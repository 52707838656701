<h1>{{ 'ticket.search.title' | translate }}</h1>
<button mat-raised-button class="filters-button" color="primary" (click)="toggleFilters()">
  {{ 'search.filters' | translate }}
</button>

<div class="search-filters" *ngIf="showFilters">
  <div class="criteria-container">
    <mat-form-field class="criteria-element">
      <input matInput
             type="number"
             [(ngModel)]="ticketId"
             placeholder="{{ 'general.ticketId' | translate }}"
             autocomplete="off">
    </mat-form-field>

    <mat-form-field class="criteria-element">
      <input matInput
             [(ngModel)]="summary"
             placeholder="{{ 'recent.tickets.summary.header' | translate }}"
             autocomplete="off">
    </mat-form-field>

    <mat-form-field class="criteria-element">
      <input #contactInput
             matInput
             [(ngModel)]="ticketOwner"
             placeholder="{{ 'create.ticket.ticket.contact' | translate }}"
             autocomplete="off">
      <mat-icon matSuffix
                class="current-contact"
                matTooltip="{{ 'ticket.search.current.contact.tooltip' | translate }}"
                (click)="showCurrentContactTickets()">person
      </mat-icon>
    </mat-form-field>

    <mat-form-field class="criteria-element">
      <input matInput
             [(ngModel)]="assignedTo"
             placeholder="{{ 'create.ticket.ticket.owner' | translate }}"
             autocomplete="off">
    </mat-form-field>

    <mat-form-field class="criteria-element">
      <input #createdDateRangeInput
             matInput
             autocomplete="off"
             ngxDaterangepickerMd
             [(ngModel)]="createdDateRange"
             [alwaysShowCalendars]="true"
             [locale]="{
               format: 'll',
               separator: ' to ',
               cancelLabel: getCancelLabel(),
               applyLabel: getApplyLabel()
             }"
             [showClearButton]="true"
             [ranges]="ranges"
             [linkedCalendars]="true"
             emptyWeekRowClass="hideDate"
             placeholder="{{ 'ticket.search.created.date.range' | translate }}"/>
    </mat-form-field>

    <mat-form-field class="criteria-element">
      <input #updatedDateRangeInput
             matInput
             autocomplete="off"
             ngxDaterangepickerMd
             [(ngModel)]="updatedDateRange"
             [alwaysShowCalendars]="true"
             [locale]="{
               format: 'll',
               separator: ' to ',
               cancelLabel: getCancelLabel(),
               applyLabel: getApplyLabel()
             }"
             [showClearButton]="true"
             [ranges]="ranges"
             [linkedCalendars]="true"
             emptyWeekRowClass="hideDate"
             placeholder="{{ 'ticket.search.updated.date.range' | translate }}"/>
    </mat-form-field>

    <mat-tree [dataSource]="statusDataSource" [treeControl]="statusTreeControl">
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
        <button mat-icon-button disabled></button>
        <mat-checkbox class="checklist-leaf-node"
                      [checked]="statusChecklistSelection.isSelected(node)"
                      (change)="statusChecklistSelection.toggle(node);">{{node.translate | translate}}
        </mat-checkbox>
      </mat-tree-node>

      <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
        <button mat-icon-button matTreeNodeToggle
                [attr.aria-label]="'toggle ' + node.filename">
          <mat-icon class="mat-icon-rtl-mirror">
            {{statusTreeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
          </mat-icon>
        </button>

        <mat-checkbox [checked]="statusDescendantsAllSelected(node)"
                      [indeterminate]="statusDescendantsPartiallySelected(node)"
                      (change)="statusSelectionToggle(node)">{{node.translate | translate}}
        </mat-checkbox>
      </mat-tree-node>
    </mat-tree>

    <mat-tree [dataSource]="priorityDataSource" [treeControl]="priorityTreeControl">
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
        <button mat-icon-button disabled></button>
        <mat-checkbox class="checklist-leaf-node"
                      [checked]="priorityChecklistSelection.isSelected(node)"
                      (change)="priorityChecklistSelection.toggle(node);">{{node.translate | translate}}
        </mat-checkbox>
      </mat-tree-node>

      <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
        <button mat-icon-button matTreeNodeToggle
                [attr.aria-label]="'toggle ' + node.filename">
          <mat-icon class="mat-icon-rtl-mirror">
            {{priorityTreeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
          </mat-icon>
        </button>

        <mat-checkbox [checked]="priorityDescendantsAllSelected(node)"
                      [indeterminate]="priorityDescendantsPartiallySelected(node)"
                      (change)="prioritySelectionToggle(node)">{{node.translate | translate}}
        </mat-checkbox>
      </mat-tree-node>
    </mat-tree>

    <div>
      <mat-icon class="role-icon" matListIcon (click)="openPrioritiesHelpDialog()">help</mat-icon>
    </div>

    <div class="criteria-actions">
      <div class="button-row">
        <button mat-raised-button color="basic"
                (click)="onClear()">
          <span>{{ 'ticket.search.clear.button' | translate }}</span>
        </button>
        <button mat-raised-button color="primary"
                (click)="onSearchClicked()">
          <span>{{ 'ticket.search.search.button' | translate }}</span>
        </button>
      </div>
    </div>
  </div>
</div>
<br>
<mat-table #table
           [dataSource]="data"
           matSort
           matSortDisableClear
           aria-label="Elements">

  <ng-container matColumnDef="id">
    <mat-header-cell *matHeaderCellDef>{{ 'recent.tickets.id.header' | translate }}</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <a [routerLink]="routeService.ticket(row.ticketId)" data-auto="search-ticket-id">{{row.ticketId}}</a>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="summary">
    <mat-header-cell *matHeaderCellDef>{{ 'recent.tickets.summary.header' | translate }}</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <a [routerLink]="routeService.ticket(row.ticketId)" data-auto="search-ticket-summary">{{row.summary}}</a>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="status">
    <mat-header-cell *matHeaderCellDef>{{ 'recent.tickets.status.header' | translate }}</mat-header-cell>
    <mat-cell *matCellDef="let row">{{row.status | ticketStatus }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="priority">
    <mat-header-cell *matHeaderCellDef>{{ 'ticket.search.priority' | translate }}</mat-header-cell>
    <mat-cell *matCellDef="let row">{{row.priority  | ticketPriority }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="ticketOwner">
    <mat-header-cell *matHeaderCellDef>{{ 'create.ticket.ticket.contact' | translate }}</mat-header-cell>
    <mat-cell *matCellDef="let row">{{row.contact}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="assignedTo">
    <mat-header-cell *matHeaderCellDef>{{ 'create.ticket.ticket.owner' | translate }}</mat-header-cell>
    <mat-cell *matCellDef="let row">{{row.assignedTo}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="dateEntered">
    <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'recent.tickets.created.header' | translate }}
    </mat-header-cell>
    <mat-cell *matCellDef="let row">
      {{row.dateEntered | localizedDateShort }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="lastUpdated">
    <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'recent.tickets.updated.header' | translate }}
    </mat-header-cell>
    <mat-cell *matCellDef="let row">
      {{row.lastUpdated | localizedDateShort }}
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>
<mat-paginator #paginator
               [showFirstLastButtons]="true"
               [length]="resultsLength"
               [hidePageSize]="true"
               [pageSize]="10">
</mat-paginator>
<mat-progress-bar mode="indeterminate" *ngIf="isLoadingResults"></mat-progress-bar>
