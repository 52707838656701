import {Component, OnInit} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {ContactUsDialogComponent} from './contact-us-dialog.component';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  constructor(
    public dialog: MatDialog,
  ) {
  }

  ngOnInit() {
  }

  onContactUsClick() {
    this.dialog.open(ContactUsDialogComponent, {
      disableClose: true,
      panelClass: 'panel-one-third',
    });
  }
}
