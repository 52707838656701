import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse} from '@angular/common/http';
import {Observable, of, Subject, throwError} from 'rxjs';
import {CurrentContactService} from '../current-contact.service';
import {catchError, map, switchMap, tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CompanyApiService {

  private companyLogoSubject$: Subject<any>;
  companyLogo$: Observable<any>;

  constructor(private http: HttpClient,
              private currentContactService: CurrentContactService
  ) {
    this.companyLogoSubject$ = new Subject<any>();
    this.companyLogo$ = this.companyLogoSubject$.asObservable();
  }

  getCompanyLogo(): Observable<any> {
    return this.http.get(
      '/api/companies/' + this.currentContactService.getContact().companyId + '/logo',
      {responseType: 'text', observe: 'response'}
    ).pipe(
      catchError(err => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 404) { // bad credentials, this is expected
            return of(null);
          }
        }
        return throwError(err);
      }),
      map((response: HttpResponse<string>) => {
        return response;
      })
    );
  }

  uploadCompanyLogo(formdata: FormData) {
    const headers = new HttpHeaders();
    headers.set('Content-Type', null);
    headers.set('Accept', 'multipart/form-data');

    this.http.post<string>('/api/companies/' + this.currentContactService.getContact().companyId + '/logo', formdata,
      // https://github.com/angular/angular/issues/18586
      {responseType: 'text' as 'json', headers: headers}
    ).subscribe(() => {
      this.getCompanyLogo().subscribe(value => this.companyLogoSubject$.next(value));
    });
  }

  getAllCompanies(): Observable<Company[]> {
    return this.http.get<Company[]>('/api/companies', {responseType: 'json'});
  }

  getCompany(companyId: number): Observable<Company> {
    return this.http.get<Company>('/api/companies/' + encodeURIComponent(companyId.toString()), {responseType: 'json'});
  }
}

export class Company {
  id: number;
  name: string;
  identifier: string;
  maxEnabledUsers: number;
  maxAdmins: number;
}
