import {AfterViewInit, Component, EventEmitter, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {UploaderOptions, UploadInput} from 'ngx-uploader';
import {FileUploadComponent} from '../file-upload/file-upload.component';

@Component({
  selector: 'app-ticket-add-note-dialog',
  templateUrl: 'ticket-add-note-dialog.component.html',
  styleUrls: ['./ticket-add-note-dialog.component.scss']
})
export class TicketAddNoteDialogComponent implements OnInit, AfterViewInit {

  ticketId: string;
  ticketStatus: string;
  hasLargeFile: boolean;
  options: UploaderOptions;
  uploadInput: EventEmitter<UploadInput>;
  note = new FormControl('', [Validators.required]);
  noteForm = new FormGroup({
    note: this.note,
  });

  emitDirty = new EventEmitter();

  @ViewChild(FileUploadComponent, {static: false}) fileUploadComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<TicketAddNoteDialogComponent>,
  ) {
    this.options = {
      concurrency: 1,
      maxUploads: 3
    };
    this.uploadInput = new EventEmitter<UploadInput>(); // input events, we use this to emit data to ngx-uploader
  }

  ngOnInit() {
    this.ticketStatus = this.data['ticketStatus'];
    this.ticketId = this.data['ticketId'];
  }

  ngAfterViewInit(): void {
  }

  onKeyPress() {
    this.emitDirty.emit();
  }

  onAddNoteClicked() {
    if (!this.noteForm.valid) {
      return;
    }

    if (this.isClosed()) {
      this.closeDialogRef();
    } else {
      this.fileUploadComponent.uploadFiles(this.ticketId)
        .subscribe(() => {
          this.closeDialogRef();
        });
    }
  }

  closeDialogRef() {
    const results = [];
    results['note'] = this.note.value;
    results['isClosed'] = this.isClosed();
    this.dialogRef.close(results);
  }

  onCancelClicked(): void {
    this.dialogRef.close();
  }

  receiveFilesChange() {
    this.hasLargeFile = this.fileUploadComponent.hasLargeFile;
  }

  isClosed() {
    return this.ticketStatus === 'RESOLVED';
  }
}
