import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {CurrentContactService} from '../../current-contact.service';
import {UserManagementApiService} from '../../api/user-management-api.service';
import {MatDialogRef} from '@angular/material';
import {switchMap} from 'rxjs/operators';
import {AuthApiService} from '../../api/auth-api.service';

@Component({
  selector: 'app-dialog-edit-user',
  templateUrl: './dialog-edit-user.component.html',
  styleUrls: ['./dialog-edit-user.component.scss']
})
export class DialogEditUserComponent implements OnInit {

  firstName = new FormControl('', [Validators.required]);
  lastName = new FormControl('', [Validators.required]);
  email = new FormControl('', [Validators.required]);
  phoneNumber = new FormControl('', [Validators.required]);

  allActiveEmails: string[];

  hasProfilePicture: boolean;
  profilePicture: string;
  $profilePicture: Event;
  hadChosenNewProfilePicture: boolean;

  userForm = new FormGroup({
    firstName: this.firstName,
    lastName: this.lastName,
    email: this.email,
    phoneNumber: this.phoneNumber,
  });

  constructor(
    private currentContactService: CurrentContactService,
    public userManagementApiService: UserManagementApiService,
    private authApiService: AuthApiService,
    private dialogRef: MatDialogRef<DialogEditUserComponent>
  ) {
    this.firstName.setValue(this.currentContactService.getContact().firstName);
    this.lastName.setValue(this.currentContactService.getContact().lastName);
    this.email.setValue(this.currentContactService.getContact().email);
    this.phoneNumber.setValue(this.currentContactService.getContact().phoneNumber);
  }

  ngOnInit() {
    this.userManagementApiService.getUsersForCompanyCurrentContact().subscribe((result) => {
      this.allActiveEmails = result.map(u => u.toLowerCase());
    });

    this.getProfilePicture();
    this.hadChosenNewProfilePicture = false;
  }

  onSaveClicked() {
    if (!this.userForm.valid) {
      return;
    }
    const userInfo = {
      'firstName': this.firstName.value,
      'lastName': this.lastName.value,
      'email': this.email.value,
      'phoneNumber': this.phoneNumber.value,
    };

    this.userManagementApiService.updateCurrentContact(userInfo)
      .pipe(
        switchMap(() => this.currentContactService.reload())
      ).subscribe(() => {
        this.saveProfilePicture();
      }
    );
  }

  updateProfilePicture($event: Event) {
    if ((<HTMLInputElement>$event.target).files.length === 1) {

      const file = (<HTMLInputElement>$event.target).files[0];

      if (file.type.startsWith('image/')) {

        const reader = new FileReader();
        reader.onload = this.handleReaderLoaded.bind(this);
        reader.readAsBinaryString(file);

        this.$profilePicture = $event;
        this.hadChosenNewProfilePicture = true;

      } else {
        alert('The chosen file must be an image');
      }
    }
  }

  private handleReaderLoaded(readerEvt) {
    const binaryString = readerEvt.target.result;
    this.profilePicture = 'data:image/png;base64,' + btoa(binaryString);
  }

  getProfilePicture() {
    this.currentContactService.getProfilePicture()
      .subscribe(value => {
        this.profilePicture = 'data:image/png;base64,' + value;
        this.hasProfilePicture = true;
      });
  }

  emailAlreadyExists(email: string) {
    const lowerCaseEmail = email.toLowerCase();
    if (this.email.dirty && this.allActiveEmails.includes(lowerCaseEmail)) {
      this.email.setErrors({notUnique: true});
    }
  }

  private saveProfilePicture() {
    if (this.hadChosenNewProfilePicture) {
      const file = (<HTMLInputElement>this.$profilePicture.target).files[0];

      const formData = new FormData();
      formData.append('file', file, file.name);

      this.currentContactService.changeProfilePicture(formData)
        .subscribe(() => {
            this.dialogRef.close();
          }
        );
    } else {
      this.dialogRef.close();
    }
  }
}
