<mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
<!-- ZIRO NOT PURCHASED -->
<div class="ziro-not-purchased" *ngIf="!isLoading && !isZiroPurchased">
  <div class="ziro-card ziro-not-purchased">
    <mat-card class="ziro-not-purchased">
      <mat-card-header>
        <mat-card-title
          class="no-avatar-title">{{"ziro.dashboard.ziro.not.purchased.title" | translate}}</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <span>{{"ziro.dashboard.ziro.not.purchased.description" | translate}} <a
          href="https://goziro.com/uc-management-platform-smacs-manage/">{{"ziro.dashboard.ziro.not.purchased.learn.more" | translate}}</a></span><br>
        <span>{{"ziro.dashboard.ziro.not.purchased.agreement.description.start" | translate}} <a
          href="mailto:softwaresupport@goziro.com">{{"ziro.dashboard.ziro.not.purchased.learn.contact.link" | translate}}</a> {{"ziro.dashboard.ziro.not.purchased.agreement.description.end" | translate}}</span>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<!-- ZIRO PURCHASED -->
<div class="grid-container" *ngIf="!isLoading && isZiroPurchased">
  <!-- LEFT SIDE -->
  <div class="grid-left-side">

    <!-- CURRENT RELEASE -->
    <div class="ziro-card">
      <mat-card>
        <mat-card-header>
          <mat-card-title
            class="no-avatar-title">{{"ziro.dashboard.card.title.current.ziro.release" | translate}}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <span> ZIRO Provisioning v{{currentZiroVersion}} {{"ziro.dashboard.card.current.ziro.release.is.now.available" | translate}}</span>
          <br>
          <a
            href="https://stack8.atlassian.net/l/c/n92022e0">{{"ziro.dashboard.card.current.ziro.release.notes" | translate}}</a>
          <span> |
            <span class='fake-link'
                  (click)="openZiroDownloadModal()">{{"ziro.dashboard.your.ziro.instances.download.latest.version" | translate}}</span>
          </span>
        </mat-card-content>
      </mat-card>
    </div>

    <!-- CONFIGURATIONS -->
    <div class="card-container" *ngIf="ziroConfigurations.length > 0">
      <h2 class="ziro-instances-title">{{ 'ziro.dashboard.your.ziro.instances.title' | translate}}</h2>
      <div *ngFor="let ziroConfiguration of ziroConfigurations" class="ziro-card">
        <mat-card>
          <mat-card-header>
            <mat-card-title class="no-avatar-title">{{ziroConfiguration.name}}</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <span *ngIf="ziroConfiguration.hasManage" class="module enabled">✓ ZIRO <strong>Manage</strong></span>
            <span *ngIf="!ziroConfiguration.hasManage" class="module disabled"
                  matTooltip="{{ 'ziro.dashboard.your.ziro.instances.manage.tooltip' | translate }}">✗ ZIRO <strong>Manage</strong></span>

            <span *ngIf="ziroConfiguration.hasAutomate"
                  class="module enabled">✓ ZIRO <strong>Automation</strong></span>
            <span *ngIf="!ziroConfiguration.hasAutomate" class="module disabled" matTooltipClass="tooltip"
                  matTooltip="{{ 'ziro.dashboard.your.ziro.instances.automate.tooltip' | translate }}">✗ ZIRO <strong>Automation</strong></span>

            <span *ngIf="ziroConfiguration.hasControl" class="module enabled">✓ ZIRO <strong>Reporting</strong></span>
            <span *ngIf="!ziroConfiguration.hasControl" class="module disabled"
                  matTooltip="{{ 'ziro.dashboard.your.ziro.instances.control.tooltip' | translate }}">✗ ZIRO <strong>Reporting</strong></span>

            <span *ngIf="ziroConfiguration.hasHa" class="module enabled">✓ ZIRO <strong>HA</strong></span>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>

  <!-- RIGHT SIDE -->
  <div class="grid-right-side">
    <!-- AM -->
    <div *ngIf="accountManager" class="ziro-card">
      <mat-card>
        <mat-card-header>
          <img mat-card-avatar alt="avatar"
               src="data:image/png;base64,{{accountManager.profilePictureByteStream}}"/>
          <mat-card-title>{{accountManager.firstName}} {{accountManager.lastName}}</mat-card-title>
          <mat-card-subtitle>
            {{ 'ziro.dashboard.your.ziro.am' | translate }}
          </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          {{ 'ziro.dashboard.your.ziro.am.content' | translate }}
        </mat-card-content>
        <mat-card-actions>
          <a href="tel:{{accountManager.phoneNumber}}">
            <button mat-button>
              <mat-icon aria-hidden="false">phone</mat-icon>
              {{ 'ziro.dashboard.your.ziro.phone' | translate }}</button>
          </a>
          <a href="mailto:{{accountManager.emailAddress}}">
            <button mat-button>
              <mat-icon aria-hidden="false">email</mat-icon>
              {{ 'ziro.dashboard.your.ziro.email' | translate }}
            </button>
          </a>
        </mat-card-actions>
      </mat-card>
    </div>

    <!-- CSM -->
    <div *ngIf="customerSuccessManager" class="ziro-card">
      <mat-card>
        <mat-card-header>
          <img mat-card-avatar alt="avatar"
               src="data:image/png;base64,{{customerSuccessManager.profilePictureByteStream}}"/>
          <mat-card-title>{{customerSuccessManager.firstName}} {{customerSuccessManager.lastName}}</mat-card-title>
          <mat-card-subtitle>
            {{ 'ziro.dashboard.your.ziro.csm' | translate }}
          </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          {{ 'ziro.dashboard.your.ziro.csm.content' | translate }}
        </mat-card-content>
        <mat-card-actions>
          <a href="tel:{{customerSuccessManager.phoneNumber}}">
            <button mat-button>
              <mat-icon aria-hidden="false">phone</mat-icon>
              {{ 'ziro.dashboard.your.ziro.phone' | translate }}</button>
          </a>
          <a href="mailto:{{customerSuccessManager.emailAddress}}">
            <button mat-button>
              <mat-icon aria-hidden="false">email</mat-icon>
              {{ 'ziro.dashboard.your.ziro.email' | translate }}
            </button>
          </a>
        </mat-card-actions>
      </mat-card>
    </div>

    <!-- AGREEMENTS -->
    <!-- ONE AGREEMENT FOUND -->
    <div class="ziro-card" *ngIf="ziroAgreements.length === 1">
      <mat-card>
        <mat-card-header>
          <mat-card-title class="no-avatar-title">{{ziroAgreements[0].name}}</mat-card-title>
          <mat-card-subtitle class="no-avatar-title">
            {{ 'ziro.dashboard.your.agreement.title' | translate}}
          </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <strong>{{ 'ziro.dashboard.your.agreements.name' | translate }}</strong><span>{{ziroAgreements[0].name}}</span><br>
          <strong>Type: </strong><span>{{ziroAgreements[0].type}}</span><br>
          <span *ngIf="ziroAgreements[0].endDate"><strong>Expiration: </strong>
            <span>{{ziroAgreements[0].endDate | localizedDateShort }}
              <span *ngIf="ziroAgreements[0].expiryInDays <= 60"> |<mat-icon class="warning-icon">notification_important</mat-icon>
                <span
                  class="orange-text"><strong>{{ 'ziro.dashboard.your.agreement.expiry' | translate }} </strong></span>
                  <a *ngIf="accountManager" href="mailto:{{accountManager.emailAddress}}">
                    {{ 'ziro.dashboard.your.agreement.contact.link' | translate }}
                  </a>
                  <a *ngIf="!accountManager" href="mailto:sales@goziro.com">
                    {{ 'ziro.dashboard.your.agreement.contact.link' | translate }}
                  </a>
                {{ 'ziro.dashboard.your.agreement.contact.renew' | translate }}
                </span>
              </span>
            </span>
        </mat-card-content>
      </mat-card>
    </div>

    <!-- ZERO OR MORE THAN ONE AGREEMENT(S) FOUND -->
    <div class="ziro-card" *ngIf="ziroAgreements.length !== 1">
      <mat-card>
        <mat-card-header>
          <mat-card-title
            class="no-avatar-title">{{ 'ziro.dashboard.your.agreement.title' | translate}}</mat-card-title>
        </mat-card-header>
        <span>{{ 'ziro.dashboard.your.agreement.error.start' | translate }} </span>
        <a *ngIf="accountManager" href="mailto:{{accountManager.emailAddress}}">
          {{ 'ziro.dashboard.your.agreement.error.am' | translate }}
        </a>
        <a *ngIf="!accountManager" href="mailto:sales@goziro.com">
          {{ 'ziro.dashboard.your.agreement.error.am' | translate }}
        </a>
        <span>{{ 'ziro.dashboard.your.agreement.error.end' | translate }} </span>
      </mat-card>
    </div>

    <!-- RELATED LINKS -->
    <div class="ziro-card">
      <mat-card>
        <mat-card-header>
          <mat-card-title
            class="no-avatar-title">{{ 'ziro.dashboard.related.links.title' | translate }}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <ul>
            <li><a href="/#/create-ticket">{{ 'ziro.dashboard.related.links.open.ticket' | translate }}</a></li>
            <li><a
              href="https://stack8.atlassian.net/l/c/n92022e0">{{ 'ziro.dashboard.related.links.release.notes' | translate }}</a>
            </li>
            <li><a
              href="https://stack8.atlassian.net/l/c/mq6Vtf2s">{{ 'ziro.dashboard.related.links.walkthrough.videos' | translate }}</a>
            </li>
            <li><a
              href="https://stack8.atlassian.net/l/c/viJzvULZ">{{ 'ziro.dashboard.related.links.support.guide' | translate }}</a>
            </li>
          </ul>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
