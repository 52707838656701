import {Component, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material';

@Component({
  selector: 'app-user-types-dialog',
  templateUrl: './user-types-dialog.component.html',
  styleUrls: ['./user-types-dialog.component.scss']
})
export class UserTypesDialogComponent implements OnInit {

  dataSource: MatTableDataSource<UserTypeDetails> = new MatTableDataSource<UserTypeDetails>();
  displayedColumns = ['permission', 'user', 'manager', 'executive', 'admin'];

  constructor() { }

  ngOnInit() {
    this.dataSource.data = [
      new UserTypeDetails('user.types.permissions.create.ticket', true, true, true, true),
      new UserTypeDetails('user.types.permissions.escalate.ticket', false, true, true, true),
      new UserTypeDetails('user.types.permissions.create.ticket.on.behalf', false, true, false, true),
      new UserTypeDetails('user.types.permissions.view.projects', true, true, false, true),
      new UserTypeDetails('user.types.permissions.create.portal.users', false, false, false, true),
      new UserTypeDetails('user.types.permissions.update.portal.users', true, true, true, true),
      new UserTypeDetails('user.types.permissions.change.company.portal.logo', false, false, false, true),
    ];
  }

}

export class UserTypeDetails {
  permissionI18n: string;
  user: boolean;
  manager: boolean;
  executive: boolean;
  admin: boolean;

  constructor(permissionI18n, user, manager, executive, admin) {
    this.permissionI18n = permissionI18n;
    this.user = user;
    this.manager = manager;
    this.executive = executive;
    this.admin = admin;
  }
}
