import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ZiroDashboardApiService {
    constructor(private http: HttpClient) {
    }

    getZiroAgreements(): Observable<ZiroAgreement[]> {
        return this.http.get<ZiroAgreement[]>('/api/agreement/ziro');
    }

    getZiroConfigurations(): Observable<ZiroConfiguration[]> {
        return this.http.get<ZiroConfiguration[]>('/api/configuration/ziro');
    }

    getCurrentZiroVersion(): Observable<CurrentZiroVersion> {
        return this.http.get<CurrentZiroVersion>('/api/configuration/current-ziro-version');
    }

    getCurrentZiroVersionDownloadLinks(): Observable<CurrentZiroVersionDownloadLinks> {
      return this.http.get<CurrentZiroVersionDownloadLinks>('/api/configuration/current-ziro-version/download-links');
    }

    getZiroTeamMembers(): Observable<ZiroTeamMember[]> {
        return this.http.get<ZiroTeamMember[]>('/api/team/');
    }

    postConfigurationApiToken(id: number): Observable<ZiroApiKey> {
        return this.http.post<ZiroApiKey>('/api/configuration/ziro/' +
            encodeURIComponent(id.toString()) + '/token', id);
    }

    deleteConfigurationApiToken(id: number): Observable<void> {
        return this.http.delete<void>('/api/configuration/ziro/' + encodeURIComponent(id.toString()) + '/token');
    }
}

export class ZiroAgreement {
    id: number;
    name: string;
    type: string;
    endDate: string;
    expiryInDays: number;
}

export class CurrentZiroVersion {
    ziroVersion: string;
    ziroConsoleVersion: string;
}

export class CurrentZiroVersionDownloadLinks {
    ziroDebLink: string;
    ziroConsoleDebLink: string;
}

export class ZiroConfiguration {
    id: number;
    name: string;
    version: string;
    numberOfLicenses: number;
    hasManage: boolean;
    hasAutomate: boolean;
    hasControl: boolean;
    hasHa: boolean;
    hasApiKey: boolean;
}


export class ZiroTeamMember {
    id: number;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    emailAddress: string;
    role: string;
    profilePictureByteStream: string;
}

export class ZiroApiKey {
    id: number;
    key: string;
}

