<div class="ticket-container">
  <h1 data-auto="ticket-title">
    <strong>№
      <span data-auto='ticket-id'>{{ticketId}}</span>
    </strong>&nbsp;-&nbsp;
    <span>{{ticketTitle}}</span>
  </h1>

  <div *ngIf="!isLoading" class="existing-ticket-container">

    <div class="property-list-container">
      <mat-list>
        <mat-list-item *ngIf="isEscalated()" style="color: red">
          <mat-icon matListIcon>warning</mat-icon>
          <h3 matLine>
            {{ 'escalate.side.title' | translate }}
          </h3>
          <p matLine>
            <mat-progress-bar mode="indeterminate" class="side-escalation-progress-bar"></mat-progress-bar>
          </p>
          <p matLine class="side-property" *ngIf="isEscalationInProgress()" data-auto="escalation-status">
            {{ 'escalate.side.status.contacting' | translate }}
          </p>
          <p matLine class="side-property" *ngIf="isEscalationFinished()">
            {{ 'escalate.side.status.responded' | translate }}
          </p>
        </mat-list-item>

        <mat-list-item>
          <mat-icon matListIcon>info_outline</mat-icon>
          <h3 matLine>
            {{ 'create.ticket.ticket.status' | translate }}
          </h3>
          <p matLine class="side-property" data-auto="side-ticket-status">
            {{ticketStatus | ticketStatus }}
          </p>
        </mat-list-item>
        <mat-list-item>
          <mat-icon matListIcon>priority_high</mat-icon>
          <h3 matLine>
            {{ 'ticket.search.priority' | translate }}
          </h3>
          <p matLine class="side-property" data-auto="side-ticket-status">
            {{ticketPriority | ticketPriority }}
          </p>
        </mat-list-item>
        <mat-list-item>
          <mat-icon matListIcon>perm_identity</mat-icon>
          <h3 matLine>
            {{ 'create.ticket.ticket.contact' | translate }}
          </h3>
          <p matLine data-auto="side-ticket-author" class="side-property">
            {{ ticketContact }}
          </p>
        </mat-list-item>
        <mat-list-item>
          <mat-icon matListIcon>access_time</mat-icon>
          <h3 matLine>
            {{ 'create.ticket.ticket.created.on' | translate }}
          </h3>
          <p matLine class="side-property">
            {{ticketCreationDate | localizedDateMedium }}
          </p>
        </mat-list-item>
        <mat-list-item>
          <mat-icon matListIcon>update</mat-icon>
          <h3 matLine>
            {{ 'create.ticket.ticket.updated.on' | translate }}
          </h3>
          <p matLine class="side-property">
            {{ticketLastUpdated | localizedDateMedium }}
          </p>
        </mat-list-item>
        <mat-list-item>
          <mat-icon matListIcon>headset_mic</mat-icon>
          <h3 matLine>
            {{ 'create.ticket.ticket.owner' | translate }}
          </h3>
          <p matLine data-auto="side-ticket-owner" class="side-property">
              <span *ngIf="ticketAssignedTo">
                {{ticketAssignedTo}}
              </span>
            <span *ngIf="!ticketAssignedTo">
                <i>&nbsp;{{ 'create.ticket.ticket.unassigned' | translate }}</i>
              </span>
          </p>
        </mat-list-item>
      </mat-list>
    </div>
    <div class="tabs-container">
      <mat-tab-group (selectedIndexChange)="onTabIndexChanged($event)">
        <mat-tab label="Description" class="description-tab">
          <p class="note-content" data-auto="ticket-description">{{initialDescription ? initialDescription.text :
              ''}}</p>
        </mat-tab>

        <mat-tab label="Notes ({{notePanels.length}})" class="notes-tab">
          <div *ngFor="let notePanel of notePanels">
            <mat-accordion class="notes-accordion">
              <mat-expansion-panel (expandedChange)="notePanel.isOpened = $event.valueOf()">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <mat-icon class="mat-icon-large-gap" [inline]="true" *ngIf="notePanel.note.author">
                      {{notePanel.note.isSupport ? 'headset_mic' : 'person'}}
                    </mat-icon>
                    {{notePanel.note.author}}
                  </mat-panel-title>
                  <mat-panel-description>
                    <div class="note-header-content">
                      <mat-icon [inline]="true">access_time</mat-icon>
                      {{notePanel.note.date | date:'medium'}}
                    </div>
                    <div class="note-header-content" *ngIf="!notePanel.isOpened">
                      <mat-icon [inline]="true">create</mat-icon>
                      {{notePanel.note.text}}
                    </div>
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <p class="note-content">{{notePanel.note.text}}</p>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </mat-tab>

        <mat-tab label="{{ 'create.ticket.ticket.attachments' | translate }} ({{attachments.length}})">
          <mat-list data-auto="attachment-list">
            <mat-list-item *ngFor="let attachment of attachments">
              <button mat-stroked-button
                      (click)="onAttachmentClick(attachment)">
                <mat-icon>file_download</mat-icon>
                <span>{{attachment.fileName}}</span>
              </button>
            </mat-list-item>
          </mat-list>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
  <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
</div>

<div class="speed-dial-container">
  <eco-fab-speed-dial #fabSpeedDial>
    <eco-fab-speed-dial-trigger [spin]="true">
      <button mat-fab data-auto="ticket-fab">
        <mat-icon class="spin180">{{fabSpeedDial.open ? 'close' : 'add'}}</mat-icon>
      </button>
    </eco-fab-speed-dial-trigger>

    <eco-fab-speed-dial-actions>
      <button mat-mini-fab
              (click)="onAddNoteClicked()"
              data-auto="ticket-fab-add-attachment"
              matTooltip="{{'create.ticket.speed.dial.add.attachment' | translate}}"
              matTooltipPosition="left">
        <mat-icon>attachment</mat-icon>
      </button>
      <button mat-mini-fab
              (click)="onAddNoteClicked()"
              data-auto="ticket-fab-add-note"
              matTooltip="{{ 'create.ticket.speed.dial.add.note' | translate}}"
              matTooltipPosition="left">
        <mat-icon>edit</mat-icon>
      </button>
      <button mat-mini-fab
              (click)="onChangeStatusClicked()"
              data-auto="ticket-fab-change-status"
              matTooltip="{{ 'create.ticket.speed.dial.change.status' | translate}}"
              matTooltipPosition="left">
        <mat-icon>info_outline</mat-icon>
      </button>
      <button mat-mini-fab
              *ngIf="!isEscalated() && canEscalate()"
              (click)="onEscalateClicked()"
              data-auto="ticket-fab-escalate"
              matTooltip="{{ 'create.ticket.speed.dial.escalate' | translate}}"
              matTooltipPosition="left">
        <mat-icon>notifications_active</mat-icon>
      </button>
      <button mat-mini-fab
              class="disabled-button"
              *ngIf="!canEscalate()"
              data-auto="ticket-fab-escalate"
              matTooltip="{{ 'create.ticket.speed.dial.cant.escalate' | translate}}"
              matTooltipPosition="left">
        <mat-icon>notifications_active</mat-icon>
      </button>
    </eco-fab-speed-dial-actions>
  </eco-fab-speed-dial>
</div>
