<table mat-table
       [dataSource]="dataSource"
       class="mat-elevation-z0 company-container"
       matSort>

  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef>
      <span>
        {{ 'dialog-companies.companyname.header' | translate }}
      </span>
      <br>
      <mat-form-field>
        <input matInput name="name" [formControl]="name">
      </mat-form-field>
    </th>
    <td mat-cell (click)="onClickCompanyChanger(row)"
        [ngClass]="{hovered: row.hovered, highlighted:row.name === selectedCompany}"
        (mouseover)="row.hovered = true" (mouseout)="row.hovered = false" *matCellDef="let row" id={{row.name}}>
      {{row.name}}
    </td>
  </ng-container>

  <ng-container matColumnDef="companyIdentifier">
    <th mat-header-cell *matHeaderCellDef>
      <span>
        {{ 'dialog-companies.identifier.header' | translate }}
      </span>
      <br>
      <mat-form-field>
        <input matInput name="companyIdentifier" [formControl]="companyIdentifier">
      </mat-form-field>
    </th>
    <td mat-cell (click)="onClickCompanyChanger(row)"
        [ngClass]="{hovered: row.hovered, highlighted:row.name === selectedCompany}"
        (mouseover)="row.hovered = true" (mouseout)="row.hovered = false" *matCellDef="let row" id={{row.identifier}}>
      {{row.identifier}}
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;" data-auto="recent-ticket-row"></tr>
</table>
