<div class="dialog-container">
  <h1 mat-dialog-title>{{ 'dialog-companies.header' | translate }}</h1>
  <div mat-dialog-content [ngClass]="{companyList: currentStep == 'companyList'}">
    <div *ngIf="currentStep == 'companyList'">
      <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
      <app-companies-list *ngIf="!isLoading" [dataSource]="dataSource" [(selectedCompany)]="selectedCompany">
      </app-companies-list>
    </div>
    <div *ngIf="currentStep == 'userType'">
      <app-user-types [(selectedUserType)]="selectedUserType" [selectedCompany]="selectedCompany">
      </app-user-types>
    </div>
  </div>

  <div class="spacer"></div>

  <div mat-dialog-actions *ngIf="!isLoading">
    <div *ngIf="currentStep == 'companyList' && selectedCompany!=null" class="selected-company">
      {{selectedCompany.name}}
    </div>
    <div class="dialog-buttons">
      <button *ngIf="currentStep == 'userType'" (click)="currentStep = 'companyList'" color="primary" mat-button
              mat-raised-button>{{'dialog-companies.previous' | translate}}</button>
      <button *ngIf="currentStep == 'companyList'" [disabled]="selectedCompany==null"
              id="nextStepCompanyImpersonation"
              (click)="currentStep = 'userType'"
              color="primary" mat-button mat-raised-button>{{'dialog-companies.next' | translate}}
      </button>
      <button *ngIf="currentStep == 'userType'" [disabled]="selectedCompany==null || selectedUserType==null"
              id="confirmCompanyImpersonation"
              (click)="confirm()"
              color="primary" mat-button mat-raised-button>{{'dialog-companies.confirm' | translate}}</button>
      <button mat-button mat-dialog-close>{{'general.cancel' | translate}}</button>
    </div>
  </div>
</div>
