import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProjectTranslationService {

  private projectStatusMap = {};
  private projectTypeMap = {};

  constructor() {
    this.generateProjectStatusMap();
    this.generateProjectTypeMap();
  }

  /**
   * The list of active Project Statuses can be assumed to be stable
   * SQL to view the list:
   *  select * from v_rpt_ProjectStatuses where Inactive_Flag = 0;
   */
  private generateProjectStatusMap() {
    this.projectStatusMap['1- New'] = 'project.status.map.1.new';
    this.projectStatusMap['2 - Initiated'] = 'project.status.map.2.initiated';
    this.projectStatusMap['3 - On-Hold'] = 'project.status.map.3.on.hold';
    this.projectStatusMap['4- In Progress'] = 'project.status.map.4.in.progress';
    this.projectStatusMap['5 - Day-2'] = 'project.status.map.5.day.2';
    this.projectStatusMap['6 - Closed PIR to be completed'] = 'project.status.map.6.closed.pir.to.be.completed';
    this.projectStatusMap['7 - Closed No PIR required'] = 'project.status.map.7.closed.no.pir';
    this.projectStatusMap['8 - Closed PIR completed'] = 'project.status.map.8.closed.pir.completed';
    this.projectStatusMap['9 - Cancelled'] = 'project.status.map.9.cancelled';
  }

  /**
   * The list of active Project Statuses can be assumed to be stable
   * SQL to view the list:
   *  select * from v_rpt_ProjectTypes where Inactive_Flag = 0;
   */
  private generateProjectTypeMap() {
    this.projectTypeMap['Contact Center'] = 'project.type.map.contact.center';
    this.projectTypeMap['Consulting'] = 'project.type.map.consulting';
    this.projectTypeMap['Custom SW Development'] = 'project.type.map.custom.sw.development';
    this.projectTypeMap['Network'] = 'project.type.map.network';
    this.projectTypeMap['UC'] = 'project.type.map.UC';
    this.projectTypeMap['Internal - R&D'] = 'project.type.map.internal.rd';
    this.projectTypeMap['SMACS Installations'] = 'project.type.map.smacs.installations';
    this.projectTypeMap['SMACS - POC'] = 'project.type.map.smacs.poc';
    this.projectTypeMap['MUST CHANGE'] = 'project.type.map.must.change';
    this.projectTypeMap['Internal'] = 'project.type.map.internal';
  }

  getStatusTranslation(key: string): string {
    return this.projectStatusMap[key];
  }

  getTypeTranslation(key: string): string {
    return this.projectTypeMap[key];
  }
}
