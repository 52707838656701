<div class="file-info-container">
    <mat-icon mat-list-icon class="attachment-icon-item">attachment</mat-icon>
    <h3 class="file-name-item">{{file.name}}</h3>
    <p class="file-size-item">{{ ceiling(file.size) | number: '1.0-0'}}K
      <mat-error *ngIf="ceiling(file.size) > maxFileSizeKb">
        {{ 'file.upload.size.error' | translate}}
      </mat-error>
    </p>
  <h3 class="upload-percentage-item" *ngIf="fileInfo.isDownloading">&nbsp;<strong>{{fileInfo.percentUpload}}%</strong></h3>
    <button mat-icon-button class="close-icon-item" (click)="removeFile()">
      <mat-icon>delete_forever</mat-icon>
    </button>
</div>
