import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {UploadFile} from 'ngx-uploader';
import {Moment} from 'moment';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TicketApiService {

  constructor(private http: HttpClient) {
  }

  createTicket(title: string, description: string, contactId: number): Observable<number> {
    const newTicketRequest = new NewTicketRequest();
    newTicketRequest.title = title;
    newTicketRequest.description = description;
    newTicketRequest.contactId = contactId;

    return this.http.post('/api/ticket/create', newTicketRequest)
      .pipe(
        map((value: NewTicketResponse) => {
          return value.id;
        })
      );
  }

  getTicket(id: string): Observable<ReadOnlyTicket> {
    return this.http.get<ReadOnlyTicket>('/api/ticket/' + encodeURIComponent(id));
  }

  getTicketNotes(id: string): Observable<TicketNote[]> {
    return this.http.get<TicketNote[]>('/api/ticket/' + encodeURIComponent(id) + '/notes');
  }

  addTicketNote(id: number, text: string): Observable<any> {
    return this.http.post<TicketNote[]>('/api/ticket/' + encodeURIComponent(id.toString())
      + '/notes', {text: text});
  }

  setTicketStatus(id: number, status: string): Observable<any> {
    const ticketStatusUpdate = new TicketStatusUpdate();
    ticketStatusUpdate.newStatus = status;
    return this.http.post('/api/ticket/' + encodeURIComponent(id.toString()) + '/status', ticketStatusUpdate);
  }

  getTicketsList(
    page: number,
    pageSize: number,
    sort?: string,
    direction?: string,
    createdStartDate?: Moment,
    createdEndDate?: Moment,
    updatedStartDate?: Moment,
    updatedEndDate?: Moment,
    tickedId?: string,
    summary?: string,
    ticketOwner?: string,
    assignedTo?: string,
    statuses?: string,
    priorities?: string,
  ): Observable<TicketListResponse> {

    let httpParams = new HttpParams()
      .set('page', String(page))
      .set('page-size', String(pageSize));
    if (sort) {
      httpParams = httpParams
        .set('sort-by', sort)
        .set('sort-order', direction === 'asc' ? 'asc' : 'desc');
    }
    if (createdStartDate && createdEndDate) {
      httpParams = httpParams
        .set('created-start-date', createdStartDate.toISOString())
        .set('created-end-date', createdEndDate.toISOString());
    }
    if (updatedStartDate && updatedEndDate) {
      httpParams = httpParams
        .set('updated-start-date', updatedStartDate.toISOString())
        .set('updated-end-date', updatedEndDate.toISOString());
    }
    if (tickedId) {
      httpParams = httpParams
        .set('ticket-id', tickedId);
    }
    if (summary) {
      httpParams = httpParams
        .set('summary', summary);
    }
    if (statuses) {
      httpParams = httpParams
        .set('statuses', statuses);
    }
    if (ticketOwner) {
      httpParams = httpParams
        .set('ticketOwner', ticketOwner);
    }
    if (assignedTo) {
      httpParams = httpParams
        .set('assignedTo', assignedTo);
    }
    if (priorities) {
      httpParams = httpParams
        .set('priorities', priorities);
    }
    const options = {
      params: httpParams
    };
    return this.http.get<TicketListResponse>('/api/ticket', options);
  }

  getTicketAttachments(id: string): Observable<TicketAttachment[]> {
    return this.http.get<TicketAttachment[]>('/api/ticket/' + encodeURIComponent(id) + '/attachments');
  }

  downloadAttachment(id: number, documentId: string): Observable<Blob> {
    return this.http.get(
      '/api/ticket/' + encodeURIComponent(id.toString()) + '/attachments/' +
      encodeURIComponent(documentId) + '/download',
      {responseType: 'blob'}
    )
      ;
  }

  uploadAttachment(ticketId: string, file: UploadFile): Observable<any> {
    const headers = new HttpHeaders();
    headers.set('Content-Type', null);
    headers.set('Accept', 'multipart/form-data');
    const formData = new FormData();
    formData.append('file', file.nativeFile);

    return this.http.post(
      '/api/ticket/' + encodeURIComponent(ticketId) + '/attachments',
      formData,
      {headers: headers, reportProgress: true, observe: 'events'}
    );
  }

  escalateTicket(id: string, method: string, value: string): Observable<any> {
    return this.http.post(
      '/api/ticket/' + encodeURIComponent(id) + '/escalate',
      {
        method: method,
        value: value
      }
    );
  }

  getEscalatedStatus(id: number): Observable<UiEscalationStatus> {
    return this.http.get<UiEscalationStatus>('/api/ticket/' + encodeURIComponent(id.toString()) + '/escalate');
  }
}

export class TicketListResponse {
  items: TicketSummary[];
  total: number;
}

export class TicketSummary {
  ticketId: number;
  dateEntered: string;
  status: string;
  priority: string;
  summary: string;
  lastUpdated: string;
  assignedTo: string;
  contact: string;
}

class TicketStatusUpdate {
  newStatus: string;
}

export class TicketNote {
  id: number;
  author: string;
  text: string;
  date: string;
  isSupport: boolean;
}

export class ReadOnlyTicket {
  title: string;
  status: string;
  priority: string;
  created: string;
  updated: string;
  assignedTo: string;
  contact: string;
}

class NewTicketRequest {
  title: string;
  description: string;
  contactId: number;
  note?: string;
}

class NewTicketResponse {
  id: number;
}

export class TicketAttachment {
  id: string;
  fileName: string;
}

export enum UiEscalationStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  FINISHED = 'FINISHED',
  NONE = 'NONE'
}
