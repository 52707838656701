<div class="container">
  <h1>{{'configure.portal.header' | translate}}</h1>

  <div class="grid-container" *ngIf="!isLoading">

    <div class="company-logo-card">
      <mat-card>
        <mat-card-header>
          <mat-card-title>{{ "configure.portal.company.logo" | translate }}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <img class="logo-image" src="{{currentCompanyLogo}}" *ngIf="hasLogo" alt="Company logo">
            <h4 *ngIf="!hasLogo" style="text-align: center">{{ 'configure.portal.no.logo' | translate }}</h4>
        </mat-card-content>
        <mat-card-actions>
          <input hidden type="file" accept="image/*" #logo (change)="selectLogo($event)">
          <button mat-raised-button
                  color="primary"
                  (click)="logo.click()">
            {{ 'configure.portal.select.logo.button' | translate }}
          </button>
          <button mat-raised-button
                  *ngIf="isWaitingForConfirm"
                  (click)="onConfirmLogoClicked()">
            Confirm
          </button>
          <button mat-raised-button *ngIf="isWaitingForConfirm" (click)="onCancelLogoClicked()">
            Cancel
          </button>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>
</div>
