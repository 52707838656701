<div class="is-loading-spinner" *ngIf="isLoading">
  <mat-spinner></mat-spinner>
</div>

<div class="project-outer-container" *ngIf="!isLoading">
  <h1 data-auto="project-title">
    <span>{{project.projectName}}</span>
  </h1>


  <div class="project-inner-container">
    <!-- SIDE -->
    <div class="property-list-container">
      <div class="project-donut">
        <canvas baseChart
                chartType="doughnut"
                [data]="getDonutData()"
                [colors]="getDonutColor()"
                [options]="getDonutOptions()"
                [matTooltip]="getDonutToolTip()"
                matTooltipHideDelay=250>
        </canvas>
      </div>
      <mat-list>
        <mat-list-item>
          <mat-icon matListIcon>info_outline</mat-icon>
          <h3 matLine>
            {{ 'project.list.status.header' | translate }}
          </h3>
          <p matLine class="side-property" data-auto="side-project-status">
            {{ projectTranslationService.getStatusTranslation(project.status) | translate }}
          </p>
        </mat-list-item>
        <mat-list-item>
          <mat-icon matListIcon>folder_open</mat-icon>
          <h3 matLine>
            {{ 'project.list.type.header' | translate }}
          </h3>
          <p matLine class="side-property" data-auto="side-project-type">
            {{projectTranslationService.getTypeTranslation(project.type) | translate }}
          </p>
        </mat-list-item>
        <mat-list-item>
          <mat-icon matListIcon>account_box</mat-icon>
          <h3 matLine>
            {{ 'project.list.manager.header' | translate }}
          </h3>
          <p matLine class="side-property" data-auto="side-project-manager">
            {{project.managerFirstName + ' ' + project.managerLastName}}
          </p>
        </mat-list-item>
        <mat-list-item>
          <mat-icon matListIcon>today</mat-icon>
          <h3 matLine>
            {{ 'project.list.start.date.header' | translate }}
          </h3>
          <p matLine class="side-property" data-auto="side-project-start">
            {{project.actualStartDate | localizedDateShort}}
          </p>
        </mat-list-item>
        <mat-list-item>
          <mat-icon matListIcon>today</mat-icon>
          <h3 matLine>
            {{ 'project.list.end.date.header' | translate }}
          </h3>
          <p matLine class="side-property" data-auto="side-project-end">
            {{project.actualEndDate | localizedDateShort}}
          </p>
        </mat-list-item>
      </mat-list>
    </div>
    <!-- TABS -->
    <div class="tabs-container">
      <mat-tab-group (selectedIndexChange)="onTabIndexChanged($event)">
        <mat-tab label="Description" class="description-tab">
          <p class="note-content" data-auto="ticket-description">{{project?.description}}</p>
        </mat-tab>
        <mat-tab label="Notes ({{notePanels.length}})" class="notes-tab">
          <div *ngFor="let notePanel of notePanels">
            <mat-accordion class="notes-accordion">
              <mat-expansion-panel (expandedChange)="notePanel.isOpened = $event.valueOf()">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <mat-icon class="mat-icon-large-gap" [inline]="true" *ngIf="notePanel.note.authorLastName">
                      {{'person'}}
                    </mat-icon>
                    {{getNoteAuthor(notePanel.note)}}
                  </mat-panel-title>
                  <mat-panel-description>
                    <div class="note-header-content">
                      <mat-icon [inline]="true">access_time</mat-icon>
                      {{notePanel.note.lastUpdate | date:'medium'}}
                    </div>
                    <div class="note-header-content" *ngIf="!notePanel.isOpened">
                      <mat-icon [inline]="true">create</mat-icon>
                      {{notePanel.note.note}}
                    </div>
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <p class="note-content">{{notePanel.note.note}}</p>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </mat-tab>
        <mat-tab label="{{ 'create.ticket.ticket.attachments' | translate }} ({{attachments.length}})">
          <mat-list>
            <mat-list-item *ngFor="let attachment of attachments">
              <button mat-stroked-button
                      (click)="onAttachmentClick(attachment)">
                <mat-icon>file_download</mat-icon>
                <span>{{attachment.fileName}}</span>
              </button>
            </mat-list-item>
          </mat-list>
        </mat-tab>
        <mat-tab label="{{ 'project.issues.tab' | translate }} ({{issues.length}})">
          <app-project-issues [projectIssues]="issues"></app-project-issues>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
