import {TranslateLoader} from '@ngx-translate/core';
import {Observable, of} from 'rxjs';
import fr_data from '../assets/i18n/fr.json';
import en_data from '../assets/i18n/en.json';

export class WebpackTranslateLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    return of(lang === 'fr' ? fr_data : en_data);
  }
}
