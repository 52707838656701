<div class="container">

  <div class="profile-pic">
    <div mat-card-avatar class="avatar">
      <img *ngIf="hasProfilePicture" mat-card-avatar src="{{profilePicture}}">
    </div>

    <input hidden type="file" #uploader
           (change)="updateProfilePicture($event)"/>

    <button (click)="uploader.click()" mat-stroked-button>
      <mat-icon>camera_enhance</mat-icon>
      {{ 'edit.user.avatar' | translate}}
    </button>
  </div>

  <form [formGroup]="userForm" class="user-form">
    <br>
    <mat-form-field>
      <label>
        <input type="text"
               matInput
               placeholder="{{ 'add.user.dialog.first.name' | translate}}"
               formControlName="firstName"
               data-auto="user-first-name">
      </label>
      <mat-error *ngIf="firstName.errors && firstName.errors['required']">
        {{ 'general.required' | translate}}
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <label>
        <input type="text"
               matInput
               placeholder="{{ 'add.user.dialog.last.name' | translate}}"
               formControlName="lastName"
               data-auto="user-last-name">
      </label>
      <mat-error *ngIf="lastName.errors && lastName.errors['required']">
        {{ 'general.required' | translate}}
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <label>
        <input type="text"
               matInput
               placeholder="{{ 'user.management.table.header.email' | translate}}"
               formControlName="email"
               data-auto="user-email"
               (ngModelChange)="emailAlreadyExists($event)">
      </label>
      <mat-error *ngIf="email.errors && email.errors['required']">
        {{ 'general.required' | translate}}
      </mat-error>
      <mat-error *ngIf="email.errors && email.errors['notUnique']">
        {{ 'error.email.already.exists' | translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <label>
        <input type="text"
               matInput
               placeholder="{{ 'user.management.table.header.phone' | translate}}"
               formControlName="phoneNumber"
               data-auto="user-phone">
      </label>
      <mat-error *ngIf="phoneNumber.errors && phoneNumber.errors['required']">
        {{ 'general.required' | translate}}
      </mat-error>
    </mat-form-field>

  </form>

  <div mat-dialog-actions style="margin-top: 12px;">
    <div style="margin-left: auto;">
      <button mat-raised-button
              color="primary"
              [disabled]="userForm.invalid"
              type="submit"
              (click)="onSaveClicked()"
              data-auto="save-user-button">
        <span>{{ 'general.submit' | translate}}</span>
      </button>
      <button mat-button mat-dialog-close>{{'general.cancel' | translate}}</button>
    </div>
  </div>
</div>
