<div>
  <p>
    {{'dialog-companies.reset.impersonation' | translate}}
  </p>

  <div class="spacer"></div>

  <div mat-dialog-actions>
    <button color="primary" mat-button id="company-reset-button"
            mat-raised-button (click)="resetImpersonation()">{{'dialog-companies.confirm' | translate}}
    </button>
    <button mat-button mat-dialog-close>{{'general.cancel' | translate}}</button>
  </div>
</div>
