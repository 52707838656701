<div class="container">
  <h1>
    {{'priority.dialog.title' | translate}}
  </h1>
  <mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="priority">
      <mat-header-cell *matHeaderCellDef>
        {{ 'priority.dialog.priority.header' | translate}}
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        {{ row.priority | translate }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="hours">
      <mat-header-cell *matHeaderCellDef>
        {{ 'priority.dialog.slo.header' | translate}}
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        {{ row.hours }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="description">
      <mat-header-cell *matHeaderCellDef>
        {{ 'priority.dialog.description.header' | translate}}
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        {{ row.description | translate }}
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row class="row-cursor" *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>

  <div mat-dialog-actions style="margin-top: 12px;">
    <div style="margin-left: auto;">
      <button color="primary" mat-raised-button mat-dialog-close>{{ 'general.close' | translate}}</button>
    </div>
  </div>
</div>
