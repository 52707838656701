import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';


@Component({
  selector: 'app-download-link-no-agreement-dialog',
  templateUrl: './download-link-no-agreement-dialog.component.html',
  styleUrls: ['./download-link-no-agreement-dialog.component.scss']
})
export class DownloadLinkNoAgreementDialogComponent {


  constructor(
    private dialogRef: MatDialogRef<DownloadLinkNoAgreementDialogComponent>
  ) {
  }

  onCloseClicked() {
    this.dialogRef.close();
  }
}
