import {Component, Input, OnInit} from '@angular/core';
import {ProjectIssue} from '../../api/project-api.service';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-project-issues',
  templateUrl: './project-issues.component.html',
  styleUrls: ['./project-issues.component.scss']
})

export class ProjectIssuesComponent implements OnInit {

  @Input() projectIssues: ProjectIssue[];
  dataSource: MatTableDataSource<ProjectIssue>;

  showFilters = false;

  priorityMap = {
    'Priority 1 - Critical': 'critical',
    'Priority 2 - High': 'high',
    'Priority 3 - Medium': 'medium',
    'Priority 4 - Low': 'low',
    'Escalated': 'escalated'
  };

  statusMap = {
    'New': 'new',
    'In Progress': 'inProgress',
    'Pending': 'pending',
    'Blocked': 'blocked',
    'Resolved': 'resolved'
  };

  issuesDisplayedColumns = [
    'id',
    'issueStatus',
    'priority',
    'initialDescription',
    'issueOwner',
    'dateEntered',
    'impactSummary',
    'actionSteps'
  ];

  priorityFilters = {
    critical: true,
    high: true,
    medium: true,
    low: true,
    escalated: true
  };

  statusFilters = {
    new: true,
    inProgress: true,
    pending: true,
    blocked: true,
    resolved: true
  };

  ngOnInit() {
    this.dataSource = new MatTableDataSource(this.projectIssues);
    this.dataSource.filterPredicate = (projectIssue: ProjectIssue) => {
      return this.priorityFilters[this.priorityMap[projectIssue.urgency]] &&
        this.statusFilters[this.statusMap[projectIssue.issueStatus]];
    };
  }

  applyFilter() {
    this.dataSource.filter = 's8cp'; // it appears necessary to pass a non-empty string
  }

  getPriorityTranslation(projectIssue: ProjectIssue): string {
    switch (this.priorityMap[projectIssue.urgency]) {
      case 'critical':
        return 'project.issue.priority.critical';
      case 'high':
        return 'project.issue.priority.high';
      case 'medium':
        return 'project.issue.priority.medium';
      case 'low':
        return 'project.issue.priority.low';
      case 'escalated':
        return 'project.issue.priority.escalated';
      default:
        return 'project.issue.priority.default';
    }
  }

  getStatusTranslation(projectIssue: ProjectIssue): string {
    switch (this.statusMap[projectIssue.issueStatus]) {
      case 'new':
        return 'project.issue.status.new';
      case 'inProgress':
        return 'project.issue.status.in.progress';
      case 'pending':
        return 'project.issue.status.pending';
      case 'blocked':
        return 'project.issue.status.blocked';
      case 'resolved':
        return 'project.issue.status.resolved';
      default:
        return 'project.issue.priority.default';
    }
  }

  toggleFilters() {
    this.showFilters = !this.showFilters;
  }

  setCheckboxes(value: boolean) {
    Object.keys(this.statusFilters).forEach(key => this.statusFilters[key] = value);
    Object.keys(this.priorityFilters).forEach(key => this.priorityFilters[key] = value);
    this.applyFilter();
  }
}

