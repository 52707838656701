import {ErrorHandler, Injectable, NgZone} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ErrorSnackBarComponent} from './error-snack-bar.component';
import {ErrorReportApiService} from '../api/error-report-api.service';
import {AuthService} from '../auth.service';
import {ErrorSnackBarService} from './error-snack-bar.service';

@Injectable({
  providedIn: 'root'
})
export class AppErrorHandler implements ErrorHandler {

  constructor(
    private snackBar: MatSnackBar,
    private zone: NgZone,
    private errorReportApiService: ErrorReportApiService,
    private authService: AuthService,
    private errorSnackBarService: ErrorSnackBarService,
  ) {
  }

  handleError(error: any): void {
    console.error(error);
    if (error.status === 401) {
      this.authService.navigateToSignInWithReturnUrl(error.url);
    }

    this.errorReportApiService.reportError(error).subscribe();
    this.zone.run(() => {
      this.errorSnackBarService.matSnackBarRef = this.snackBar.openFromComponent(ErrorSnackBarComponent);
    });

  }
}
