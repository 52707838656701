<div class="reset-pass-container">
  <mat-card class="reset-pass-card mat-elevation-z8">
    <mat-card-title>
      <img src="../../assets/ziro-logo-small2.png" width="200px">
      <button mat-icon-button
              matTooltip="{{ 'layout.change.language.tooltip' | translate }}"
              (click)="onLanguageClicked()"
              style="float: right;">
        <mat-icon>language</mat-icon>
      </button>
    </mat-card-title>
    <mat-card-content>
      <p>{{ 'reset.password.instruction' | translate}}</p>
      <form [formGroup]="resetPassForm">

        <div class="reset-pass-form">
          <mat-form-field data-auto="reset-pass-username">
            <input matInput placeholder="{{ 'sign.in.email' | translate}}" formControlName="username" class="foo">
            <mat-error *ngIf="username.errors && username.errors['required']">{{ 'sign.in.email.error' | translate}}</mat-error>
          </mat-form-field>
        </div>

        <div class="reset-pass-actions">
          <button mat-raised-button
                  color="primary"
                  [disabled]="isInProgress"
                  data-auto="reset-pass-button"
                  (click)="onSendClicked()">
            <span>{{ 'change.password.send' | translate}}</span>
          </button>
        </div>
      </form>
    </mat-card-content>
    <mat-card-footer>
      <mat-progress-bar mode="indeterminate" *ngIf="isInProgress"></mat-progress-bar>
    </mat-card-footer>
  </mat-card>
</div>
