<div class="ticket-container">
  <h1>{{ 'create.ticket.title' | translate}}</h1>
  <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>

  <div *ngIf="!isLoading">
    <div *ngIf="canCreateTicketForOthers()">
      <mat-form-field style="width: 300px">
        <input type="text"
               placeholder="{{ 'create.ticket.contact' | translate}}"
               matInput [formControl]="onBehalf"
               [matAutocomplete]="auto"
               id="input-on-behalf">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
          <mat-option *ngFor="let user of filteredPortalContacts | async" [value]="user">
            {{ user.firstName + ' ' + user.lastName }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>

    <form [formGroup]="ticketForm">
      <div class="ticket-form">

        <mat-form-field>
          <label>
            <input type="text"
                   matInput
                   placeholder="{{ 'create.ticket.ticket.title' | translate}}"
                   formControlName="title"
                   tabindex="1"
                   data-auto="create-ticket-title">
          </label>
          <mat-error *ngIf="title.errors && title.errors['required']">
            {{ 'create.ticket.ticket.title.error' | translate}}
          </mat-error>
          <mat-error *ngIf="title.errors && title.errors['maxlength']">
            {{ 'create.ticket.title.too.long' | translate}}
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <label>
            <textarea matInput
                      placeholder="{{ 'create.ticket.ticket.description' | translate}}"
                      formControlName="description"
                      rows="5"
                      tabindex="2"
                      data-auto="ticket-description">
            </textarea>
          </label>
          <mat-error *ngIf="description.errors && description.errors['required']">
            {{ 'create.ticket.ticket.description.error' | translate}}
          </mat-error>
        </mat-form-field>
      </div>
    </form>

    <app-file-upload (filesChangedEvent)="receiveFilesChange()"></app-file-upload>

    <div class="ticket-actions">
      <button mat-raised-button
              color="primary"
              [disabled]="isSaving || hasLargeFile || !this.ticketForm.valid"
              type="submit"
              (click)="onNewTicketSave()"
              tabindex="3"
              data-auto="create-ticket-submit-button">
        <span>{{ 'general.submit' | translate}}</span>
      </button>
    </div>
  </div>
</div>
