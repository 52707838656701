import {Component, OnInit, ViewChild} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-ticket-report',
  templateUrl: './ticket-report.component.html',
  styleUrls: ['./ticket-report.component.scss']
})
export class TicketReportComponent implements OnInit {

  @ViewChild(MatSort, {static: true}) sort: MatSort;

  dataSource: MatTableDataSource<CompanyFile> = new MatTableDataSource<CompanyFile>();
  isLoading: boolean;

  constructor(private http: HttpClient) {
  }

  ngOnInit() {
    this.dataSource.sort = this.sort;
    this.isLoading = true;

    this.http.get<CompanyFile[]>('/api/ticket-report-files')
      .subscribe(results => {
        this.dataSource.data = results;
        this.isLoading = false;
      });
  }

  download(file: CompanyFile) {

    this.http.get(
      '/api/ticket-report-files/' + encodeURIComponent(file.id) + '/download',
      {responseType: 'blob'}
    ).subscribe(f => {
      saveAs(f, file.filename);
    });
  }

  toFileSize(bytes): string {
    const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    // validate 'bytes'
    if (isNaN(parseFloat(bytes))) {
      return '-';
    }
    if (bytes < 1) {
      return '0 B';
    }
    const unitIndex = Math.floor(Math.log(bytes) / Math.log(1000)),
      value = bytes / Math.pow(1000, unitIndex);
    return value.toFixed(2) + ' ' + units[unitIndex];
  }

}

export class CompanyFile {
  id: string;
  filename: string;
  size: number;
}
