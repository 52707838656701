import {Injectable} from '@angular/core';
import {RouterPathSegments} from './route/router-paths';

@Injectable({
  providedIn: 'root'
})
export class RouteService {

  public dashboard() {
    return ['/', RouterPathSegments.dashboard];
  }

  public createTicket() {
    return ['/', RouterPathSegments.createTicket];
  }

  public ticketReport() {
    return ['/', RouterPathSegments.ticketReport];
  }

  public changePassword() {
    return ['/', RouterPathSegments.changePassword];
  }

  public resetPassword() {
    return ['/', RouterPathSegments.resetPassword];
  }

  public signIn() {
    return ['/', RouterPathSegments.signIn];
  }

  public ticket(ticketId: number, tab = 'description') {
    return ['/', RouterPathSegments.ticket, ticketId, {tab: tab}];
  }

  public recentTicket(ticketId: string) {
    return ['/', RouterPathSegments.recentTicket, ticketId];
  }

  public ticketSearch() {
    return ['/', RouterPathSegments.ticketSearch];
  }

  public projectList() {
    return ['/', RouterPathSegments.projectList];
  }

  public project(projectId: string, tab = 'description') {
    return ['/', RouterPathSegments.project, projectId, {tab: tab}];
  }

  public userManagement() {
    return ['/', RouterPathSegments.userManagement];
  }

  public configurePortal() {
    return ['/', RouterPathSegments.configurePortal];
  }

  public ziroDashboard() {
    return ['/', RouterPathSegments.ziroDashboard];
  }
}
