import {Injectable} from '@angular/core';
import {AuthApiService} from './api/auth-api.service';
import {NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent} from '@angular/router';
import {filter, map, switchMap, tap} from 'rxjs/operators';
import {HttpCancelService} from './http-cancel.service';
import {from, Observable, of} from 'rxjs';
import {CurrentContactService} from './current-contact.service';
import {RouteService} from './route.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private redirectUrl: string;
  private isRouting: boolean;
  private routingToUrl: string;

  constructor(
    private authApiService: AuthApiService,
    private router: Router,
    private httpCancelService: HttpCancelService,
    private currentContactService: CurrentContactService,
    private routeService: RouteService,
  ) {

    /*
     This sets up a handler for when a route resolve fails because of an http 401 status. This happens when the client
     believes the user is logged in but the server indicates otherwise (the server is always right). This occurs
     when the the remember-me cookie and/or local storage flag not valid, or the jsessionid or not valid.
    */
    router.events
      .pipe(
        filter(
          event => event instanceof NavigationStart ||
            event instanceof NavigationEnd ||
            event instanceof NavigationCancel ||
            event instanceof NavigationError
        )
      )
      .subscribe((event: RouterEvent) => {
        if (event instanceof NavigationStart) {
          this.isRouting = true;
          this.routingToUrl = event.url;
        } else {
          this.isRouting = false;
          this.routingToUrl = null;
        }
      });
  }

  onApiUnauthorized() {
    /*
    When we get one unauthorized request, we're going to routing the user back to the sign in page.
    Any further http requests are cancelled since whatever was going on is no longer relevant.
    For example if a component fires two requests in ngOnInit, and the first one returns a 401, the
    remaining one, which in all likelihood will also return a 401, gets cancelled.
    */
    this.httpCancelService.cancelPendingRequests();

    // Reset the state to signed-out (ie maybe the remember me state is wrong for example)
    this.onSignedOut();

    /*
     Send the user to the sign-in page.
     This also saves the url the user was at, or in the case of an error during a routing event, the url the user
     wanted to go to, so that when the user can be sent there after a successful sign in.
      */
    this.navigateToSignInWithReturnUrl(this.isRouting ? this.routingToUrl : this.router.url);
  }

  navigateAfterLogin() {
    if (this.redirectUrl) {
      return this.router.navigateByUrl(this.redirectUrl);
    }
    return this.goHome();
  }

  goHome() {
    return this.currentContactService.resolve().then(() => {
      const contact = this.currentContactService.getContactMaybe();
      if (!contact) {
        // If there's no contact, we will have automatically been redirected to the sign-in page as part of
        // resolving the current contact.
        return;
      }
      return this.router.navigate(this.routeService.dashboard());
    });
  }

  doSignIn(username: string, password: string, rememberMe: boolean): Observable<DoSignInResult> {

    return this.authApiService.signIn(username, password, rememberMe)
      .pipe(
        switchMap(value => {
          // if the auth was successful, load up the contact's info
          if (value === 200) {
            return from(this.currentContactService.reload())
              .pipe(
                map(() => {
                  return value;
                })
              );
          }
          return of(value);
        }),
        map(value => {

          const doSignInResult = new DoSignInResult();
          doSignInResult.status = value;
          if (doSignInResult.status !== 200) {
            RememberMeLocalStorage.set(false);
            HasSignedInLocalStorage.set(false);
            return doSignInResult;
          }

          RememberMeLocalStorage.set(rememberMe);
          HasSignedInLocalStorage.set(true);

          doSignInResult.navPromise = this.navigateAfterLogin();
          return doSignInResult;
        })
      );
  }

  doSignOut(): Observable<any> {
    return this.authApiService.signOut()
      .pipe(
        tap(() => {
          this.onSignedOut();
        })
      );
  }

  hasReturnUrl(): boolean {
    return this.redirectUrl != null;
  }

  navigateToSignInWithReturnUrl(url: string) {
    this.redirectUrl = url;
    // noinspection JSIgnoredPromiseFromCall
    this.router.navigate(this.routeService.signIn());
  }

  isAuthenticated(): boolean {
    return HasSignedInLocalStorage.get() || RememberMeLocalStorage.get();
  }

  private onSignedOut() {
    RememberMeLocalStorage.set(false);
    this.currentContactService.clear();
    HasSignedInLocalStorage.set(false);
  }
}

namespace RememberMeLocalStorage {
  export function get(): boolean {
    const item = localStorage.getItem('rememberMe');
    return item && item === 'true';
  }

  export function set(value: boolean) {
    localStorage.setItem('rememberMe', value ? 'true' : 'false');
  }
}

namespace HasSignedInLocalStorage {
  export function get(): boolean {
    const item = localStorage.getItem('hasSignedIn');
    return item && item === 'true';
  }

  export function set(value: boolean) {
    localStorage.setItem('hasSignedIn', value ? 'true' : 'false');
  }
}

export class DoSignInResult {
  status: number;
  navPromise: Promise<any>;
}
