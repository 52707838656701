/**
 * These must be static for AOT compile to not screw it up.
 * https://github.com/angular/angular/issues/18662#issuecomment-382993458
 */
export const RouterPathSegments = {
  dashboard: 'dashboard',
  ticket: 'ticket',
  ticketSearch: 'ticket-search',
  recentTicket: 'recent-ticket',
  signIn: 'sign-in',
  createTicket: 'create-ticket',
  ticketReport: 'ticket-report',
  changePassword: 'change-password',
  resetPassword: 'reset-password',
  projectList: 'project-list',
  project: 'project',
  userManagement: 'user-management',
  configurePortal: 'configure-portal',
  ziroDashboard: 'ziro',
};

export const RouterPathParams = {
  ticketId: 'ticketDd',
  projectId: 'projectId'
};

export const RouterPaths = {
  ticket: RouterPathSegments.ticket + '/:' + RouterPathParams.ticketId,
  recentTicket: RouterPathSegments.recentTicket + '/:' + RouterPathParams.ticketId,
  dashboard: RouterPathSegments.dashboard,
  ticketSearch: RouterPathSegments.ticketSearch,
  signIn: RouterPathSegments.signIn,
  createTicket: RouterPathSegments.createTicket,
  ticketReport: RouterPathSegments.ticketReport,
  changePassword: RouterPathSegments.changePassword,
  resetPassword: RouterPathSegments.resetPassword,
  projectList: RouterPathSegments.projectList,
  project: RouterPathSegments.project + '/:' + RouterPathParams.projectId,
  userManagement: RouterPathSegments.userManagement,
  configurePortal: RouterPathSegments.configurePortal,
  ziroDashboard: RouterPathSegments.ziroDashboard,
};
