import {Injectable} from '@angular/core';
import {CurrentContactService} from './current-contact.service';
import * as moment from 'moment';
import {TranslateService} from '@ngx-translate/core';
import {NavigationEnd, Router} from '@angular/router';

declare var gtag: any;

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  private sessionId: string;

  constructor(
    private currentContactService: CurrentContactService,
    private translate: TranslateService,
    private router: Router,
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.onPageView(event);
      }
    });
  }

  onPageView(event: NavigationEnd) {
    // some redirection happened, log the initial url as well as the final
    if (event.urlAfterRedirects !== event.url) {
      this.onPageViewUrl(event.url);
    }
    this.onPageViewUrl(event.urlAfterRedirects);
  }

  private onPageViewUrl(url: string) {
  }

  trackEvent(action: string, category: EventCategory, label?: string) {
  }
}

export enum EventCategory {
  Ticket = 'Ticket',
  Authentication = 'Authentication',
  Language = 'Language',
  Project = 'Project',
  User_Management = 'User Management',
  Company = 'Company Management',
}
