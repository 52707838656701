import {Pipe, PipeTransform} from '@angular/core';
import {LanguageService} from './language.service';
import {DatePipe} from '@angular/common';

@Pipe({
  name: 'localizedDateShort'
})
export class LocalizedDatePipe implements PipeTransform {

  constructor(private languageService: LanguageService) {
  }

  transform(value: any, pattern: string = 'mediumDate'): any {
    return new DatePipe(this.languageService.getCurrent()).transform(value, pattern);
  }
}

@Pipe({
  name: 'localizedDateMedium'
})
export class LocalizedDatePipeMedium implements PipeTransform {

  constructor(private languageService: LanguageService) {
  }

  transform(value: any, pattern: string = 'medium'): any {
    return new DatePipe(this.languageService.getCurrent()).transform(value, pattern);
  }
}
