import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-project-list-health-dialog',
  templateUrl: './project-list-health-dialog.component.html',
  styleUrls: ['./project-list-health-dialog.component.scss']
})
export class ProjectListHealthDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ProjectListHealthDialogComponent>,
  ) { }

  ngOnInit() {
  }

  getDonutData(): number[] {
    return [0.6, 0.4];
  }

  getDonutColor() {
    const healthColor = '#008000';
    return [
      {
        backgroundColor: [healthColor, '#FFFFFF'],
        borderColor: [healthColor, healthColor],
        borderWidth: 1.2
      }
    ];
  }

  getDonutOptions() {
    return {
      cutoutPercentage: 60,
      animation: false
    };
  }

  onCancelClicked() {
    this.dialogRef.close();
  }
}
