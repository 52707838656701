import { MatPaginatorIntl } from '@angular/material/paginator';
import {TranslateService} from '@ngx-translate/core';

export class LocalizedPaginator extends MatPaginatorIntl {
  translate: TranslateService;
  itemsPerPageLabel = 'Items per page';
  nextPageLabel = 'Next page';
  previousPageLabel = 'Previous page';
  lastPageLabel = 'Last Page';
  firstPageLabel = 'First Page';

  injectTranslateService(translate: TranslateService) {
    this.translate = translate;

    this.translate.onLangChange.subscribe(() => {
      this.translateLabels();
    });

    this.translateLabels();
  }

  getRangeLabel = function (page, pageSize, length) {
    let of = this.translate.instant('paginator.of');
    this.translate.get('paginator.of').subscribe(value => of = value);

    if (length === 0 || pageSize === 0) {
      return '0 ' + of + ' ' + length;
    }

    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;
    return startIndex + 1 + ' - ' + endIndex + ' ' + of + ' ' + length;
  };

  translateLabels() {
    this.itemsPerPageLabel = this.translate.instant('paginator.items.per.page');
    this.nextPageLabel = this.translate.instant('paginator.next.page');
    this.previousPageLabel = this.translate.instant('paginator.previous.page');
    this.lastPageLabel = this.translate.instant('paginator.last.page');
    this.firstPageLabel = this.translate.instant('paginator.first.page');
  }
}
