<div class="container">
  <h1
    mat-dialog-title>{{ 'ziro.dashboard.your.ziro.instances.download.latest.version.no.agreement.title' | translate}}</h1>
  <span
    class="dialog-content">{{ 'ziro.dashboard.your.ziro.instances.download.latest.version.no.agreement' | translate}} </span>
  <div mat-dialog-actions>
    <div style="margin-left: auto; padding-top: 28px; padding-bottom: 28px">
      <button color="primary" mat-raised-button (click)="onCloseClicked()">{{ 'general.close' | translate}}</button>
    </div>
  </div>
</div>
