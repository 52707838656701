<div class="sign-in-container">
  <mat-card class="sign-in-card mat-elevation-z8">
    <mat-card-title>
      <img src="../../assets/ziro-logo-small2.png" width="200px" alt="logo">
      <button mat-icon-button
              matTooltip="{{ 'layout.change.language.tooltip' | translate }}"
              (click)="onLanguageClicked()"
              style="float: right;"
              data-auto="change-language-button">
        <mat-icon>language</mat-icon>
      </button>
    </mat-card-title>


    <mat-card-subtitle *ngIf="hasReturnUrl">
      <em>{{ 'sign.in.sign.in.warning' | translate }}</em>
    </mat-card-subtitle>
    <h1><b>{{ 'sign.in.login.title' | translate}}</b></h1>
    <mat-card-content>
      <form [formGroup]="signInForm">
        <div class="sign-in-form">
          <mat-form-field data-auto="sign-in-username">
            <input matInput placeholder="{{ 'sign.in.email' | translate}}" formControlName="username" tabindex="1" autocomplete="email">
            <mat-error *ngIf="username.errors && username.errors['required']">{{ 'sign.in.email.error' | translate}}</mat-error>
          </mat-form-field>

          <mat-form-field data-auto="sign-in-password">
            <input matInput placeholder="{{ 'sign.in.password' | translate}}" formControlName="password" type="password" tabindex="2" autocomplete="current-password">
            <mat-error *ngIf="password.errors && password.errors['required']">{{ 'sign.in.password.error' | translate}}</mat-error>
          </mat-form-field>

          <!--
          Turns out putting a checkbox into a form is not super easy... but possible!
          Open issue for checkboxes and radio buttons in mat-form-field: https://github.com/angular/material2/issues/7891
          Comment that lead to this solution: https://github.com/angular/material2/issues/7891#issuecomment-342691238
          Also, `mat-form-field-no-underline` is defined in the style.css file. It removes the underline that is
          usually on mat-form-fields.
          -->
          <mat-form-field class="mat-form-field-no-underline">
            <input name="rememberMe" matInput style="display: none" tabindex="5">
            <mat-checkbox color="accent"
                          formControlName="rememberMe"
                          [tabIndex]="3"
                          data-auto="remember-me"
                          (change)="onRememberMeChanged()">
              {{ 'sign.in.remember.me' | translate}}
            </mat-checkbox>
          </mat-form-field>
        </div>

        <div class="sign-in-actions">
          <a [routerLink]="routeService.resetPassword()" class="sign-in-forgot-password-link" tabindex="5" data-auto="forgot-password-link">
            <span>{{ 'sign.in.forgot.password' | translate}}</span>
          </a>
          <button mat-raised-button
                  color="primary"
                  [disabled]="isInProgress"
                  data-auto="sign-in-button"
                  tabindex="4"
                  (click)="onSignInClicked()">
            <span>{{ 'sign.in.sign.in.button' | translate}}</span>
          </button>
        </div>
      </form>
    </mat-card-content>
    <mat-card-footer>
      <mat-progress-bar mode="indeterminate" *ngIf="isInProgress"></mat-progress-bar>
    </mat-card-footer>
  </mat-card>
</div>
