import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {SignInComponent} from '../sign-in/sign-in.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule} from '@angular/forms';
import {ResetPasswordComponent} from '../reset-password/reset-password.component';
import {LayoutComponent} from '../layout/layout.component';
import {AuthGuardService} from '../auth-guard.service';
import {TicketComponent} from '../ticket/ticket.component';
import {TicketSearchComponent} from '../ticket-search/ticket-search.component';
import {CanDeactivateGuard} from '../can-deactivate/can-deactivate.guard';
import {CurrentContactService} from '../current-contact.service';
import {CreateTicketComponent} from '../create-ticket/create-ticket.component';
import {DashboardComponent} from '../dashboard/dashboard.component';
import {TicketReportComponent} from '../ticket-report/ticket-report.component';
import {RouterPaths} from './router-paths';
import {ProjectListComponent} from '../project-list/project-list.component';
import {ProjectComponent} from '../project/project.component';
import {UserManagementComponent} from '../user-management/user-management.component';
import {ConfigurePortalComponent} from '../configure-portal/configure-portal.component';
import {ZiroDashboardComponent} from '../ziro-dashboard/ziro-dashboard.component';

// the canonical ticket route
const ticketRoute = {
  path: RouterPaths.ticket,
  component: TicketComponent,
  canDeactivate: [CanDeactivateGuard],
  canActivate: [AuthGuardService]
};

// alternative path to ticket - this is done to track different entry points in analytics
const recentTicketRoute = {
  path: RouterPaths.recentTicket,
  canDeactivate: [CanDeactivateGuard],
  redirectTo: RouterPaths.ticket,
};

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    resolve: {
      contact: CurrentContactService
    },
    children: [
      {
        path: '',
        redirectTo: RouterPaths.dashboard,
        canActivate: [AuthGuardService],
        pathMatch: 'full'
      },
      {
        path: RouterPaths.ziroDashboard,
        component: ZiroDashboardComponent,
        canActivate: [AuthGuardService],
        data: {
          titleKey: 'ziro.dashboard.title'
        }
      },
      {
        path: RouterPaths.ticketSearch,
        component: TicketSearchComponent,
        canActivate: [AuthGuardService],
        data: {
          titleKey: 'ticket.search.title'
        }
      },
      ticketRoute,
      recentTicketRoute,
      {
        path: RouterPaths.createTicket,
        component: CreateTicketComponent,
        canActivate: [AuthGuardService],
        canDeactivate: [CanDeactivateGuard],
        data: {
          titleKey: 'create.ticket.title'
        }
      },
      {
        path: RouterPaths.dashboard,
        component: DashboardComponent,
        canActivate: [AuthGuardService],
        data: {
          titleKey: 'dashboard.title'
        }
      },
      {
        path: RouterPaths.ticketReport,
        component: TicketReportComponent,
        canActivate: [AuthGuardService],
        data: {
          titleKey: 'ticket.report.title'
        }
      },
      {
        path: RouterPaths.projectList,
        component: ProjectListComponent,
        canActivate: [AuthGuardService],
        data: {
          titleKey: 'project.list.title'
        }
      },
      {
        path: RouterPaths.project,
        component: ProjectComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: RouterPaths.userManagement,
        component: UserManagementComponent,
        canDeactivate: [CanDeactivateGuard],
        canActivate: [AuthGuardService],
        data: {
          titleKey: 'user.management.title'
        }
      },
      {
        path: RouterPaths.configurePortal,
        component: ConfigurePortalComponent,
        canActivate: [AuthGuardService],
        data: {
          titleKey: 'configure.portal.title'
        }
      }
    ]
  },
  {
    path: RouterPaths.signIn,
    component: SignInComponent,
    data: {
      titleKey: 'sign.in.title'
    }
  },
  {
    path: RouterPaths.resetPassword,
    component: ResetPasswordComponent,
    data: {
      titleKey: 'reset.password.title'
    }
  },
  {
    /*
    We don't have an auth guard or current contact resolve here since this route is used for authenticated
    change password as well as unauthenticated token based.
    We should probably break these out into individual components to clarify this.
    */
    path: RouterPaths.changePassword,
    loadChildren: '../change-password/change-password.module#ChangePasswordModule',
    data: {
      titleKey: 'change.password.title'
    }
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {useHash: true}),
    BrowserAnimationsModule,
    FormsModule,
  ],
  exports: [
    RouterModule
  ]
})
export class RoutingModule {
}
