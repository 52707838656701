import {BrowserModule} from '@angular/platform-browser';
import {ApplicationRef, ErrorHandler, NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {JsonPipe, registerLocaleData} from '@angular/common';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material/core';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatPaginatorIntl, MatPaginatorModule} from '@angular/material/paginator';
import {MatRadioModule} from '@angular/material/radio';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTreeModule} from '@angular/material/tree';
import {MatSelectModule} from '@angular/material/select';

import {RootComponent} from './root/root.component';
import {RoutingModule} from './route/routing.module';
import {SignInComponent} from './sign-in/sign-in.component';
import {ResetPasswordComponent} from './reset-password/reset-password.component';
import {LayoutComponent} from './layout/layout.component';
import {TicketComponent} from './ticket/ticket.component';
import {TicketSearchComponent} from './ticket-search/ticket-search.component';
import {LayoutModule} from '@angular/cdk/layout';
import {TicketStatusDialogComponent} from './ticket/ticket-status-dialog.component';
import {TicketAddNoteDialogComponent} from './ticket/ticket-add-note-dialog.component';
import {ContactUsComponent} from './contact-us/contact-us.component';
import {AppErrorHandler} from './error/app-error-handler';
import {ErrorSnackBarComponent} from './error/error-snack-bar.component';
import {EcoFabSpeedDialModule} from '@ecodev/fab-speed-dial';
import {NgxUploaderModule} from 'ngx-uploader';
import {FileUploadComponent} from './file-upload/file-upload.component';
import {NgxDaterangepickerMd} from 'ngx-daterangepicker-material';
import {RecentTicketsComponent} from './recent-tickets/recent-tickets.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MomentDateAdapter, MomentDateModule} from '@angular/material-moment-adapter';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {LocalizedDatePipe, LocalizedDatePipeMedium} from './localized-date.pipe';
import localeFr from '@angular/common/locales/fr';
import {LocalizedPaginator} from './ticket-search/localized-paginator';
import {AppHttpInterceptor} from './app-http-interceptor';
import {TicketStatusPipe} from './ticket-status.pipe';
import {NoopAnimationsModule} from '@angular/platform-browser/animations';
import {environment} from '../environments/environment';
import {EscalationDialogComponent} from './ticket/escalation/escalation-dialog.component';
import {FileInfoComponent} from './file-upload/file-info.component';
import {CreateTicketComponent} from './create-ticket/create-ticket.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {TicketReportComponent} from './ticket-report/ticket-report.component';
import {ChartsModule} from 'ng2-charts/ng2-charts';
import {ContactUsDialogComponent} from './contact-us/contact-us-dialog.component';
import {ProgressSpinnerDialogComponent} from './progress-spinner-dialog/progress-spinner-dialog.component';
import {SharedModule} from './shared/shared.module';
import {WebpackTranslateLoader} from './webpack-translate-loader';
import {ProjectListComponent} from './project-list/project-list.component';
import {ProjectComponent} from './project/project.component';
import {ProjectListHealthDialogComponent} from './project-list/project-list-health-dialog/project-list-health-dialog.component';
import {ProjectIssuesComponent} from './project/project-issues/project-issues.component';
import {DialogCompaniesComponent} from './layout/company-context-dialog/dialog-companies.component';
import {UserManagementComponent} from './user-management/user-management.component';
import {UserDialogComponent} from './user-management/user-dialog/user-dialog.component';
import {CompaniesListComponent} from './layout/company-context-dialog/companies-list.component';
import {UserTypesComponent} from './layout/company-context-dialog/user-types.component';
import {ConfirmResetImpersonationDialogComponent} from './layout/company-context-dialog/confirm-reset-impersonation-dialog.component';
import {MatAutocompleteModule} from '@angular/material';
import {UserTypesDialogComponent} from './user-management/user-types-dialog/user-types-dialog.component';
import {DialogEditUserComponent} from './layout/dialog-edit-user/dialog-edit-user.component';
import {ConfigurePortalComponent} from './configure-portal/configure-portal.component';
import {TicketPriorityPipe} from './ticket-priority.pipe';
import {PrioritiesDialogComponent} from './ticket-search/priorities-dialog/priorities-dialog.component';
import {ZiroDashboardComponent} from './ziro-dashboard/ziro-dashboard.component';
import {AddTokenDialogComponent} from './ziro-dashboard/add-token-dialog/add-token-dialog.component';
import {DeleteTokenDialogComponent} from './ziro-dashboard/delete-token-dialog/delete-token-dialog.component';
import {CookieService} from 'ngx-cookie-service';
import {UpgradeZiroDialogComponent} from './ziro-dashboard/upgrade-ziro-dialog/upgrade-ziro-dialog.component';
import {
  DownloadLinkNoAgreementDialogComponent
} from './ziro-dashboard/download-link-no-agreement-dialog/download-link-no-agreement-dialog.component';

declare var window: any;

@NgModule({
  providers: [
    CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppHttpInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: AppErrorHandler
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: 'LL',
        },
        display: {
          dateInput: 'll',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY',
        },
      }
    },
    {
      provide: MatPaginatorIntl,
      useFactory: (translate) => {
        const service = new LocalizedPaginator();
        service.injectTranslateService(translate);
        return service;
      },
      deps: [TranslateService]
    }
  ],
  entryComponents: [
    RootComponent,
    TicketStatusDialogComponent,
    AddTokenDialogComponent,
    DeleteTokenDialogComponent,
    DownloadLinkNoAgreementDialogComponent,
    UpgradeZiroDialogComponent,
    TicketAddNoteDialogComponent,
    ErrorSnackBarComponent,
    EscalationDialogComponent,
    ContactUsDialogComponent,
    ProgressSpinnerDialogComponent,
    ProjectListHealthDialogComponent,
    UserDialogComponent,
    DialogCompaniesComponent,
    ConfirmResetImpersonationDialogComponent,
    UserTypesDialogComponent,
    PrioritiesDialogComponent,
    DialogEditUserComponent
  ],
  declarations: [
    RootComponent,
    SignInComponent,
    ResetPasswordComponent,
    LayoutComponent,
    TicketComponent,
    TicketSearchComponent,
    TicketStatusDialogComponent,
    AddTokenDialogComponent,
    DeleteTokenDialogComponent,
    DownloadLinkNoAgreementDialogComponent,
    UpgradeZiroDialogComponent,
    TicketAddNoteDialogComponent,
    ContactUsComponent,
    ErrorSnackBarComponent,
    FileUploadComponent,
    RecentTicketsComponent,
    LocalizedDatePipe,
    LocalizedDatePipeMedium,
    TicketStatusPipe,
    TicketPriorityPipe,
    EscalationDialogComponent,
    FileInfoComponent,
    CreateTicketComponent,
    DashboardComponent,
    TicketReportComponent,
    ContactUsDialogComponent,
    ProgressSpinnerDialogComponent,
    ProjectListComponent,
    ProjectComponent,
    ProjectListHealthDialogComponent,
    ProjectIssuesComponent,
    DialogCompaniesComponent,
    UserManagementComponent,
    UserDialogComponent,
    CompaniesListComponent,
    UserTypesComponent,
    ConfirmResetImpersonationDialogComponent,
    UserTypesDialogComponent,
    PrioritiesDialogComponent,
    DialogEditUserComponent,
    ConfigurePortalComponent,
    ZiroDashboardComponent
  ],
  imports: [
    BrowserModule,
    SharedModule,
    RoutingModule,
    HttpClientModule,
    MatProgressSpinnerModule,
    MatGridListModule,
    MatMenuModule,
    MatIconModule,
    MatToolbarModule,
    MatSnackBarModule,
    MatTableModule,
    MatSelectModule,
    MatSidenavModule,
    MatListModule,
    MatDividerModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatPaginatorModule,
    MatSortModule,
    LayoutModule,
    MatTabsModule,
    MatDialogModule,
    EcoFabSpeedDialModule,
    NgxUploaderModule,
    NgxDaterangepickerMd,
    MatDatepickerModule,
    MatNativeDateModule,
    MomentDateModule,
    MatTreeModule,
    MatRadioModule,
    ChartsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader
      }
    }),
    ...(environment['disableAnimations'] ? [NoopAnimationsModule] : []),
    MatAutocompleteModule
  ],
  exports: [
    JsonPipe,
  ]
})
export class AppModule {

  // noinspection JSUnusedGlobalSymbols,JSMethodCanBeStatic
  /**
   * The usual way to bootstrap an angular applicaiton is to declare a bootstrap attribute. We want to prevent the
   * application from loading in some cases, so we do this imperatively instead.
   * @param app
   */
  ngDoBootstrap(app: ApplicationRef) {

    // This is the usual bootstrap.
    app.bootstrap(RootComponent);
  }
}

registerLocaleData(localeFr);
