<table mat-table
       [dataSource]="dataSource"
       class="mat-elevation-z0 tickets-container"
       matSort>

  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef>
      <span>
          {{ 'recent.tickets.id.header' | translate }}
      </span>
      <mat-form-field>
        <input matInput name="ticketId" [formControl]="ticketIdFilter">
      </mat-form-field>
    </th>
    <td mat-cell *matCellDef="let row">
      <a [routerLink]="routeService.recentTicket(row.ticketId)" data-auto="recent-ticket-id">{{row.ticketId}}</a>
    </td>
  </ng-container>

  <ng-container matColumnDef="summary">
    <th mat-header-cell *matHeaderCellDef>
      <span>
        {{ 'recent.tickets.summary.header' | translate }}
      </span>
      <mat-form-field>
        <input matInput name="summary" [formControl]="summaryFilter">
      </mat-form-field>
    </th>
    <td mat-cell *matCellDef="let row" data-auto="recent-ticket-summary">
      <a [routerLink]="routeService.recentTicket(row.ticketId)">{{row.summary}}</a>
    </td>
  </ng-container>

  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef>
      <span>
        {{ 'recent.tickets.status.header' | translate }}
      </span>
      <mat-form-field>
        <input matInput name="status" [formControl]="statusFilter">
      </mat-form-field>
    </th>
    <td mat-cell *matCellDef="let row">
      {{row.status | ticketStatus }}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;" data-auto="recent-ticket-row"></tr>
</table>
