import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatDialogRef, MatOptionSelectionChange} from '@angular/material';
import {DialogCompaniesComponent} from './dialog-companies.component';
import {UserRoles} from '../../api/user-management-api.service';
import {Company} from '../../api/company-api.service';

@Component({
  selector: 'app-user-types',
  templateUrl: './user-types.component.html',
  styleUrls: ['./user-types.component.scss']
})
export class UserTypesComponent implements OnInit {
  @Input() selectedCompany: Company;
  @Input() selectedUserType: string;
  @Output() readonly selectedUserTypeChange = new EventEmitter();
  userRoles = [
    UserRoles.roles.ADMIN,
    UserRoles.roles.EXECUTIVE,
    UserRoles.roles.MANAGER,
    UserRoles.roles.USER
  ];

  constructor(private dialogRef: MatDialogRef<DialogCompaniesComponent>) {
  }

  ngOnInit() {
    this.selectedUserType = UserRoles.roles.USER.value;
    this.dialogRef.updateSize('400px', '300px');
  }

  changeUserTypeSelection(event: MatOptionSelectionChange, value: string) {
    if (event.source.selected) {
      this.selectedUserType = value;
      this.selectedUserTypeChange.emit(this.selectedUserType);
    }
  }
}
