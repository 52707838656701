import {Component, EventEmitter, Input, Output} from '@angular/core';
import {UploadFile} from 'ngx-uploader';
import {tap} from 'rxjs/operators';
import {HttpEvent, HttpEventType} from '@angular/common/http';
import {TicketApiService} from '../api/ticket-api.service';

@Component({
  selector: 'app-file-info',
  templateUrl: './file-info.component.html',
  styleUrls: ['./file-info.component.scss']
})
export class FileInfoComponent {

  maxFileSizeKb: number = 50 * 1024;

  @Input() fileInfo: FileInfo;
  @Output() readonly fileRemovedEvent: EventEmitter<string> = new EventEmitter();

  constructor(
    private ticketApiService: TicketApiService,
  ) {
  }

  // noinspection JSMethodCanBeStatic
  ceiling(fileSize: number): number {
    return Math.ceil(fileSize / 1024);
  }

  removeFile() {
    this.fileRemovedEvent.emit(this.fileInfo.file.id);
  }

  public fileUpload(ticketId: string) {
    return this.ticketApiService.uploadAttachment(ticketId, this.file)
      .pipe(
        tap((event: HttpEvent<any>) => {
          switch (event.type) {
            case HttpEventType.Sent: {
              this.fileInfo.percentUpload = 0;
              this.fileInfo.isDownloading = true;
              break;
            }
            case HttpEventType.Response: {
              this.fileInfo.isDownloading = false;
              break;
            }
            case HttpEventType.UploadProgress: {
              if (Math.round(this.fileInfo.percentUpload) !== Math.round(event['loaded'] / event['total'] * 100)) {
                this.fileInfo.percentUpload = (Math.round(event['loaded'] / event['total'] * 100));
              }
              break;
            }
          }
        }));
  }

  get file() {
    return this.fileInfo.file;
  }
}

export class FileInfo {
  file: UploadFile;
  isDownloading: boolean;
  percentUpload: number;

  constructor(file: UploadFile) {
    this.file = file;
    this.isDownloading = false;
    this.percentUpload = 0;
  }
}
