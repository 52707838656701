<div class="container">
  <h1 mat-dialog-title>{{ 'create.ticket.note.dialog.title' | translate}}</h1>
  <h2>
    <mat-error *ngIf="isClosed()">
      <span data-auto="add-note-dialog-text-closed-ticket-warning">
      <mat-icon style="vertical-align: bottom; margin-bottom: 5px">warning</mat-icon>
        {{ 'create.ticket.note.dialog.closed.1' | translate}}
        <strong>{{ 'create.ticket.note.dialog.closed.2' | translate}}</strong>
        {{ 'create.ticket.note.dialog.closed.3' | translate}}
        </span>
    </mat-error>
  </h2>
  <div mat-dialog-content>
    <form [formGroup]="noteForm" (keypress)="onKeyPress()">

      <div class="add-note-form">
        <mat-form-field>
        <textarea matInput
                  placeholder="{{ 'create.ticket.note.dialog.note' | translate}}"
                  formControlName="note"
                  rows="5"
                  tabindex="2"
                  data-auto="add-note-dialog-text">
        </textarea>
          <mat-error *ngIf="note.dirty && note.errors && note.errors['required']">
            Please provide a note
          </mat-error>
        </mat-form-field>

      </div>
    </form>
    <app-file-upload *ngIf="!isClosed()" (filesChangedEvent)="receiveFilesChange()"></app-file-upload>
  </div>
  <div>
    <div class="button-row">
      <button mat-raised-button
              [disabled]="hasLargeFile"
              color="primary"
              (click)="onAddNoteClicked()"
              data-auto="add-note-dialog-submit">
        <span>{{ 'create.ticket.note.dialog.add.note.button' | translate}}</span>
      </button>
      <button mat-button (click)="onCancelClicked()">{{ 'general.close' | translate}}</button>
    </div>
  </div>
</div>
