import {EventEmitter, Injectable} from '@angular/core';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {AnalyticsService, EventCategory} from './analytics.service';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  constructor(
    private translate: TranslateService,
    private analyticsService: AnalyticsService
  ) {
    if (!LangLocalStorage.get()) {
      let navigatorLanguages = navigator.languages || [];
      navigatorLanguages = navigatorLanguages.filter(value => value === 'en' || value === 'fr');
      LangLocalStorage.set(navigatorLanguages.length > 0 ? navigatorLanguages[0] : 'en');
    }

    this.translate.use(LangLocalStorage.get());
  }

  changeLanguage() {
    LangLocalStorage.set(LangLocalStorage.get() === 'en' ? 'fr' : 'en');
    this.analyticsService.trackEvent('Change Language', EventCategory.Language, LangLocalStorage.get());
    /*
     We assume that changing the language is not something that users do often. Therefore we allow the application
     to load and apply translations statically (which keeps things simpler because the app doesn't need to react to
     language changes dynamically), which then requires a full reload of the application when the language is changed
     to ensure that translations are all reloaded in the correct language.
      */
    window.location.reload();
  }

  // noinspection JSMethodCanBeStatic
  getCurrent() {
    return LangLocalStorage.get();
  }
}


namespace LangLocalStorage {
  export function get(): string {
    return localStorage.getItem('lang');
  }

  export function set(value: string) {
    localStorage.setItem('lang', value);
  }
}
