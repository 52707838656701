import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {ContactJson} from './auth-api.service';
import {CurrentContactService} from '../current-contact.service';
import {LanguageService} from '../language.service';

@Injectable({
  providedIn: 'root'
})
export class UserManagementApiService {

  constructor(
    private http: HttpClient,
    private currentContactService: CurrentContactService,
    private languageService: LanguageService) {
  }

  getUsersForCompany(companyId: number): Observable<ContactJson[]> {
    return this.http.get<ContactJson[]>('/api/companies/' + encodeURIComponent(companyId.toString()) + '/users');
  }

  getUsersForCompanyCurrentContact(): Observable<String[]> {
    return this.getUsersForCompany(this.currentContactService.getContact().realCompanyId)
      .pipe(
        map(value => {
            return value.map(a => a.email);
          }
        ));
  }

  createUser(user: ContactJson): Observable<any> {
    return this.http.post<ContactJson>('/api/contact', user, {observe: 'response'})
      .pipe(
        catchError(err => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 400) {
              return of(err);
            }
          }
          return throwError(err);
        }),
        map((response: HttpResponse<any>) => {
          return response;
        })
      );
  }

  updateUser(user: ContactJson): Observable<any> {
    return this.http.patch<ContactJson>('/api/contact/' + user.contactId, user, {observe: 'response'})
      .pipe(
        catchError(err => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 400) {
              return of(err);
            }
          }
          return throwError(err);
        }),
        map((response: HttpResponse<any>) => {
          return response;
        })
      );
  }

  updateCurrentContact(newUserInfo: { firstName: string; lastName: string; phoneNumber: string; email: string }): Observable<any> {
    const currentContact = this.currentContactService.getContact();
    currentContact.firstName = newUserInfo.firstName;
    currentContact.lastName = newUserInfo.lastName;
    currentContact.phoneNumber = newUserInfo.phoneNumber;
    currentContact.email = newUserInfo.email;
    currentContact.language = this.languageService.getCurrent();
    return this.updateUser(currentContact);
  }
}

export class UserRoles {
  static roles = {
    ZIRO: {i18n: 'user.roles.ziro', value: 'ZIRO'},
    USER: {i18n: 'user.roles.user', value: 'USER'},
    MANAGER: {i18n: 'user.roles.manager', value: 'MANAGER'},
    ADMIN: {i18n: 'user.roles.admin', value: 'ADMIN'},
    EXECUTIVE: {i18n: 'user.roles.exec', value: 'EXECUTIVE'}
  };
}
