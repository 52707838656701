<div class="container">
  <h1 mat-dialog-title>{{ 'ziro.dashboard.generate.token.dialog.title' | translate}}</h1>
  <div class="dialog-content">
    <span>{{ 'ziro.dashboard.generate.token.dialog.content1' | translate}} </span>
    <br><br>
    <mat-form-field class="token-form-field" appearance="standard">
      <input matInput type="text" value="{{token}}" disabled>
      <mat-icon
        matSuffix
        *ngIf="!isCopied"
        class="content-copy-icon"
        (click)="copyToken()"
        aria-hidden="false"
        matTooltip="{{ 'ziro.dashboard.generate.token.dialog.copy.tooltip' | translate }}"
      >
        content_copy
      </mat-icon>
      <mat-icon
        matSuffix
        *ngIf="isCopied"
        aria-hidden="false"
        matTooltip="{{ 'ziro.dashboard.generate.token.dialog.copied.tooltip' | translate }}">
        done
      </mat-icon>
    </mat-form-field>
    <br><br>
    <span>{{ 'ziro.dashboard.generate.token.dialog.content2' | translate}}</span>
    <a target="_blank"
       href="https://stack8.atlassian.net/l/c/PPmrfP1V">{{ 'ziro.dashboard.generate.token.dialog.guide.link' | translate}}</a>
    <span>{{ 'ziro.dashboard.generate.token.dialog.content3' | translate}}</span>
  </div>
  <div mat-dialog-actions>
    <div style="margin-left: auto; padding-top: 28px; padding-bottom: 28px">
      <button color="primary" mat-raised-button (click)="onCloseClicked()">{{ 'general.close' | translate}}</button>
    </div>
  </div>
</div>
