import {Component, OnInit} from '@angular/core';
import {
  ZiroAgreement,
  ZiroConfiguration,
  ZiroDashboardApiService,
  ZiroTeamMember
} from '../api/ziro-dashboard-api.service';
import {forkJoin} from 'rxjs';
import {CurrentContactService} from '../current-contact.service';
import {UserRoles} from '../api/user-management-api.service';
import {MatDialog} from '@angular/material/dialog';
import {AddTokenDialogComponent} from './add-token-dialog/add-token-dialog.component';
import {DeleteTokenDialogComponent} from './delete-token-dialog/delete-token-dialog.component';
import {UpgradeZiroDialogComponent} from './upgrade-ziro-dialog/upgrade-ziro-dialog.component';
import {
  DownloadLinkNoAgreementDialogComponent
} from './download-link-no-agreement-dialog/download-link-no-agreement-dialog.component';

@Component({
  selector: 'app-ziro-dashboard',
  templateUrl: './ziro-dashboard.component.html',
  styleUrls: ['./ziro-dashboard.component.scss']
})
export class ZiroDashboardComponent implements OnInit {

  ziroAgreements: ZiroAgreement[];
  customerSuccessManager: ZiroTeamMember;
  accountManager: ZiroTeamMember;
  ziroConfigurations: ZiroConfiguration[];
  currentZiroVersion: string;
  areAllZiroInstancesUpgraded = true;
  isZiroPurchased = true;
  isLoading = true;
  isAdmin = false;

  constructor(
    private ziroDashboardApiService: ZiroDashboardApiService,
    private currentContactService: CurrentContactService,
    private dialog: MatDialog,
  ) {
  }

  ngOnInit() {
    const contact = this.currentContactService.getContact();
    if (contact.userType === UserRoles.roles.ADMIN.value) {
      this.isAdmin = true;
    }

    forkJoin(
      [
        this.ziroDashboardApiService.getZiroAgreements(),
        this.ziroDashboardApiService.getCurrentZiroVersion(),
        this.ziroDashboardApiService.getZiroTeamMembers(),
        this.ziroDashboardApiService.getZiroConfigurations()
      ]
    ).subscribe((
      [ziroAgreements,
        currentZiroVersion,
        teamsMembers,
        ziroConfigurations
      ]) => {
      this.ziroAgreements = ziroAgreements;
      this.currentZiroVersion = currentZiroVersion.ziroVersion;
      this.ziroConfigurations = ziroConfigurations;

      for (const member of teamsMembers) {
        if (member.role === 'Account Mgr') {
          this.accountManager = member;
        } else if (member.role === 'CSM') {
          this.customerSuccessManager = member;
        }
      }

      for (const config of ziroConfigurations) {
        if (config.version !== this.currentZiroVersion) {
          this.areAllZiroInstancesUpgraded = false;
        }
      }

      if (ziroAgreements.length === 0 && ziroConfigurations.length === 0) {
        this.isZiroPurchased = false;
      }

      this.isLoading = false;
    });
  }

  generateToken(id: number) {
    this.ziroDashboardApiService.postConfigurationApiToken(id)
      .subscribe(key =>
        this.dialog.open(AddTokenDialogComponent, {
          disableClose: true,
          panelClass: 'panel-one-third',
          width: '30%',
          data: {
            key: key
          }
        }).afterClosed().subscribe(() =>
          this.ziroDashboardApiService.getZiroConfigurations().subscribe(configs => {
              this.ziroConfigurations = configs;
            }
          )
        )
      );
  }

  deleteToken(id: number) {
    this.dialog.open(DeleteTokenDialogComponent, {
      disableClose: true,
      panelClass: 'panel-one-third',
      width: '30%',
      data: {
        configId: id
      }
    }).afterClosed().subscribe(() =>
      this.ziroDashboardApiService.getZiroConfigurations().subscribe(configs => {
          this.ziroConfigurations = configs;
        }
      )
    );
  }

  openZiroDownloadModal() {
    if (this.ziroAgreements.length === 0) {
      this.dialog.open(DownloadLinkNoAgreementDialogComponent, {
        disableClose: true,
        panelClass: 'panel-one-third',
        width: '50%',
      });
    } else {
      this.ziroDashboardApiService.getCurrentZiroVersionDownloadLinks().subscribe(
        value =>
          this.dialog.open(UpgradeZiroDialogComponent, {
            disableClose: true,
            panelClass: 'panel-one-third',
            width: '50%',
            data: {
              currentZiroVersionDownloadLinks: value
            }
          })
      );
    }
  }
}
