import {Component, OnInit} from '@angular/core';
import {ErrorSnackBarService} from './error-snack-bar.service';

@Component({
  selector: 'app-error-snack-bar',
  templateUrl: './error-snack-bar.component.html',
  styleUrls: ['./error-snack-bar.component.scss']
})
export class ErrorSnackBarComponent implements OnInit {

  constructor(
    private errorSnackBarService: ErrorSnackBarService,
  ) {
  }

  ngOnInit() {
  }

  onDismissClick() {
    if (this.errorSnackBarService.matSnackBarRef) {
      this.errorSnackBarService.matSnackBarRef.dismiss();
    }
  }

  onReloadClick() {
    window.location.reload();
    window.document.body.innerHTML = '';
  }
}
