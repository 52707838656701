import {ComponentCanDeactivate} from '../can-deactivate/component-can-deactivate';
import {FormGroup} from '@angular/forms';

export abstract class FormCanDeactivate extends ComponentCanDeactivate {

  abstract get forms(): FormGroup[];

  canDeactivate(): boolean {
    // canDeactivate if every form is not dirty
    return this.forms.every(
      function (formGroup: FormGroup) {
        return !formGroup.dirty;
      }
    );
  }
}
