import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-add-token-dialog',
  templateUrl: './add-token-dialog.component.html',
  styleUrls: ['./add-token-dialog.component.scss']
})
export class AddTokenDialogComponent implements OnInit {

  isCopied = false;
  token: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AddTokenDialogComponent>
  ) {
  }

  ngOnInit() {
    this.token = this.data.key['id'] + ':' + this.data.key['key'];
  }

  onCloseClicked(): void {
    this.dialogRef.close();
  }

  copyToken() {
    this.isCopied = true;
    navigator.clipboard.writeText(this.token).then(() =>
      setTimeout(() => {
        this.isCopied = false;
      }, 3000)
    );
  }
}
