<div class="header">
  <h1>{{ 'error.modal.something.went.wrong' | translate }}</h1>
  <button mat-icon-button (click)="onDismissClick()">
    <mat-icon>clear</mat-icon>
  </button>
</div>
<p>{{ 'error.modal.middle.text' | translate }}</p>
<button mat-icon-button (click)="onReloadClick()">
  <mat-icon>refresh</mat-icon>
  {{ 'error.modal.try.reloading' | translate }}
</button>
