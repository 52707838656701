import {Component, OnInit, ViewChild} from '@angular/core';
import {TicketApiService} from '../api/ticket-api.service';
import {FormControl} from '@angular/forms';
import {MatSort, MatSortable} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {Router} from '@angular/router';
import {RouteService} from '../route.service';

@Component({
  selector: 'app-recent-tickets',
  templateUrl: './recent-tickets.component.html',
  styleUrls: ['./recent-tickets.component.scss'],
})
export class RecentTicketsComponent implements OnInit {
  dataSource = new MatTableDataSource();
  displayedColumns = ['id', 'summary', 'status'];

  ticketIdFilter = new FormControl();
  summaryFilter = new FormControl();
  statusFilter = new FormControl();

  private filterValues = {ticketId: '', summary: '', status: ''};

  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(
    private ticketApiService: TicketApiService,
    private router: Router,
    private routeService: RouteService,
  ) {
  }

  ngOnInit() {
    this.ticketApiService.getTicketsList(
      1,
      10,
      this.sort.direction ? this.sort.active : null,
      this.sort.direction,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    ).subscribe(results => {
        this.dataSource.data = results.items;
      }
    );

    this.dataSource.sort = this.sort;
    this.sort.sort(<MatSortable>{
        id: 'ticketId',
        start: 'desc'
      }
    );

    this.ticketIdFilter.valueChanges
      .subscribe(value => {
        this.filterValues['ticketId'] = value.trim().toLowerCase();
        this.dataSource.filter = JSON.stringify(this.filterValues);
      });
    this.summaryFilter.valueChanges
      .subscribe(value => {
        this.filterValues['summary'] = value.trim().toLowerCase();
        this.dataSource.filter = JSON.stringify(this.filterValues);
      });
    this.statusFilter.valueChanges
      .subscribe(value => {
        this.filterValues['status'] = value.trim().toLowerCase();
        this.dataSource.filter = JSON.stringify(this.filterValues);
      });
    this.dataSource.filterPredicate = this.createFilter();
  }

  createFilter() {
    return function (data, filter): boolean {
      const searchTerms = JSON.parse(filter);

      if (searchTerms.ticketId) {
        const ticketIdStr = data.ticketId.toString();
        if (ticketIdStr.indexOf(searchTerms.ticketId) < 0) {
          return false;
        }
      }

      if (searchTerms.summary) {
        if (data.summary.toLowerCase().indexOf(searchTerms.summary.toLowerCase()) < 0) {
          return false;
        }
      }

      if (searchTerms.status) {
        if (data.status.toLowerCase().indexOf(searchTerms.status.toLowerCase()) < 0) {
          return false;
        }
      }
      return true;
    };
  }
}
