import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DashboardApiService {

  constructor(private http: HttpClient) {
  }

  getDashboardData(): Observable<DashboardData> {
    return this.http.get<DashboardData>('/api/dashboard');
  }
}

export class DashboardData {
  activeTicketCount: number;
  openedLast30Days: number;
  closedLast30Days: number;
  openedLast30DaysP1: number;
  openedLast30DaysP2: number;
  openedLast30DaysP3: number;
  openedLast30DaysP4: number;
  openedLast30DaysNoSla: number;
  openedLast30DaysNew: number;
  openedLast30DaysAssigned: number;
  openedLast30DaysInProgress: number;
  openedLast30DaysPending: number;
  openedLast30DaysResolved: number;
  ageLessThan6Days: number;
  ageBetween6And10Days: number;
  ageBetween11And20Days: number;
  ageBetween21And30Days: number;
  ageMoreThan30Days: number;
  historicalOpenedCounts: number[];
  historicalClosedCounts: number[];
}
