import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {EMPTY, Observable, throwError} from 'rxjs';
import {catchError, takeUntil} from 'rxjs/operators';
import {Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {AuthService} from './auth.service';
import {HttpCancelService} from './http-cancel.service';

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private authService: AuthService,
    private httpCancelService: HttpCancelService,
  ) {
  }

  /**
   * Do this here until https://github.com/angular/angular/issues/18155 (Allow passing misc data to http client options)
   * is closed.
   */
  private static isSignInRequest(req: HttpRequest<any>) {
    return /\/?api\/sign-in\/?.*/.test(req.url);
  }

  private static isChangePasswordInRequest(req: HttpRequest<any>) {
    return /\/?api\/contact\/change-password\/?.*/.test(req.url);
  }

  private static isApiUnauthorizedError(req: HttpRequest<any>, err) {
    return /\/?api\//.test(req.url) &&
      !AppHttpInterceptor.isSignInRequest(req) &&
      !AppHttpInterceptor.isChangePasswordInRequest(req) &&
      err instanceof HttpErrorResponse &&
      err.status === 401;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next
      .handle(req)
      .pipe(
        takeUntil(this.httpCancelService.onCancelPendingRequests()),
        catchError(err => {
          if (AppHttpInterceptor.isApiUnauthorizedError(req, err)) {
            this.authService.onApiUnauthorized();

            /*
             Returning empty here does not prevent the observable from completing, rather it will not emit a value.
             So be aware that obervables that were converted to promises (`observable.toPromise()`) will still get
             their 'success' callback invoked. To run a callback only when an http response is returned, use
             the 'next' callback: (ie `observable.subscribe(nextCallback)`),
             the pip pattern: `observable.pipe(map(() => ...)`,
             or if you insist on using a promise, make sure to test for whatever empty returns (falsy/null/undefined?).
              */
            return EMPTY;
          }
          return throwError(err);
        })
      );
  }

}
