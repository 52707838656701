<mat-sidenav-container class="sidenav-container">
  <mat-sidenav
      #drawer
    class="sidenav"
    [fixedInViewport]="true">
    <mat-toolbar color="accent" class="nav-toolbar">
      <div class="logo">
        <img src="../../assets/ziro-logo-small-white.png" [routerLink]="routeService.dashboard()">
      </div>
      <button mat-icon-button class="close" (click)="drawer.toggle()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar>
    <mat-card>
      <div [ngClass]="{'company-logo-placeholder': isZiroUser || isImpersonated}" id="companyLogo">
        <img class="company-logo-image" (click)="openCompanyImpersonationDialog()" mat-card-image src="{{companyLogo}}" *ngIf="hasLogo"
             alt="Company logo">
        <p (click)="openCompanyImpersonationDialog()" id="company-logo-text" *ngIf="!hasLogo" >
          {{companyName}}
        </p>
      </div>
      <mat-card-header>
        <div mat-card-avatar class="avatar">
          <img *ngIf="hasProfilePicture" mat-card-avatar src="{{profilePicture}}">
        </div>
        <mat-card-title id="fullname">
          {{fullName}}
        </mat-card-title>
        <mat-card-subtitle>{{getUserTypeI18n(getCurrentContact().userType) | translate}}</mat-card-subtitle>
      </mat-card-header>
    </mat-card>
    <mat-divider></mat-divider>
    <mat-nav-list (click)="onSideNavClicked()">
      <a mat-list-item [routerLink]="routeService.dashboard()" data-auto="side-nav-dashboard">
        <mat-icon matListIcon>dashboard</mat-icon>
        <span matLine>{{ 'layout.dashboard' | translate }}</span>
      </a>
      <!-- TICKETS -->
      <h1 mat-subheader>{{ 'general.tickets' | translate }}</h1>
      <a mat-list-item [routerLink]="routeService.createTicket()" data-auto="side-nav-create">
        <mat-icon matListIcon>playlist_add</mat-icon>
        <span matLine>{{ 'layout.ticket.create' | translate }}</span>
      </a>
      <a mat-list-item [routerLink]="routeService.ticketSearch()" data-auto="side-nav-search">
        <mat-icon matListIcon>search</mat-icon>
        <span matLine>{{ 'layout.ticket.search' | translate }}</span>
      </a>
      <a mat-list-item [routerLink]="routeService.ticketReport()" data-auto="side-nav-ticket-report">
        <mat-icon matListIcon>attach_file</mat-icon>
        <span matLine>{{ 'layout.ticket.report' | translate }}</span>
      </a>
      <!-- PROJECTS -->
      <h1 mat-subheader>{{ 'general.projects' | translate }}</h1>
      <a mat-list-item [routerLink]="routeService.projectList()" data-auto="side-nav-project-list">
        <mat-icon matListIcon>list</mat-icon>
        <span matLine>{{ 'layout.project.list' | translate }}</span>
      </a>
      <!-- USER MANAGEMENT -->
      <div *ngIf="canViewUsersSection">
        <h1 mat-subheader>{{ 'general.users' | translate }}</h1>
        <a mat-list-item [routerLink]="routeService.userManagement()" data-auto="side-nav-user-management">
          <mat-icon matListIcon>account_circle</mat-icon>
          <span matLine>{{ 'layout.user.management' | translate }}</span>
        </a>
      </div>
      <!-- PRODUCTS -->
      <h1 mat-subheader>{{ 'layout.products' | translate }}</h1>
      <a mat-list-item [routerLink]="routeService.ziroDashboard()" data-auto="side-nav-ziro-dashboard">
        <mat-icon matListIcon svgIcon="ziro-logo"></mat-icon>
        <span matLine>ZIRO Provisioning</span>
      </a>

    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="accent">
      <button
        *ngIf="!drawer.opened"
        type="button"
        mat-icon-button
        (click)="drawer.toggle()">
        <mat-icon>menu</mat-icon>
      </button>
      <a *ngIf="!drawer.opened" class="logo topnav-logo">
        <img src="../../assets/ziro-logo-small-white.png" [routerLink]="routeService.dashboard()">
      </a>
      <div class="toolbar-action-container">
        <app-contact-us></app-contact-us>
        &nbsp;
        <button mat-icon-button [matMenuTriggerFor]="menu" data-auto="top-nav-menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="onLanguageClicked()" data-auto="change-language-button">
            <mat-icon>language</mat-icon>
            <span>{{ 'layout.change.language.tooltip' | translate }}</span>
          </button>
          <button mat-menu-item [routerLink]="routeService.changePassword()" data-auto="change-password-button">
            <mat-icon matListIcon>lock_outline</mat-icon>
            <span matLine>{{ 'layout.change.password' | translate }}</span>
          </button>
          <button *ngIf="getCurrentContact().userType == 'ADMIN'" mat-menu-item [routerLink]="routeService.configurePortal()" data-auto="configure-portal-button">
            <mat-icon>build</mat-icon>
            <span>{{ 'layout.configure.portal' | translate }}</span>
          </button>
          <button mat-menu-item (click)="openCurrentContactEditDialog()" data-auto="edit-user-button">
            <mat-icon>settings</mat-icon>
            <span>{{ 'layout.user.edit.tooltip' | translate }}</span>
          </button>
          <button mat-menu-item (click)="onSignOutClicked()" data-auto="sign-out-button">
            <mat-icon>exit_to_app</mat-icon>
            <span>{{ 'layout.sign.out.tooltip' | translate }}</span>
          </button>
        </mat-menu>
      </div>
    </mat-toolbar>
    <div class="outlet-container">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
