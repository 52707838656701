import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {distinctUntilChanged, filter, map, share, switchMap, takeUntil, tap} from 'rxjs/operators';
import {combineLatest, Subject, zip} from 'rxjs';
import {ProjectApiService, ProjectAttachment, ProjectIssue, ProjectNote, ProjectSummary} from '../api/project-api.service';
import {RouterPathParams} from '../route/router-paths';
import {ActivatedRoute, Router} from '@angular/router';
import {SuffixTitleService} from '../suffix-title.service';
import {ProjectTranslationService} from '../project-translation.service';
import { MatTabGroup } from '@angular/material/tabs';
import {RouteService} from '../route.service';
import {TranslateService} from '@ngx-translate/core';
import {saveAs} from 'file-saver';
import {AnalyticsService, EventCategory} from '../analytics.service';

class NotePanel {
  isOpened: boolean;
  note: ProjectNote;
}

const TABS = ['description', 'notes', 'attachments', 'issues'];

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class ProjectComponent implements OnInit, OnDestroy {

  @ViewChild(MatTabGroup, {static: true}) private set tabGroup(tabGroup: MatTabGroup) {
    if (tabGroup) {
      this.tabGroup$.next(tabGroup);
    }
  }

  private tabGroup$: Subject<MatTabGroup> = new Subject<MatTabGroup>();
  private projectReloadTrigger$ = new Subject<string>();
  private ngUnsubscribe = new Subject<void>();

  public isLoading: boolean;
  public projectId: string;
  public project: ProjectSummary;
  public notePanels: NotePanel[];
  public notes: ProjectNote[];
  public attachments: ProjectAttachment[];
  public issues: ProjectIssue[];

  constructor(
    private projectApiService: ProjectApiService,
    private activatedRoute: ActivatedRoute,
    private titleService: SuffixTitleService,
    public projectTranslationService: ProjectTranslationService,
    private router: Router,
    private routeService: RouteService,
    private translateService: TranslateService,
    private analyticsService: AnalyticsService,
  ) {
  }

  ngOnInit() {
    this.projectSetup();
    this.tabSetup();
  }

  ngOnDestroy(): void {
    // this pattern is suggested by https://stackoverflow.com/a/41177163/501930
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private tabSetup() {
    const tabParam$ = this.activatedRoute.paramMap.pipe(
      map(paramMap => paramMap.get('tab')),
      filter(param => !!param)
    );

    combineLatest([tabParam$, this.tabGroup$])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(([tabParam, tabGroup]) => {
        function getTabIndex() {
          for (let i = 0; i < TABS.length; i++) {
            if (TABS[i] === tabParam) {
              return i;
            }
          }
          throw new Error('Unhandled: ' + tabParam);
        }
        tabGroup.selectedIndex = getTabIndex();
      });
  }

  private projectSetup() {
    let lastProjectId = null;

    const projectIdLoader$ = // this observable emits a projectId every time we need to load a project
      this.projectReloadTrigger$.pipe(
        map(() => lastProjectId),
        tap(() => this.isLoading = true),
        share(),
        takeUntil(this.ngUnsubscribe)
      );

    const project$ = projectIdLoader$.pipe(switchMap(projectId => this.projectApiService.getProject(<string>projectId)));
    const notes$ = projectIdLoader$.pipe(switchMap(projectId => this.projectApiService.getNotes(<string>projectId)));
    const attachments$ = projectIdLoader$.pipe(switchMap(projectId => this.projectApiService.getAttachments(<string>projectId)));
    const issues$ = projectIdLoader$.pipe(switchMap(projectId => this.projectApiService.getIssues(<string>projectId)));

    zip(projectIdLoader$, project$, notes$, attachments$, issues$)
      .subscribe(([projectId, project, notes, attachments, issues]) => {
        console.log('loaded project', projectId, project, notes, attachments, issues);
        this.onProjectLoaded(projectId, project, notes, attachments, issues);
        this.isLoading = false;
      });

    this.activatedRoute.paramMap.pipe(
      map(paramMap => paramMap.get(RouterPathParams.projectId)),
      filter(projectIdParam => !!projectIdParam),
      distinctUntilChanged(),
      takeUntil(this.ngUnsubscribe)
    ).subscribe(projectId => {
      lastProjectId = projectId;
      this.projectReloadTrigger$.next();
    });
  }

  private onProjectLoaded(
    projectId: string,
    project: ProjectSummary,
    notes: ProjectNote[],
    attachments: ProjectAttachment[],
    issues: ProjectIssue[],
  ) {
    this.projectId = projectId;
    this.project = project;
    this.notes = notes.sort((a, b) => b.lastUpdate.localeCompare(a.lastUpdate));
    this.titleService.setTitle(this.projectId + ' - ' + this.project.projectName);
    this.attachments = attachments.filter(attachment => attachment.publicFlag === 1);
    this.issues = issues.sort((a, b) => b.dateEntered.localeCompare(a.dateEntered));

    this.notePanels = [];
    notes.forEach(value => {
      const np = new NotePanel();
      np.isOpened = false;
      np.note = value;
      this.notePanels.push(np);
    });
  }

  onTabIndexChanged(tabIndex: number) {
    // noinspection JSIgnoredPromiseFromCall
    this.router.navigate(this.routeService.project(this.projectId, TABS[tabIndex]));
  }

  getNoteAuthor(projectNote: ProjectNote) {
    return projectNote.authorFirstName + ' ' + projectNote.authorLastName;
  }

  getDonutData(): number[] {
    return [this.project.completion, 100 - this.project.completion];
  }

  getDonutColor() {
    let healthColor;
    if (this.project.projectHealth === 'GREEN') {
      healthColor = '#008000';
    } else if (this.project.projectHealth === 'YELLOW') {
      healthColor = '#ff980f';
    } else if (this.project.projectHealth === 'RED') {
      healthColor = '#FF0000';
    }
    return [
      {
        backgroundColor: [healthColor, '#FFFFFF'],
        borderColor: [healthColor, healthColor],
        borderWidth: 1.2
      }
    ];
  }

  getDonutOptions() {
    return {
      cutoutPercentage: 60,
      animation: false,
      responsive: true,
      aspectRatio: 1
    };
  }

  getDonutToolTip() {
    return this.project.completion + '% ' + this.translateService.instant('project.donut.tooltip');
  }

  onAttachmentClick(attachment) {
    this.projectApiService.downloadAttachment(this.projectId, attachment.id)
      .subscribe(file => {
        saveAs(file, attachment.fileName);
        this.analyticsService.trackEvent('Download Project Attachment', EventCategory.Project, this.projectId);
      });
  }
}
