import {Component, OnInit} from '@angular/core';
import {AuthApiService} from '../../api/auth-api.service';
import {Router} from '@angular/router';
import {RouteService} from '../../route.service';

@Component({
  selector: 'app-confirm-reset-impersonation-dialog',
  templateUrl: './confirm-reset-impersonation-dialog.component.html',
  styleUrls: ['./confirm-reset-impersonation-dialog.component.scss']
})
export class ConfirmResetImpersonationDialogComponent implements OnInit {

  constructor(
    private authApiService: AuthApiService,
    private router: Router,
    private routeService: RouteService
  ) {
  }

  ngOnInit() {
  }

  resetImpersonation() {
    this.authApiService.resetImpersonation().subscribe(() =>
      this.router.navigate(this.routeService.dashboard()).then(() => window.location.reload()));
  }
}
