import {Component, OnInit, ViewChild} from '@angular/core';
import {ProjectApiService, ProjectSummary} from '../api/project-api.service';
import {MatDialog} from '@angular/material/dialog';
import {MatSort, MatSortable} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {FormControl} from '@angular/forms';
import {ProjectTranslationService} from '../project-translation.service';
import {TranslateService} from '@ngx-translate/core';
import {RouteService} from '../route.service';
import {ProjectListHealthDialogComponent} from './project-list-health-dialog/project-list-health-dialog.component';
import {timer} from 'rxjs';

@Component({
  selector: 'app-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.scss']
})
export class ProjectListComponent implements OnInit {

  @ViewChild(MatSort, {static: true}) sort: MatSort;

  dataSource = new MatTableDataSource();
  displayedColumns = ['projectHealth', 'projectName', 'projectManager', 'status', 'type', 'actualStartDate', 'actualEndDate'];

  filterValues = {
    projectName: '',
    projectManager: '',
    status: '',
    type: ''
  };

  nameFilter = new FormControl();
  managerFilter = new FormControl();
  statusFilter = new FormControl();
  typeFilter = new FormControl();

  constructor(
    private projectApiService: ProjectApiService,
    private projectTranslationService: ProjectTranslationService,
    public translate: TranslateService,
    public routeService: RouteService,
    public dialog: MatDialog,
    private translateService: TranslateService,
  ) {
  }

  ngOnInit() {
    this.projectApiService.listProjectSummaries()
      .subscribe(value => {
        this.dataSource.data = value;
      });

    this.dataSource.sort = this.sort;
    this.sort.sort(<MatSortable>{
        id: 'actualStartDate',
        start: 'desc'
      }
    );

    this.dataSource.sortingDataAccessor = (item: ProjectSummary, property) => {
      switch (property) {
        case 'projectHealth':
          return this.getHealthSort(item.projectHealth);
        default:
          return item[property];
      }
    };

    this.nameFilter.valueChanges.subscribe(value => this.applyFilter('projectName', value));
    this.managerFilter.valueChanges.subscribe(value => this.applyFilter('projectManager', value));
    this.statusFilter.valueChanges.subscribe(value => this.applyFilter('status', value));
    this.typeFilter.valueChanges.subscribe(value => this.applyFilter('type', value));
    this.dataSource.filterPredicate = this.createFilter(this);
  }

  private applyFilter(filter, value) {
    this.filterValues[filter] = value.trim().toLowerCase();
    this.dataSource.filter = JSON.stringify(this.filterValues);
  }

  createFilter(self: this) {
    return function (data, filter): boolean {
      const searchTerms = JSON.parse(filter);
      for (const property in searchTerms) {
        if (searchTerms.hasOwnProperty(property) && searchTerms[property].length > 0) {
          let dataValue;
          if (property === 'status') {
            dataValue = self.translate.instant(self.projectTranslationService.getStatusTranslation(data[property]));
          }
          if (property === 'projectManager') {
            dataValue = data['managerFirstName'].charAt(0) + '. ' + data['managerLastName'];
          } else {
            dataValue = data[property];
          }
          if (dataValue.toLowerCase().indexOf(searchTerms[property].toLowerCase()) < 0) {
            return false;
          }
        }
      }
      return true;
    };
  }

  getProjectManagerName(projectSummary: ProjectSummary) {
    return projectSummary.managerFirstName.charAt(0) + '. ' + projectSummary.managerLastName;
  }

  getProjectStatusI18n(key: string): string {
    return this.projectTranslationService.getStatusTranslation(key);
  }

  getProjectTypeI18n(key: string): string {
    return this.projectTranslationService.getTypeTranslation(key);
  }

  private getHealthSort(projectHealth: any) {
    if (projectHealth === 'GREEN') {
      return '1';
    } else if (projectHealth === 'YELLOW') {
      return '2';
    } else if (projectHealth === 'RED') {
      return '3';
    }
  }

  getDonutData(projectSummary: ProjectSummary): number[] {
    return [projectSummary.completion, 100 - projectSummary.completion];
  }

  getDonutColor(projectSummary: ProjectSummary) {
    let healthColor;
    if (projectSummary.projectHealth === 'GREEN') {
      healthColor = '#008000';
    } else if (projectSummary.projectHealth === 'YELLOW') {
      healthColor = '#ff980f';
    } else if (projectSummary.projectHealth === 'RED') {
      healthColor = '#FF0000';
    }
    return [
      {
        backgroundColor: [healthColor, '#FFFFFF'],
        borderColor: [healthColor, healthColor],
        borderWidth: 1.2
      }
    ];
  }

  getDonutOptions() {
    return {
      cutoutPercentage: 60,
      animation: false
    };
  }

  openHealthDialog() {
    timer(200).subscribe(() =>
      this.dialog.open(ProjectListHealthDialogComponent)
    );
  }

  getDonutToolTip(projectSummary: ProjectSummary) {
    return projectSummary.completion + '% ' + this.translateService.instant('project.donut.tooltip');
  }
}
