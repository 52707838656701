import {Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatSort, MatTableDataSource, Sort} from '@angular/material';
import {UserManagementApiService, UserRoles} from '../api/user-management-api.service';
import {CurrentContactService} from '../current-contact.service';
import {UserDialogComponent} from './user-dialog/user-dialog.component';
import {TranslateService} from '@ngx-translate/core';
import {FormControl, FormGroup} from '@angular/forms';
import {CompanyApiService} from '../api/company-api.service';
import {UserTypesDialogComponent} from './user-types-dialog/user-types-dialog.component';
import {ContactJson} from '../api/auth-api.service';
import {FormCanDeactivate} from '../form-can-deactivate/form-can-deactivate';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})

export class UserManagementComponent extends FormCanDeactivate implements OnInit {
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  isLoading = true;
  currentContactCompanyId: number;
  canCreateAdminUsers = true;
  maxNumberOfEnabledUsers: number;
  maxNumberOfAdmins: number;
  currentNumberOfActiveUsers: number;
  currentNumberOfAdmins: number;

  userFilter = new FormControl();
  emailFilter = new FormControl();
  roleFilter = new FormControl();
  statusFilter = new FormControl();
  phoneFilter = new FormControl();

  userDialogForm = new FormGroup({});

  private filterValues = {user: '', email: '', status: '', role: '', phone: ''};

  dataSource: MatTableDataSource<ContactJson> = new MatTableDataSource<ContactJson>();
  displayedColumns = ['user', 'email', 'phone', 'role', 'status'];

  constructor(
    private userManagementApiService: UserManagementApiService,
    private currentContactService: CurrentContactService,
    private translate: TranslateService,
    private dialog: MatDialog,
    private companyApiService: CompanyApiService
  ) {
    super();
    this.currentContactService.contact$.subscribe(value => {
      this.currentContactCompanyId = value.companyId;
    });
  }

  ngOnInit() {
    this.refreshList(true);
    this.setUpFilters();
  }

  get forms(): FormGroup[] {
    const groups: FormGroup[] = [];
    groups.push(this.userDialogForm);
    return groups;
  }

  refreshList(requireSort: boolean) {
    this.isLoading = true;
    this.companyApiService.getCompany(this.currentContactCompanyId).subscribe(company => {
      this.maxNumberOfEnabledUsers = company.maxEnabledUsers;
      this.maxNumberOfAdmins = company.maxAdmins;
      this.userManagementApiService
        .getUsersForCompany(this.currentContactCompanyId)
        .subscribe((value) => {
          this.dataSource.data = value;
          this.canCreateAdminUsers = this.getCurrentNumberOfAdmins() < this.maxNumberOfAdmins;
          if (requireSort) {
            this.sortData({active: 'status', direction: 'asc'});
          }
          this.currentNumberOfAdmins = this.getCurrentNumberOfAdmins();
          this.currentNumberOfActiveUsers = this.getCurrentNumberOfActiveUsers();

          this.isLoading = false;
        });
    });
  }

  getStatus(isPortalEnabled: boolean) {
    let key;
    if (isPortalEnabled) {
      key = 'user.management.status.active';
    } else {
      key = 'user.management.status.inactive';
    }
    return this.translate.instant(key);
  }

  getRoleI18n(userType: string) {
    return UserRoles.roles[userType].i18n;
  }

  onAddNewUserClicked() {
    this.openUserConfigDialog(null);
  }

  onEditUserClicked(user: ContactJson) {
    this.openUserConfigDialog(user);
  }

  private openUserTypesHelpDialog() {
    this.dialog.open(UserTypesDialogComponent, {
      panelClass: 'panel-two-thirds',
    });
  }

  private openUserConfigDialog(user: ContactJson) {
    const dialogRef = this.dialog.open(UserDialogComponent, {
      // don't allow escape or backdrop click to close the dialog - this is to avoid losing a half written note.
      disableClose: true,
      autoFocus: false,
      width: '400px',
      data: {
        user: user,
        allActiveEmails: this.dataSource.data.map(u => u.email.toLowerCase()),
        companyId: this.currentContactService.getContact().companyId,
        canCreateAdminUsers: this.canCreateAdminUsers,
        isExceedingMaxNumberOfActiveUsers: this.isExceedingMaxNumberOfActiveUsers()
      }
    });
    dialogRef.componentInstance.emitDirty.subscribe(() => {
      this.userDialogForm.markAsDirty();
    });
    dialogRef.afterClosed()
      .subscribe(refresh => {
          if (refresh) {
            this.refreshList(true);
          }
          this.userDialogForm.markAsPristine();
        }
      );
  }

  sortData(sort: Sort) {
    const data = this.dataSource.data;
    if (!sort.active || sort.direction === '') {
      this.dataSource.data = data;
      return;
    }

    this.dataSource.data = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'user':
          return this.compare(a.lastName, b.lastName, isAsc);
        case 'email':
          return this.compare(a.email, b.email, isAsc);
        case 'phone':
          return this.compare(a.phoneNumber, b.phoneNumber, isAsc);
        case 'role':
          return this.compare(a.userType, b.userType, isAsc);
        case 'status':
          return this.compare(this.getStatus(a.isPortalEnabled), this.getStatus(b.isPortalEnabled), isAsc);
        default:
          return 0;
      }
    });
  }

  getCurrentNumberOfActiveUsers(): number {
    return this.dataSource.data.filter(user => user.isPortalEnabled).length;
  }

  getCurrentNumberOfAdmins(): number {
    return this.dataSource.data.filter(user => user.userType === 'ADMIN').length;
  }

  isExceedingMaxNumberOfActiveUsers(): boolean {
    return this.getCurrentNumberOfActiveUsers() >= this.maxNumberOfEnabledUsers;
  }

  // noinspection JSMethodCanBeStatic
  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  private setUpFilters() {
    this.userFilter.valueChanges
      .subscribe(value => {
        this.filterValues['user'] = value.trim().toLowerCase();
        this.dataSource.filter = JSON.stringify(this.filterValues);
      });
    this.emailFilter.valueChanges
      .subscribe(value => {
        this.filterValues['email'] = value.trim().toLowerCase();
        this.dataSource.filter = JSON.stringify(this.filterValues);
      });
    this.roleFilter.valueChanges
      .subscribe(value => {
        this.filterValues['role'] = value.trim().toLowerCase();
        this.dataSource.filter = JSON.stringify(this.filterValues);
      });
    this.statusFilter.valueChanges
      .subscribe(value => {
        this.filterValues['status'] = value.trim().toLowerCase();
        this.dataSource.filter = JSON.stringify(this.filterValues);
      });
    this.phoneFilter.valueChanges
      .subscribe(value => {
        this.filterValues['phone'] = value.trim().toLowerCase();
        this.dataSource.filter = JSON.stringify(this.filterValues);
      });
    this.dataSource.filterPredicate = this.createFilter();
  }

  createFilter() {
    const self = this;
    return function (data, filter): boolean {
      const searchTerms = JSON.parse(filter);
      if (searchTerms.user) {
        if ((data.firstName + ' ' + data.lastName).toLowerCase().indexOf(searchTerms.user.toLowerCase()) < 0) {
          return false;
        }
      }
      if (searchTerms.email) {
        if (data.email.toLowerCase().indexOf(searchTerms.email.toLowerCase()) < 0) {
          return false;
        }
      }
      if (searchTerms.status) {
        if (!self.getStatus(data.isPortalEnabled).toLowerCase().startsWith(searchTerms.status.toLowerCase())) {
          return false;
        }
      }
      if (searchTerms.role) {
        if (self.translate.instant(self.getRoleI18n(data.userType)).toLowerCase().indexOf(searchTerms.role.toLowerCase()) < 0) {
          return false;
        }
      }
      if (searchTerms.phone) {
        if (!data.phoneNumber) {
          return false;
        } else if (data.phoneNumber.indexOf(searchTerms.phone) < 0) {
          return false;
        }
      }
      return true;
    };
  }
}
