<div>
  <h3>{{'dialog-companies.selection.prompt' | translate}}: {{selectedCompany.name}}</h3>
  <h4>{{'dialog-companies.role.prompt' | translate}}</h4>
  <mat-form-field id="user-types">
    <mat-select
      [(ngModel)]="selectedUserType"
      placeholder="{{ 'user.management.table.header.role' | translate}}">
      <mat-option *ngFor="let role of userRoles"
                  (onSelectionChange)="changeUserTypeSelection($event, role.value)"
                  [value]="role.value"
                  id="{{role.value}}">
        {{ role.i18n | translate}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
