<div class="container">
  <h1 mat-dialog-title>{{ 'ziro.dashboard.upgrade.ziro.dialog.title' | translate}}</h1>
  <div class="dialog-content">
    <span>{{ 'ziro.dashboard.upgrade.ziro.dialog.content1' | translate}}</span><br><br>
    <span>{{ 'ziro.dashboard.upgrade.ziro.dialog.content2' | translate}}</span>
    <a target="_blank"
       href="https://stack8.atlassian.net/l/c/NPLT5wRv">{{'ziro.dashboard.upgrade.ziro.dialog.content3' | translate}}</a>
    <span>{{ 'ziro.dashboard.upgrade.ziro.dialog.content4' | translate}}</span>
  </div>
  <div class="download-buttons">
    <a href="{{currentZiroVersionDownloadLinks.ziroDebLink}}">
      <button mat-button>
        <mat-icon aria-hidden="false">file_download</mat-icon>
        {{'ziro.dashboard.upgrade.ziro.dialog.download.ziro.button' | translate}}</button>
    </a>
    <a href="{{currentZiroVersionDownloadLinks.ziroConsoleDebLink}}">
      <button style="margin-left:20px"
              mat-button>
        <mat-icon aria-hidden="false">file_download</mat-icon>
        {{'ziro.dashboard.upgrade.ziro.dialog.download.console.button' | translate}}</button>
    </a>
  </div>
  <div mat-dialog-actions>
    <div style="margin-left: auto; padding-top: 28px; padding-bottom: 28px">
      <button color="primary" mat-raised-button (click)="onCloseClicked()">{{ 'general.close' | translate}}</button>
    </div>
  </div>
</div>
