import {Component, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material';

@Component({
  selector: 'app-user-types-dialog',
  templateUrl: './priorities-dialog.component.html',
  styleUrls: ['./priorities-dialog.component.scss']
})
export class PrioritiesDialogComponent implements OnInit {

  dataSource: MatTableDataSource<PriorityDetails> = new MatTableDataSource<PriorityDetails>();
  displayedColumns = ['priority', 'hours', 'description'];

  constructor() {
  }

  ngOnInit() {
    this.dataSource.data = [
      new PriorityDetails('ticket.priority.Priority 1 - Critical', 0.5, 'priority.description.p1'),
      new PriorityDetails('ticket.priority.Priority 2 - High', 1, 'priority.description.p2'),
      new PriorityDetails('ticket.priority.Priority 3 - Medium', 8, 'priority.description.p3'),
      new PriorityDetails('ticket.priority.Priority 4 - Low', 8, 'priority.description.p4'),
    ];
  }

}

export class PriorityDetails {
  priority: string;
  hours: number;
  description: string;

  constructor(priority, hours, description) {
    this.priority = priority;
    this.hours = hours;
    this.description = description;
  }
}
