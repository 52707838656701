<div class="container">
  <h1 mat-dialog-title>{{ 'project.health.dialog.title' | translate }}</h1>
  <div class="project-donut">
    <canvas baseChart
            [data]="getDonutData()"
            chartType="doughnut"
            [colors]="getDonutColor()"
            [options]="getDonutOptions()">
    </canvas>
  </div>

  <p>{{'project.health.dialog.text' | translate}}</p>

  <div mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="onCancelClicked()">{{ 'general.close' | translate}}</button>
  </div>
</div>
