<div class="upload-drop-container"
     ngFileDrop [options]="options"
     (uploadOutput)="onUploadOutput($event)"
     [uploadInput]="uploadInput">
  <p>{{ 'file.upload.drag' | translate}}
    <label class="upload-button">
      <input type="file"
             ngFileSelect
             onclick="this.value = null"
             [options]="options"
             (uploadOutput)="onUploadOutput($event)"
             [uploadInput]="uploadInput"
             data-auto="ticket-attachment-upload"
             multiple>
      {{ 'file.upload.choose' | translate}}
    </label>
    {{ 'file.upload.upload' | translate}}
  </p>
</div>
<br>
<div class="files-to-upload">
  <div *ngFor="let fileInfo of fileInfos">
    <app-file-info #fileInfoComp [fileInfo]="fileInfo" (fileRemovedEvent)="childRemovedFile($event)"></app-file-info>
  </div>
</div>
