<div>
  <h1>{{ 'project.list.title' | translate }}</h1>

  <div class="container">
    <mat-card>

      <table #table
             mat-table
             [dataSource]="dataSource"
             class="project-container"
             matSort
             matSortDisableClear>

        <ng-container matColumnDef="projectHealth">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <span>{{ 'project.list.health.header' | translate }}</span>
          </th>
          <td mat-cell *matCellDef="let row">
            <div class="project-donut">
              <canvas baseChart
                      [data]="getDonutData(row)"
                      chartType="doughnut"
                      [colors]="getDonutColor(row)"
                      [options]="getDonutOptions()"
                      [matTooltip]="getDonutToolTip(row)"
                      matTooltipHideDelay=50>
              </canvas>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="projectName">
          <th mat-header-cell *matHeaderCellDef>
            <span>{{ 'project.list.name.header' | translate }}</span>
            <mat-form-field>
              <input matInput name="projectName" data-auto="project-name-filter" [formControl]="nameFilter">
            </mat-form-field>
          </th><td mat-cell *matCellDef="let row">

            <a [routerLink]="routeService.project(row.id)" data-auto="project-name-link">{{row.projectName}}</a>
          </td>
        </ng-container>

        <ng-container matColumnDef="projectManager">
          <th mat-header-cell *matHeaderCellDef>
            <span>{{ 'project.list.manager.header' | translate }}</span>
            <mat-form-field>
              <input matInput name="projectManager" [formControl]="managerFilter">
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let row">
            {{getProjectManagerName(row)}}
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>
            <span>{{ 'project.list.status.header' | translate }}</span>
            <mat-form-field>
              <input matInput name="status" [formControl]="statusFilter">
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let row">
            {{getProjectStatusI18n(row.status) | translate}}
          </td>
        </ng-container>

        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef>
            <span>{{ 'project.list.type.header' | translate }}</span>
            <mat-form-field>
              <input matInput name="type" [formControl]="typeFilter">
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let row">
            {{getProjectTypeI18n(row.type) | translate}}
          </td>
        </ng-container>

        <ng-container matColumnDef="actualStartDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <span>{{ 'project.list.start.date.header' | translate }}</span>
          </th>
          <td mat-cell *matCellDef="let row">
            {{row.actualStartDate | localizedDateShort}}
          </td>
        </ng-container>

        <ng-container matColumnDef="actualEndDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <span>{{ 'project.list.end.date.header' | translate }}</span>
          </th>
          <td mat-cell *matCellDef="let row">
            {{row.actualEndDate | localizedDateShort}}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" data-auto="project-list-row"></tr>
      </table>
    </mat-card>
  </div>
</div>
