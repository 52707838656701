import {Injectable} from '@angular/core';
import {catchError, map} from 'rxjs/operators';
import {HttpClient, HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthApiService {

  constructor(
    private http: HttpClient
  ) {
  }

  private static handleError(err) {
    if (err instanceof HttpErrorResponse) {
      if (err.status === 401) { // bad credentials, this is expected
        return of(err);
      }
    }
    return throwError(err);
  }

  signOut(): Observable<any> {
    return this.http.post(
      'api/sign-out',
      null,
      {observe: 'response'}
    );
  }

  signIn(username: string, password: string, rememberMe: boolean): Observable<number> {
    return this.http.post(
      'api/sign-in',
      {username: username, password: password, rememberMe: rememberMe},
      {observe: 'response'}
    )
      .pipe(
        catchError(err => {
          return AuthApiService.handleError(err);
        }),
        map((response: HttpResponse<any>) => {
          return response.status;
        })
      );
  }

  sendResetPassword(email: string, language: string): Observable<any> {
    return this.http.post(
      '/api/contact/reset-password',
      {email: email, language: language},
      {observe: 'response'}
    );
  }

  changePassword(newPassword: string, token?: string, oldPassword?: string): Observable<any> {
    const body = {newPassword: newPassword};

    if (token) {
      body['token'] = token;
    }
    if (oldPassword) {
      body['oldPassword'] = oldPassword;
    }

    return this.http.post(
      '/api/contact/change-password',
      body,
      {observe: 'response'}
    ).pipe(
      catchError(err => {
        return AuthApiService.handleError(err);
      }),
      map((response: HttpResponse<any>) => {
        return response.status;
      })
    );
  }

  testToken(token: string): Observable<boolean> {
    return this.http.post(
      '/api/contact/test-token',
      {token: token},
      {observe: 'response'}
    ).pipe(
      map(response => {
        return (response.body as TokenTestResponse).exists;
      })
    );
  }

  impersonate(companyId: number, userType: string): Observable<any> {
    const data = {companyId: companyId, userType: userType};
    return this.http.post('/api/impersonate', data);
  }

  resetImpersonation(): Observable<any> {
    return this.http.get('/api/reset-impersonation');
  }
}

export class TokenTestResponse {
  exists: boolean;
}

export class ContactJson {
  contactId: number;
  companyId: number;
  realCompanyId: number;
  companyIdentifier: string;
  companyName: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  userType: string;
  roles: string[];
  isPortalEnabled: boolean;
  language: string;
}
