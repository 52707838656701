<div class="container">
  <h1>
    {{'user.management.permissions.title' | translate}}
  </h1>
  <mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="permission">
      <mat-header-cell *matHeaderCellDef>
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        {{ row.permissionI18n | translate }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="user">
      <mat-header-cell *matHeaderCellDef>
        {{'user.roles.user' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div *ngIf="row.user">
          <mat-icon class="green-icon">done</mat-icon>
        </div>
        <div *ngIf="!row.user">
          <mat-icon class="red-icon">clear</mat-icon>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="manager">
      <mat-header-cell *matHeaderCellDef>
        {{'user.roles.manager' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div *ngIf="row.manager">
          <mat-icon class="green-icon">done</mat-icon>
        </div>
        <div *ngIf="!row.manager">
          <mat-icon class="red-icon">clear</mat-icon>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="executive">
      <mat-header-cell *matHeaderCellDef>
        {{'user.roles.exec' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div *ngIf="row.executive">
          <mat-icon class="green-icon">done</mat-icon>
        </div>
        <div *ngIf="!row.executive">
          <mat-icon class="red-icon">clear</mat-icon>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="admin">
      <mat-header-cell *matHeaderCellDef>
        {{'user.roles.admin' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div *ngIf="row.admin">
          <mat-icon class="green-icon">done</mat-icon>
        </div>
        <div *ngIf="!row.admin">
          <mat-icon class="red-icon">clear</mat-icon>
        </div>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row class="row-cursor" *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>

  <div mat-dialog-actions  style="margin-top: 12px;">
    <div style="margin-left: auto;">
      <button color="primary" mat-raised-button mat-dialog-close>{{ 'general.close' | translate}}</button>
    </div>
  </div>
</div>
