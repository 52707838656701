<div class="header-row">
  <h1>{{ 'ticket.report.title' | translate }}</h1>
</div>

<table mat-table [dataSource]="dataSource"
       matSort
       matSortActive="description"
       matSortDirection="desc"
       matSortDisableClear
       class="mat-elevation-z8 files-table"
       data-auto="ticket-report-table">

  <ng-container matColumnDef="description">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ 'ticket.report.description' | translate }}
    </th>
    <td mat-cell *matCellDef="let file">
      <span data-auto="description">{{file.description}}</span>
    </td>
  </ng-container>

  <ng-container matColumnDef="filename">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ 'ticket.report.filename' | translate }}
    </th>
    <td mat-cell *matCellDef="let file">
      <button mat-stroked-button
              (click)="download(file)">
        <mat-icon>file_download</mat-icon>
        <span data-auto="filename">{{file.filename}}</span>
      </button>
    </td>
  </ng-container>

  <ng-container matColumnDef="size">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ 'ticket.report.size' | translate }}
    </th>
    <td mat-cell *matCellDef="let file"> {{toFileSize(file.size)}}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="['description', 'filename', 'size']"></tr>
  <tr mat-row *matRowDef="let row; columns: ['description', 'filename', 'size'];"></tr>

</table>
