<div class="container">
  <div *ngIf="isAddNewUser">
    <h1 data-auto="add-new-user-title">{{ 'user.management.button.add.user' | translate}}</h1>
  </div>
  <div *ngIf="!isAddNewUser">
    <h1 data-auto="edit-user-title">{{ 'user.management.dialog.header.edit.user' | translate}}</h1>
  </div>

  <mat-progress-bar mode="indeterminate" *ngIf="isSaving"></mat-progress-bar>
  <form [formGroup]="userForm" (keypress)="onKeyPress()">

    <div class="user-form">
      <mat-form-field>
        <label>
          <input type="text"
                 matInput
                 placeholder="{{ 'add.user.dialog.first.name' | translate}}"
                 formControlName="firstName"
                 data-auto="user-first-name">
        </label>
        <mat-error *ngIf="firstName.errors && firstName.errors['required']">
          {{ 'general.required' | translate}}
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <label>
          <input type="text"
                 matInput
                 placeholder="{{ 'add.user.dialog.last.name' | translate}}"
                 formControlName="lastName"
                 data-auto="user-last-name">
        </label>
        <mat-error *ngIf="lastName.errors && lastName.errors['required']">
          {{ 'general.required' | translate}}
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <label>
          <input type="text"
                 matInput
                 placeholder="{{ 'user.management.table.header.email' | translate}}"
                 formControlName="email"
                 data-auto="user-email"
                 (ngModelChange)="emailAlreadyExists($event)">
        </label>
        <mat-error *ngIf="email.errors && email.errors['required']">
          {{ 'general.required' | translate}}
        </mat-error>
        <mat-error *ngIf="email.errors && email.errors['notUnique']">
          {{ 'error.email.already.exists' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <label>
          <input type="text"
                 matInput
                 placeholder="{{ 'user.management.table.header.phone' | translate}}"
                 formControlName="phoneNumber"
                 data-auto="user-phone">
        </label>
        <mat-error *ngIf="phoneNumber.errors && phoneNumber.errors['required']">
          {{ 'general.required' | translate}}
        </mat-error>
      </mat-form-field>
      <mat-form-field *ngIf="!isCurrentContact()">
        <mat-select
          placeholder="{{ 'user.management.table.header.role' | translate}}"
          formControlName="role">
          <mat-option *ngFor="let role of userRoles"
                      [value]="role.value"
                      matTooltip="{{'error.too.many.admins' | translate}}"
                      [matTooltipDisabled]="role.value != 'ADMIN' || canCreateAdminUsers"
                      matTooltipPosition="above"
                      [disabled]="!canCreateAdminUsers && role.value == 'ADMIN'">
            {{ role.i18n | translate}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="role.errors && role.errors['required']">
          {{ 'general.required' | translate}}
        </mat-error>
      </mat-form-field>

      <div class="user-active-checkbox"
           matTooltip="{{'user.management.tooltip.too.many.active.users' | translate}}"
           [matTooltipDisabled]=allowSettingActivationStatus()
           matTooltipPosition="after">
        <mat-checkbox *ngIf="!isCurrentContact()" [formControl]="status" id="user-status">
          {{ 'user.management.dialog.active.status' | translate}}
        </mat-checkbox>
      </div>

    </div>

    <mat-error *ngIf="userForm.errors && email.errors['tooManyAdmins']">
      {{ 'error.email.already.exists' | translate }}
    </mat-error>

  </form>

  <div mat-dialog-actions style="margin-top: 12px;">
    <div style="margin-left: auto;">
      <button mat-raised-button
              color="primary"
              [disabled]="userForm.invalid"
              type="submit"
              (click)="onSaveClicked()"
              data-auto="save-user-button">
        <span>{{ 'general.submit' | translate}}</span>
      </button>
      <button mat-raised-button
              (click)="onDialogClose(false)">{{ 'general.cancel' | translate}}</button>
    </div>
  </div>
</div>

