import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CurrentZiroVersionDownloadLinks} from '../../api/ziro-dashboard-api.service';

@Component({
  selector: 'app-upgrade-ziro-dialog',
  templateUrl: './upgrade-ziro-dialog.component.html',
  styleUrls: ['./upgrade-ziro-dialog.component.scss']
})
export class UpgradeZiroDialogComponent implements OnInit {

  currentZiroVersionDownloadLinks: CurrentZiroVersionDownloadLinks;

  constructor(
    private dialogRef: MatDialogRef<UpgradeZiroDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.currentZiroVersionDownloadLinks = data['currentZiroVersionDownloadLinks'];
  }

  ngOnInit() {
  }

  onCloseClicked(): void {
    this.dialogRef.close();
  }
}
