import { Pipe, PipeTransform } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
  name: 'ticketStatus'
})
export class TicketStatusPipe implements PipeTransform {

  constructor(
    private translate: TranslateService,
  ) {
  }

  transform(value: any, args?: any): any {
    return this.translate.instant('ticket.status.' + value);
  }

}
