import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatDialogRef, MatSort, MatSortable, MatTableDataSource} from '@angular/material';
import {FormControl} from '@angular/forms';
import {DialogCompaniesComponent} from './dialog-companies.component';
import {Company} from '../../api/company-api.service';

@Component({
  selector: 'app-companies-list',
  templateUrl: './companies-list.component.html',
  styleUrls: ['./companies-list.component.scss']
})
export class CompaniesListComponent implements OnInit {

  displayedColumns = ['name', 'companyIdentifier'];
  name = new FormControl();
  companyIdentifier = new FormControl();

  @Input() dataSource: MatTableDataSource<any>;
  @Input() selectedCompany: Company;
  @Output() readonly selectedCompanyChange = new EventEmitter();

  @ViewChild(MatSort, {static: true}) sort: MatSort;

  private filterValues = {name: '', companyIdentifier: ''};

  constructor(private dialogRef: MatDialogRef<DialogCompaniesComponent>) {
  }

  ngOnInit() {
    this.dialogRef.updateSize('40%', '90%');

    this.dataSource.sort = this.sort;
    this.sort.sort(<MatSortable>{
        id: 'name'
      }
    );

    this.name.valueChanges
      .subscribe(value => {
        this.filterValues['name'] = value.trim().toLowerCase();
        this.dataSource.filter = JSON.stringify(this.filterValues);
      });

    this.companyIdentifier.valueChanges
      .subscribe(value => {
        this.filterValues['companyIdentifier'] = value.trim().toLowerCase();
        this.dataSource.filter = JSON.stringify(this.filterValues);
      });
    this.dataSource.filterPredicate = this.createFilter();
  }

  createFilter() {
    return function (data, filter): boolean {
      const searchTerms = JSON.parse(filter);

      if (searchTerms.name) {
        if (data.name.toLowerCase().indexOf(searchTerms.name.toLowerCase()) < 0) {
          return false;
        }
      }
      if (searchTerms.companyIdentifier) {
        if (data.identifier.toLowerCase().indexOf(searchTerms.companyIdentifier.toLowerCase()) < 0) {
          return false;
        }
      }
      return true;
    };
  }

  onClickCompanyChanger(company: Company) {
    this.selectedCompany = company;
    this.selectedCompanyChange.emit(this.selectedCompany);
  }
}
