import {AfterViewInit, Component, ElementRef, Injectable, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {TicketApiService, TicketListResponse, TicketSummary} from '../api/ticket-api.service';
import {BehaviorSubject, merge, Observable, of} from 'rxjs';
import {map, startWith, switchMap} from 'rxjs/operators';
import * as moment from 'moment';
import {FlatTreeControl} from '@angular/cdk/tree';
import {SelectionModel} from '@angular/cdk/collections';
import {RouteService} from '../route.service';
import {TranslateService} from '@ngx-translate/core';
import {PrioritiesDialogComponent} from './priorities-dialog/priorities-dialog.component';
import {CurrentContactService} from '../current-contact.service';
import {CookieService} from 'ngx-cookie-service';

export class Node {
  children: Node[];
  name: string;
  translate: string;

  constructor(name: string, translate: string) {
    this.name = name;
    this.translate = translate;
  }
}

export class FlatNode {
  name: string;
  translate: string;
  level: number;
  expandable: boolean;
}

@Injectable()
export class PriorityChecklistDatabase {
  priorityDataChange = new BehaviorSubject<Node[]>([]);

  static buildTree(): Node[] {
    const list: Node[] = [];

    const priorities = new Node('Priority', 'ticket.search.priority');

    priorities.children = [];
    priorities.children.push(new Node('Priority 1 - Critical', 'ticket.priority.Priority 1 - Critical'));
    priorities.children.push(new Node('Priority 2 - High', 'ticket.priority.Priority 2 - High'));
    priorities.children.push(new Node('Priority 3 - Medium', 'ticket.priority.Priority 3 - Medium'));
    priorities.children.push(new Node('Priority 4 - Low', 'ticket.priority.Priority 4 - Low'));
    priorities.children.push(new Node('Ignore - No SLA', 'ticket.priority.Ignore - No SLA'));

    list.push(priorities);

    return list;
  }

  get data(): Node[] {
    return this.priorityDataChange.value;
  }

  constructor() {
    this.initialize();
  }

  initialize() {
    const data = PriorityChecklistDatabase.buildTree();
    this.priorityDataChange.next(data);
  }
}

@Injectable()
export class StatusChecklistDatabase {
  statusDataChange = new BehaviorSubject<Node[]>([]);

  static buildTree(): Node[] {
    const list: Node[] = [];

    const opened = new Node('OPENED', 'ticket.status.OPENED');

    opened.children = [];
    opened.children.push(new Node('NEW', 'ticket.status.NEW'));
    opened.children.push(new Node('ASSIGNED', 'ticket.status.ASSIGNED'));
    opened.children.push(new Node('IN_PROGRESS', 'ticket.status.IN_PROGRESS'));
    opened.children.push(new Node('PENDING', 'ticket.status.PENDING'));

    const closed = new Node('CLOSED', 'ticket.status.CLOSED');
    closed.children = [];
    closed.children.push(new Node('RESOLVED', 'ticket.status.RESOLVED'));

    list.push(opened);
    list.push(closed);

    return list;
  }

  get data(): Node[] {
    return this.statusDataChange.value;
  }

  constructor() {
    this.initialize();
  }

  initialize() {
    const data = StatusChecklistDatabase.buildTree();
    this.statusDataChange.next(data);
  }
}

@Component({
  selector: 'app-ticket-search',
  templateUrl: './ticket-search.component.html',
  styleUrls: ['./ticket-search.component.scss'],
  providers: [StatusChecklistDatabase, PriorityChecklistDatabase]
})
export class TicketSearchComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild('contactInput', {static: false}) contactInput: ElementRef;
  @ViewChild('createdDateRangeInput', {static: false}) createdDateRangeInput: ElementRef;
  @ViewChild('updatedDateRangeInput', {static: false}) updatedDateRangeInput: ElementRef;

  showFilters = false;
  ticketId: string;
  summary: string;
  priority: string;
  ticketOwner: string;
  assignedTo: string;
  createdDateRange: any;
  updatedDateRange: any;
  currentContact;

  /** Map from a flat node to its nested representation */
  flatNodeMap = new Map<FlatNode, Node>();
  /** Map from a nested node to its flat representation */
  nestedNodeMap = new Map<Node, FlatNode>();
  statusTreeControl: FlatTreeControl<FlatNode>;
  statusTreeFlattener: MatTreeFlattener<Node, FlatNode>;
  priorityTreeControl: FlatTreeControl<FlatNode>;
  priorityTreeFlattener: MatTreeFlattener<Node, FlatNode>;
  statusDataSource: MatTreeFlatDataSource<Node, FlatNode>;
  priorityDataSource: MatTreeFlatDataSource<Node, FlatNode>;
  /** the selected items */
  statusChecklistSelection = new SelectionModel<FlatNode>(true); // multiple selection true
  priorityChecklistSelection = new SelectionModel<FlatNode>(true); // multiple selection true

  isLoadingResults = true;
  resultsLength = 0;
  data: TicketSummary[];
  displayedColumns = ['id', 'summary', 'status', 'priority', 'ticketOwner', 'assignedTo', 'dateEntered', 'lastUpdated'];
  ranges: any = {};

  constructor(
    private ticketApiService: TicketApiService,
    public dialog: MatDialog,
    private statusDatabase: StatusChecklistDatabase,
    private priorityDatabase: PriorityChecklistDatabase,
    public routeService: RouteService,
    private translateService: TranslateService,
    private currentContactService: CurrentContactService,
    private cookieService: CookieService,
  ) {
    this.statusTreeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren);
    this.statusTreeControl = new FlatTreeControl<FlatNode>(this.getLevel, this.isExpandable);
    this.priorityTreeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren);
    this.priorityTreeControl = new FlatTreeControl<FlatNode>(this.getLevel, this.isExpandable);
    this.statusDataSource = new MatTreeFlatDataSource(this.statusTreeControl, this.statusTreeFlattener);
    this.priorityDataSource = new MatTreeFlatDataSource(this.priorityTreeControl, this.priorityTreeFlattener);

    statusDatabase.statusDataChange.subscribe(data => {
      this.statusDataSource.data = data;
    });

    priorityDatabase.priorityDataChange.subscribe(data => {
      this.priorityDataSource.data = data;
    });

    this.generateDatePickerRanges();
  }

  getLevel = (node: FlatNode) => node.level;
  isExpandable = (node: FlatNode) => node.expandable;
  getChildren = (node: Node): Observable<Node[]> => of(node.children);
  hasChild = (_: number, _nodeData: FlatNode) => _nodeData.expandable;

  /** Nested node -> Flat Node */
  transformer = (node: Node, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.name === node.name && existingNode.translate === node.translate
      ? existingNode
      : new FlatNode();
    flatNode.name = node.name;
    flatNode.translate = node.translate;
    flatNode.level = level;
    flatNode.expandable = !!node.children;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  }

  priorityDescendantsAllSelected(node: FlatNode): boolean {
    const descendants = this.priorityTreeControl.getDescendants(node);
    return descendants.every(child => this.priorityChecklistSelection.isSelected(child));
  }

  priorityDescendantsPartiallySelected(node: FlatNode): boolean {
    const descendants = this.priorityTreeControl.getDescendants(node);
    const result = descendants.some(child => this.priorityChecklistSelection.isSelected(child));
    return result && !this.priorityDescendantsAllSelected(node);
  }

  /** select/deselect descendants */
  prioritySelectionToggle(node: FlatNode): void {
    this.priorityChecklistSelection.toggle(node);
    const descendants = this.priorityTreeControl.getDescendants(node);
    this.priorityChecklistSelection.isSelected(node)
      ? this.priorityChecklistSelection.select(...descendants)
      : this.priorityChecklistSelection.deselect(...descendants);
  }

  statusDescendantsAllSelected(node: FlatNode): boolean {
    const descendants = this.statusTreeControl.getDescendants(node);
    return descendants.every(child => this.statusChecklistSelection.isSelected(child));
  }

  statusDescendantsPartiallySelected(node: FlatNode): boolean {
    const descendants = this.statusTreeControl.getDescendants(node);
    const result = descendants.some(child => this.statusChecklistSelection.isSelected(child));
    return result && !this.statusDescendantsAllSelected(node);
  }

  /** select/deselect descendants */
  statusSelectionToggle(node: FlatNode): void {
    this.statusChecklistSelection.toggle(node);
    const descendants = this.statusTreeControl.getDescendants(node);
    this.statusChecklistSelection.isSelected(node)
      ? this.statusChecklistSelection.select(...descendants)
      : this.statusChecklistSelection.deselect(...descendants);
  }

  ngOnInit() {
    this.currentContact = this.currentContactService.getContact();
  }

  ngAfterViewInit(): void {
    this.getCookies();
    this.getSearchResults();
  }

  private getSearchResults() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(
      this.paginator.page,
      this.sort.sortChange
    )
      .pipe(
        startWith([]),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.ticketApiService.getTicketsList(
            this.paginator.pageIndex + 1,
            this.paginator.pageSize ? this.paginator.pageSize : 10,
            this.sort.direction ? this.sort.active : null,
            this.sort.direction,
            this.createdDateRange ? this.createdDateRange['startDate'] : null,
            this.createdDateRange ? this.createdDateRange['endDate'] : null,
            this.updatedDateRange ? this.updatedDateRange['startDate'] : null,
            this.updatedDateRange ? this.updatedDateRange['endDate'] : null,
            this.ticketId,
            this.summary,
            this.ticketOwner,
            this.assignedTo,
            this.getStatusesString(),
            this.getPrioritiesString()
          );
        }),
        map((ticketListResponse: TicketListResponse) => {
          this.isLoadingResults = false;
          this.resultsLength = ticketListResponse.total;
          return ticketListResponse.items;
        })
      )
      .subscribe(data => this.data = data);
  }

  getStatusesString(): string {
    if (this.statusChecklistSelection.selected) {
      return this.statusChecklistSelection.selected
        .map(status => status.name)
        .filter(name => name !== 'CLOSED' && name !== 'OPENED')
        .join();
    }
  }

  getPrioritiesString(): string {
    if (this.priorityChecklistSelection.selected) {
      return this.priorityChecklistSelection.selected
        .map(priority => priority.name)
        .join();
    }
  }

  onClear() {
    for (const node of this.statusChecklistSelection.selected) {
      if (this.statusChecklistSelection.isSelected(node)) {
        this.statusChecklistSelection.deselect(node);
      }
    }

    for (const node of this.priorityChecklistSelection.selected) {
      if (this.priorityChecklistSelection.isSelected(node)) {
        this.priorityChecklistSelection.deselect(node);
      }
    }

    this.createdDateRangeInput.nativeElement.value = '';
    this.updatedDateRangeInput.nativeElement.value = '';

    this.ticketId = null;
    this.summary = null;
    this.assignedTo = null;
    this.ticketOwner = null;
    this.createdDateRange = null;
    this.updatedDateRange = null;
    this.priority = null;
  }

  onSearchClicked() {
    this.paginator.pageIndex = 0;
    this.getSearchResults();
    this.toggleFilters();
    this.setCookies();
  }

  private setCookies() {
    // Text fields
    this.cookieService.set('ticket-owner', this.ticketOwner);
    this.cookieService.set('ticket-id', this.ticketId);
    this.cookieService.set('ticket-summary', this.summary);
    this.cookieService.set('assignedTo', this.assignedTo);

    // Datepickers
    if (this.updatedDateRange && this.updatedDateRange['startDate'] && this.updatedDateRange['endDate']) {
      this.cookieService.set('updatedDateRangeStart', this.updatedDateRange['startDate'].format());
      this.cookieService.set('updatedDateRangeEnd', this.updatedDateRange['endDate'].format());
    } else {
      this.cookieService.set('updatedDateRangeStart', null);
      this.cookieService.set('updatedDateRangeEnd', null);
    }

    if (this.createdDateRange && this.createdDateRange['startDate'] && this.createdDateRange['endDate']) {
      this.cookieService.set('createdDateRangeStart', this.createdDateRange['startDate'].format());
      this.cookieService.set('createdDateRangeEnd', this.createdDateRange['endDate'].format());
    } else {
      this.cookieService.set('createdDateRangeStart', null);
      this.cookieService.set('createdDateRangeEnd', null);
    }

    // Checkboxes
    this.cookieService.set('priorityCheckboxes', this.getPrioritiesString());
    this.cookieService.set('statusCheckboxes', this.getStatusesString());
  }

  private getCookies() {
    // Text fields
    const ticketOwnerCookie = this.cookieService.get('ticket-owner');
    const ticketIdCookie = this.cookieService.get('ticket-id');
    const summaryCookie = this.cookieService.get('ticket-summary');
    const assignedToCookie = this.cookieService.get('assignedTo');

    // Saving undefined/null variables in cookies saves them as 'null' or 'undefined` strings
    if (this.isCookieSet(ticketOwnerCookie)) {
      this.ticketOwner = ticketOwnerCookie;
    }

    if (this.isCookieSet(ticketIdCookie)) {
      this.ticketId = ticketIdCookie;
    }

    if (this.isCookieSet(summaryCookie)) {
      this.summary = summaryCookie;
    }

    if (this.isCookieSet(assignedToCookie)) {
      this.assignedTo = assignedToCookie;
    }

    // Datepickers
    this.setUpdatedDateRange();
    this.setCreatedDateRange();

    // Checkboxes
    const priorities = this.cookieService.get('priorityCheckboxes');
    if (this.isCookieSet(priorities)) {
      this.checkPriorityCheckboxes(priorities);
    }

    const statuses = this.cookieService.get('statusCheckboxes');
    if (this.isCookieSet(statuses)) {
      this.checkOpenStatusCheckboxes(statuses);
      this.checkClosedStatusCheckboxes(statuses);
    }
  }

  toggleFilters() {
    this.showFilters = !this.showFilters;
  }

  private generateDatePickerRanges() {
    const today = this.translateService.instant('date.picker.today');
    const yesterday = this.translateService.instant('date.picker.yesterday');
    const last7Days = this.translateService.instant('date.picker.last.7.days');
    const last30Days = this.translateService.instant('date.picker.last.30.days');
    const thisMonth = this.translateService.instant('date.picker.this.month');
    const lastMonth = this.translateService.instant('date.picker.last.month');

    this.ranges[today] = [moment(), moment()];
    this.ranges[yesterday] = [moment().subtract(1, 'days'), moment().subtract(1, 'days')];
    this.ranges[last7Days] = [moment().subtract(6, 'days'), moment()];
    this.ranges[last30Days] = [moment().subtract(29, 'days'), moment()];
    this.ranges[thisMonth] = [moment().startOf('month'), moment().endOf('month')];
    this.ranges[lastMonth] = [moment().subtract(1, 'month')
      .startOf('month'), moment().subtract(1, 'month').endOf('month')];
  }

  public getCancelLabel() {
    return this.translateService.instant('date.picker.cancel');
  }

  public getApplyLabel() {
    return this.translateService.instant('date.picker.apply');
  }

  openPrioritiesHelpDialog() {
    this.dialog.open(PrioritiesDialogComponent, {
      panelClass: 'panel-two-thirds',
    });
  }

  showCurrentContactTickets() {
    const contactName = this.currentContact.firstName.concat(' ').concat(this.currentContact.lastName);
    this.contactInput.nativeElement.value = contactName;
    this.ticketOwner = contactName;
    this.getSearchResults();

    this.paginator.pageIndex = 0;
    this.showFilters = true;
  }

  private checkPriorityCheckboxes(priorityCheckboxes: string) {
    const priorityStatuses = [
      'Priority 1 - Critical',
      'Priority 2 - High',
      'Priority 3 - Medium',
      'Priority 4 - Low',
      'Ignore - No SLA',
    ];

    // Filter out parent checkboxes
    const priorities = priorityCheckboxes
      .split(',')
      .filter(p => priorityStatuses.includes(p));

    // If all priorities all selected, toggle only the parent checkbox
    if (priorities.length === priorityStatuses.length) {
      const node = this.priorityTreeControl.dataNodes.find(
        value => {
          return value.name === 'Priority';
        });
      this.prioritySelectionToggle(node);
    } else {
      for (const priority of priorities) {
        const node = this.priorityTreeControl.dataNodes.find(
          value => {
            return value.name === priority;
          });
        this.priorityChecklistSelection.select(node);
      }
    }
  }

  private checkOpenStatusCheckboxes(statusCheckboxes: string) {
    const openedStatuses = [
      'NEW',
      'ASSIGNED',
      'IN_PROGRESS',
      'PENDING'
    ];

    // Filter out parent checkboxes
    const statuses = statusCheckboxes
      .split(',')
      .filter(p => openedStatuses.includes(p));

    // If all open statuses all selected, toggle only the parent checkbox
    if (statuses.length === openedStatuses.length) {
      const node = this.statusTreeControl.dataNodes.find(
        value => {
          return value.name === 'OPENED';
        });
      this.statusSelectionToggle(node);
    } else {
      for (const status of statuses) {
        const node = this.statusTreeControl.dataNodes.find(
          value => {
            return value.name === status;
          });
        this.statusChecklistSelection.select(node);
      }
    }
  }

  private checkClosedStatusCheckboxes(statusCheckboxes: string) {
    const closedStatuses = [
      'RESOLVED'
    ];

    // Filter out parent checkboxes
    const statuses = statusCheckboxes
      .split(',')
      .filter(p => closedStatuses.includes(p));

    // If all closed statuses all selected, toggle only the parent checkbox
    if (statuses.length === closedStatuses.length) {
      const node = this.statusTreeControl.dataNodes.find(
        value => {
          return value.name === 'CLOSED';
        });
      this.statusSelectionToggle(node);
    } else {
      for (const status of statuses) {
        const node = this.statusTreeControl.dataNodes.find(
          value => {
            return value.name === status;
          });
        this.statusChecklistSelection.select(node);
      }
    }
  }

  private setUpdatedDateRange() {
    const updatedDateRangeStartCookie = this.cookieService.get('updatedDateRangeStart');
    const updatedDateRangeEndCookie = this.cookieService.get('updatedDateRangeEnd');
    if (this.isCookieSet(updatedDateRangeStartCookie) && this.isCookieSet(updatedDateRangeEndCookie)) {
      this.updatedDateRange = {
        startDate: moment(updatedDateRangeStartCookie),
        endDate: moment(updatedDateRangeEndCookie)
      };
    }
  }

  private setCreatedDateRange() {
    const createdDateRangeStartCookie = this.cookieService.get('createdDateRangeStart');
    const createdDateRangeEndCookie = this.cookieService.get('createdDateRangeEnd');
    if (this.isCookieSet(createdDateRangeStartCookie) && this.isCookieSet(createdDateRangeEndCookie)) {
      this.createdDateRange = {
        startDate: moment(createdDateRangeStartCookie),
        endDate: moment(createdDateRangeEndCookie)
      };
    }
  }

  private isCookieSet(cookie: string) {
    return cookie !== ''
      && cookie !== 'null'
      && cookie !== 'undefined';
  }
}

