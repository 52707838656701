import { Injectable } from '@angular/core';
import { MatSnackBarRef } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class ErrorSnackBarService {

  matSnackBarRef: MatSnackBarRef<any>;

  constructor() { }

}
