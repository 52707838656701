import {Component, Injectable, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import {AuthService} from '../auth.service';
import {LanguageService} from '../language.service';
import {EMPTY, from} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {AnalyticsService, EventCategory} from '../analytics.service';
import {RouteService} from '../route.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
@Injectable()
export class SignInComponent implements OnInit {

  username = new FormControl('', [Validators.required]);
  password = new FormControl('', [Validators.required]);
  rememberMe = new FormControl(true);
  signInForm = new FormGroup({
      username: this.username,
      password: this.password,
      rememberMe: this.rememberMe,
    }
  );
  isInProgress = false;

  static isValidOrHasOnlyBadCredentialError(formControl: FormControl): boolean {
    return !formControl.errors ||
      (formControl.errors['badCredentials'] && Object.keys(formControl.errors).length === 1);
  }

  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    private authService: AuthService,
    private languageService: LanguageService,
    private analyticsService: AnalyticsService,
    public routeService: RouteService,
  ) {
  }

  ngOnInit() {
    this.rememberMe.setValue(RememberMeOptionLocalStorage.get());

    if (this.authService.isAuthenticated()) {
      // noinspection JSIgnoredPromiseFromCall
      this.authService.goHome();
    }
  }

  get hasReturnUrl(): boolean {
    return this.authService.hasReturnUrl();
  }

  canSubmitForm(): boolean {
    if (this.signInForm.valid) {
      return true;
    }

    return SignInComponent.isValidOrHasOnlyBadCredentialError(this.username) &&
      SignInComponent.isValidOrHasOnlyBadCredentialError(this.password);
  }

  onSignInClicked() {

    // ignore the click if the form is invalid reasons other reason than exclusively a bad credentials error
    if (!this.canSubmitForm()) {
      return;
    }

    this.signInForm.disable();
    this.isInProgress = true;

    this.authService.doSignIn(
      this.username.value,
      this.password.value,
      this.rememberMe.value
    )
      .pipe(
        switchMap(signInResult => {
          if (signInResult.status === 200) {
            this.analyticsService.trackEvent('Sign In Success', EventCategory.Authentication);
            return from(signInResult.navPromise);
          }
          this.analyticsService.trackEvent('Sign In Failure', EventCategory.Authentication);
          this.signInForm.enable();
          this.isInProgress = false;
          this.snackBar.open('Invalid username and/or password', '', {duration: 5000});
          this.username.setErrors({'badCredentials': true});
          this.password.setErrors({'badCredentials': true});
          return EMPTY;
        })
      ).subscribe();
  }

  onLanguageClicked() {
    this.languageService.changeLanguage();
  }

  onRememberMeChanged() {
    RememberMeOptionLocalStorage.set(this.rememberMe.value);
  }
}

namespace RememberMeOptionLocalStorage {
  export function get(): boolean {
    const item = localStorage.getItem('rememberMeOption');
    return item == null || item === 'true';
  }

  export function set(value: boolean) {
    localStorage.setItem('rememberMeOption', value ? 'true' : 'false');
  }
}
