import {Component, OnInit, ViewChildren} from '@angular/core';
import {DashboardApiService, DashboardData} from '../api/dashboard-api.service';
import * as moment from 'moment';
import {LanguageService} from '../language.service';
import {TranslateService} from '@ngx-translate/core';
import {BaseChartDirective} from 'ng2-charts';
import {Router} from '@angular/router';
import {RouteService} from '../route.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  @ViewChildren(BaseChartDirective) charts;

  isDataLoaded: Promise<boolean>;
  dashboardData: DashboardData;

  // Generally speaking, Red and orange = bad, green and blue = chill
  priorityPieColors: any[] =
    [
      {
        backgroundColor: ['#D60000', '#ff681d', '#ffcc00', '#0076a9', '#D80E9B']
      }
    ];
  statusPieColors: any[] =
    [
      {
        backgroundColor: ['#0076a9', '#ff681d', '#D60000', '#2B9D00', '#D80E9B']
      }
    ];
  activeTicketsAgeColors: any[] =
    [
      {
        backgroundColor: ['#2B9D00', '#0076a9', '#D80E9B', '#ff681d', '#D60000']
      }
    ];

  openedByPriorityLabels: string[];
  openedByPriorityData: number[];
  openedByPriorityType: string;
  openedByPriorityOptions: any;

  openedByStatusLabels: string[];
  openedByStatusData: number[];
  openedByStatusType: string;
  openedByStatusOptions: any;

  ticketAgingLabels: string[];
  ticketAgingData: any;
  ticketAgingType: string;
  ticketAgingOptions: any;

  historicalTicketsLabels: string[] = [];
  historicalTicketsData: any;
  historicalTicketsType: string;
  historicalTicketsOptions: any;

  constructor(
    private dashboardApiService: DashboardApiService,
    private languageService: LanguageService,
    private translate: TranslateService,
    private router: Router,
    private routeService: RouteService,
  ) {
  }

  ngOnInit() {
    this.isDataLoaded = Promise.resolve(false);

    this.dashboardApiService.getDashboardData().subscribe(value => {
      this.dashboardData = Object.assign(new DashboardData, value);
      this.setTicketOpenedByPriorityChart();
      this.setTicketOpenedByStatusChart();
      this.setTicketAgingChart();
      this.setHistoricalOpenedClosedChart();
      this.isDataLoaded = Promise.resolve(true);
    });
  }

  setTicketOpenedByPriorityChart() {
    this.openedByPriorityType = 'doughnut';
    this.setTicketOpenedByPriorityLabels();
    this.openedByPriorityData = [
      this.dashboardData.openedLast30DaysP1,
      this.dashboardData.openedLast30DaysP2,
      this.dashboardData.openedLast30DaysP3,
      this.dashboardData.openedLast30DaysP4,
      this.dashboardData.openedLast30DaysNoSla
    ];
    this.openedByPriorityOptions = {
      maintainAspectRatio: false,
      legend: {
        position: 'left'
      }
    };
  }

  setTicketOpenedByPriorityLabels() {
    this.openedByPriorityLabels = ['P1', 'P2', 'P3', 'P4', 'No SLA'];
  }

  setTicketOpenedByStatusChart() {
    this.openedByStatusType = 'doughnut';
    this.setTicketOpenedByStatusLabels();
    this.openedByStatusData = [
      this.dashboardData.openedLast30DaysNew,
      this.dashboardData.openedLast30DaysAssigned,
      this.dashboardData.openedLast30DaysInProgress,
      this.dashboardData.openedLast30DaysResolved,
      this.dashboardData.openedLast30DaysPending
    ];
    this.openedByStatusOptions = {
      maintainAspectRatio: false,
      legend: {
        position: 'left'
      }
    };
  }

  setTicketOpenedByStatusLabels() {
    this.openedByStatusLabels = [
      this.translate.instant('ticket.status.NEW'),
      this.translate.instant('ticket.status.ASSIGNED'),
      this.translate.instant('ticket.status.IN_PROGRESS'),
      this.translate.instant('ticket.status.RESOLVED'),
      this.translate.instant('ticket.status.PENDING')
    ];
  }

  setTicketAgingChart() {
    this.ticketAgingType = 'bar';
    this.ticketAgingLabels = ['<6', '6-10', '11-20', '21-30', '>30'];
    this.ticketAgingData = [{
      data: [
        this.dashboardData.ageLessThan6Days,
        this.dashboardData.ageBetween6And10Days,
        this.dashboardData.ageBetween11And20Days,
        this.dashboardData.ageBetween21And30Days,
        this.dashboardData.ageMoreThan30Days
      ],
      label: 'Tickets'
    }
    ];
    this.ticketAgingOptions = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        yAxes: [{
          display: true,
          ticks: {
            min: 0,
            max: this.getAgingMax(),
            stepSize: this.getAgingMax() / 2
          }
        }]
      }
    };
  }

  setHistoricalOpenedClosedChart() {
    this.historicalTicketsType = 'bar';
    this.setHistoricalOpenedCloseData();
    this.historicalTicketsOptions = {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        position: 'bottom'
      }
    };
  }

  setHistoricalOpenedCloseData() {
    this.historicalTicketsLabels.length = 0;
    for (let i = 12; i >= 0; i--) {
      this.historicalTicketsLabels.push(
        moment().subtract(i, 'month').format('MMM YY')
      );
    }
    this.historicalTicketsData = [
      {
        data: this.dashboardData.historicalOpenedCounts.reverse(),
        label: this.translate.instant('ticket.status.OPENED'),
        backgroundColor: '#0076a9'
      },
      {
        data: this.dashboardData.historicalClosedCounts.reverse(),
        label: this.translate.instant('ticket.status.CLOSED'),
        backgroundColor: '#2B9D00'
      }
    ];
  }

  getAgingMax() {
    return Math.max(
      this.dashboardData.ageLessThan6Days,
      this.dashboardData.ageBetween6And10Days,
      this.dashboardData.ageBetween11And20Days,
      this.dashboardData.ageBetween21And30Days,
      this.dashboardData.ageMoreThan30Days
    ) + 1;
  }
}

