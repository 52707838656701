import {Component, EventEmitter, OnInit, Output, QueryList, ViewChildren} from '@angular/core';
import {UploaderOptions, UploadInput, UploadOutput} from 'ngx-uploader';
import {FileInfo, FileInfoComponent} from './file-info.component';
import {forkJoin} from 'rxjs';
import {defaultIfEmpty} from 'rxjs/operators';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {

  maxFileSizeKb: number = 50 * 1024;
  options: UploaderOptions;
  fileInfos: FileInfo[];
  uploadInput: EventEmitter<UploadInput>;
  hasLargeFile: boolean;

  @Output() readonly filesChangedEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() readonly uploadFileEvent: EventEmitter<boolean> = new EventEmitter();
  @ViewChildren('fileInfoComp') fileInfoComponents: QueryList<FileInfoComponent>;

  constructor() {
    this.options = {
      concurrency: 1,
      maxUploads: 10
    };
    this.fileInfos = [];
    this.uploadInput = new EventEmitter<UploadInput>();
  }

  ngOnInit() {
  }

  onUploadOutput(output: UploadOutput): void {
    if (output.type === 'allAddedToQueue') {
    } else if (output.type === 'addedToQueue' && typeof output.file !== 'undefined') { // add file to array when added
      this.fileInfos.push(new FileInfo(output.file));
      this.hasLargeFile = this.setHasLargeFile();
      this.filesChanged();
    } else if (output.type === 'uploading' && typeof output.file !== 'undefined') {
      const index = this.fileInfos.findIndex(fileInfo => typeof output.file !== 'undefined' && fileInfo.file.id === output.file.id);
      this.fileInfos[index].file = output.file;
    } else if (output.type === 'removed') {
      this.fileInfos = this.fileInfos.filter((fileInfo: FileInfo) => fileInfo.file !== output.file);
    }
  }

  removeFile(id: string): void {
    this.uploadInput.emit({type: 'remove', id: id});
    this.hasLargeFile = this.setHasLargeFile();
    this.filesChanged();
  }

  childRemovedFile(event) {
    this.removeFile(event.valueOf().toString());
  }

  ceiling(fileSize: number): number {
    return Math.ceil(fileSize / 1024);
  }

  setHasLargeFile(): boolean {
    return this.fileInfos.some(fileInfo => this.ceiling(fileInfo.file.size) > this.maxFileSizeKb);
  }

  filesChanged() {
    this.filesChangedEvent.emit();
  }

  uploadFiles(ticketId: number) {
    return forkJoin(this.fileInfoComponents.map(child => child.fileUpload(ticketId.toString()))).pipe(defaultIfEmpty());
  }
}
