import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {BreakpointObserver} from '@angular/cdk/layout';
import {MatSidenav} from '@angular/material/sidenav';
import {AuthService} from '../auth.service';
import {LanguageService} from '../language.service';
import {CurrentContactService} from '../current-contact.service';
import {RouteService} from '../route.service';
import {CompanyApiService} from '../api/company-api.service';
import {MatDialog} from '@angular/material';
import {DialogCompaniesComponent} from './company-context-dialog/dialog-companies.component';
import {ConfirmResetImpersonationDialogComponent} from './company-context-dialog/confirm-reset-impersonation-dialog.component';
import {tap} from 'rxjs/operators';
import {DialogEditUserComponent} from './dialog-edit-user/dialog-edit-user.component';
import {UserRoles} from '../api/user-management-api.service';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  /*
  These flag are kept to control the layout behavior. They are used similar to how "mobile first" responsive css is
  meant to be done. (See https://css-tricks.com/logic-in-media-queries/#article-header-id-6)

  Also, having breakpoints tripped like this as opposed to exclusive queries (`Breakpoints.Small` and company) avoids
  fractional css pixels and the problems they can cause when using exclusive ranges.
  See http://damienclarke.me/code/posts/those-1px-gaps-between-media-queries-can-be-a-problem
   */
  private isSizeAtLeastMedium = false;
  private isSizeAtLeastLarge = false;

  companyLogo: string;
  hasLogo: boolean;
  hasProfilePicture: boolean;
  profilePicture: string;
  isZiroUser: boolean;
  isImpersonated: boolean;
  canViewUsersSection: boolean;
  companyName: string;
  fullName: string;

  @ViewChild(MatSidenav, {static: true}) private matSidenav: MatSidenav;

  constructor(
    private authService: AuthService,
    private currentContactService: CurrentContactService,
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private languageService: LanguageService,
    private companyApiService: CompanyApiService,
    public routeService: RouteService,
    private dialog: MatDialog,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.currentContactService.contact$.pipe(
      tap(currentContactInfo => {
        if (!currentContactInfo) {
          this.isZiroUser = false;
          this.isImpersonated = false;
          this.canViewUsersSection = false;
          this.companyName = null;
          this.fullName = null;
        } else {
          this.isImpersonated = currentContactInfo.roles.includes('CAN_RESET_IMPERSONATION');
          this.isZiroUser = currentContactInfo.companyIdentifier === 'ZIRO' && !this.isImpersonated;
          this.canViewUsersSection = currentContactInfo.userType === 'ADMIN';
          this.companyName = currentContactInfo.companyName;
          this.fullName = currentContactInfo.firstName + ' ' + currentContactInfo.lastName;
        }
      })
    ).subscribe();
  }

  ngOnInit() {


    // Given a relative URL path string, the bypassSecurityTrustResourceUrl
    // method on DomSanitizer will return a safe resource URL, which is required by the addSvgIcon method.
    this.matIconRegistry.addSvgIcon(
      'ziro-logo',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/ziro-logo.svg')
    );
    // The breakpoint observers are fired immediately when added. This flag is used to prevent them from doing any
    // actual work before the setup is complete.
    let doUpdate = false;

    this.getCompanyLogo();
    this.getProfilePicture();

    this.breakpointObserver.observe('(min-width: 960px)').subscribe(result => {
      this.isSizeAtLeastMedium = result.matches;
      if (doUpdate) {
        this.updateLayout();
      }
    });

    this.breakpointObserver.observe('(min-width: 1280px)').subscribe(result => {
      this.isSizeAtLeastLarge = result.matches;
      if (doUpdate) {
        this.updateLayout();
      }
    });

    this.companyApiService.companyLogo$.subscribe(() => this.getCompanyLogo());

    // call manually now that the setup of all observers has been complete
    this.updateLayout();
    // and from now on breakpoint observers update the layout
    doUpdate = true;
  }

  getCompanyLogo() {
    this.companyApiService.getCompanyLogo()
      .subscribe((value => {
        this.hasLogo = value !== null;
        if (this.hasLogo) {
          this.companyLogo = 'data:image/png;base64,' + value.body;
        }
      }));
  }

  getProfilePicture() {
    this.currentContactService.getProfilePicture()
      .subscribe(value => {
        this.profilePicture = 'data:image/png;base64,' + value;
        this.hasProfilePicture = true;
      });
  }

  openCompanyImpersonationDialog() {
    if (this.isZiroUser) {
      this.dialog.open(DialogCompaniesComponent, {
        disableClose: true,
        height: '90%',
        maxHeight: '850px',
        width: '40%',
        maxWidth: '700px',
        minWidth: '500px',
      });
    } else if (this.isImpersonated) {
      this.dialog.open(ConfirmResetImpersonationDialogComponent, {
        disableClose: true,
        height: '140px',
        width: '260px',
        minWidth: '260px',
        minHeight: '140px',
      });
    }
  }

  private isSmall(): boolean {
    return !this.isSizeAtLeastMedium;
  }

  private isMedium(): boolean {
    return this.isSizeAtLeastMedium && !this.isSizeAtLeastLarge;
  }

  private isLarge(): boolean {
    return this.isSizeAtLeastLarge;
  }

  private updateLayout() {
    console.log('updateLayout', {
      'atLeastMedium': this.isSizeAtLeastMedium,
      'atLeastLarge': this.isSizeAtLeastLarge,
      'isSmall': this.isSmall(),
      'isMedium': this.isMedium(),
      'isLarge': this.isLarge(),
    });

    if (this.isSmall() || this.isMedium()) {
      if (this.matSidenav.mode !== 'over') {
        this.matSidenav.mode = 'over';
        this.closeSideNav();
      }
    } else {
      if (this.matSidenav.mode !== 'side') {
        this.matSidenav.mode = 'side';
        this.openSideNav();
      }
    }
  }

  private closeSideNav() {
    // noinspection JSIgnoredPromiseFromCall
    this.matSidenav.close();
  }

  private openSideNav() {
    // noinspection JSIgnoredPromiseFromCall
    this.matSidenav.open('program');
  }

  onSignOutClicked() {
    this.authService.doSignOut().subscribe(() => this.router.navigate(this.routeService.signIn()));
  }

  /**
   * This is used to close the nav when it is clicked and is not permanently opened.
   */
  onSideNavClicked() {
    if (this.isSmall() || this.isMedium()) {
      this.closeSideNav();
    }
  }

  onLanguageClicked() {
    this.languageService.changeLanguage();
  }

  openCurrentContactEditDialog() {
    this.dialog.open(DialogEditUserComponent, {
      disableClose: true,
    }).afterClosed().subscribe(() => {
      this.getProfilePicture();
    });
  }

  getCurrentContact() {
    return this.currentContactService.getContact();
  }

  getUserTypeI18n(userType: string) {
    return UserRoles.roles[userType].i18n;
  }
}
