import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import {FormControl, Validators} from '@angular/forms';
import {LanguageService} from '../../language.service';
import {TranslateService} from '@ngx-translate/core';
import {CurrentContactService} from '../../current-contact.service';
import {ContactJson} from '../../api/auth-api.service';
import {TicketApiService} from '../../api/ticket-api.service';
import {ProgressSpinnerDialogComponent} from '../../progress-spinner-dialog/progress-spinner-dialog.component';
import {timer} from 'rxjs';
import {AnalyticsService, EventCategory} from '../../analytics.service';

@Component({
  selector: 'app-escalation-dialog',
  templateUrl: './escalation-dialog.component.html',
  styleUrls: ['./escalation-dialog.component.scss']
})
export class EscalationDialogComponent implements OnInit {

  ticketId: string;
  isEscalatedDesired: boolean;
  currentContact: ContactJson;

  emailFormControl: FormControl;
  phoneFormControl: FormControl;

  phonePlaceholder: string;
  emailPlaceholder: string;

  contactMethods: any[] = [
    {name: 'Phone', radio: 'escalate.phone.radio'},
    {name: 'Email', radio: 'escalate.email.radio'}
  ];
  selectedMethod: any = this.contactMethods[0];

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<EscalationDialogComponent>,
    private languageService: LanguageService,
    private translate: TranslateService,
    private currentContactService: CurrentContactService,
    private ticketApiService: TicketApiService,
    private analyticsService: AnalyticsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
  }

  ngOnInit() {
    this.ticketId = this.data['ticketId'];

    this.isEscalatedDesired = false;
    this.currentContact = this.currentContactService.getContact();

    this.setPhonePlaceholder();
    this.setEmailPlaceholder();

    this.phoneFormControl = new FormControl(this.currentContact.phoneNumber );
    this.emailFormControl = new FormControl(this.currentContact.email, [Validators.email]);

  }

  setPhonePlaceholder() {
    this.phonePlaceholder = this.translate.instant('escalate.phone.field');
  }

  setEmailPlaceholder() {
    this.emailPlaceholder = this.translate.instant('escalate.email.field');
  }

  onDialogClose(isEscalated: boolean): void {
    const results = [];
    results['isEscalated'] = isEscalated;
    this.dialogRef.close(results);
  }

  onEscalationDesiredClicked() {
    this.isEscalatedDesired = true;
  }

  onEscalationConfirmedClicked() {
    const value = this.selectedMethod === this.contactMethods[1] ?
      this.emailFormControl.value : this.phoneFormControl.value;

    const dialogSpinner: MatDialogRef<ProgressSpinnerDialogComponent> = this.dialog.open(ProgressSpinnerDialogComponent, {
      panelClass: 'transparent',
      disableClose: true
    });

    this.ticketApiService.escalateTicket(this.ticketId, this.selectedMethod.name, value).subscribe();

    timer(3000)
      .subscribe(() => {
        dialogSpinner.close();
        this.onDialogClose(true);
        this.analyticsService.trackEvent('Escalation', EventCategory.Ticket, this.ticketId);
      });
  }

  isButtonDisabled() {
    if (this.selectedMethod === this.contactMethods[0]) {
      if (this.phoneFormControl.value == null) {
        return true;
      }
      return !(this.phoneFormControl.value.toString().length > 0) || !this.phoneFormControl.valid;
    } else if (this.selectedMethod === this.contactMethods[1]) {
      return !(this.emailFormControl.value.toString().length > 0) || !this.emailFormControl.valid;
    } else {
      return false;
    }
  }
}
