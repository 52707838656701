import {Component, EventEmitter, Inject, OnInit, ViewChild} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {UploadInput} from 'ngx-uploader';
import {FileUploadComponent} from '../file-upload/file-upload.component';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-ticket-status-dialog',
  templateUrl: 'ticket-status-dialog.component.html',
  styleUrls: ['./ticket-status-dialog.component.scss']
})
export class TicketStatusDialogComponent implements OnInit {

  ticketId: string;
  ticketStatus: string;
  hasLargeFile: boolean;
  uploadInput: EventEmitter<UploadInput>;
  selectedStatus: string;
  availableStatuses: string[];
  note = new FormControl('', [Validators.required]);
  noteForm = new FormGroup({
    note: this.note,
  });
  emitDirty = new EventEmitter();

  @ViewChild(FileUploadComponent, {static: false}) fileUploadComponent;

  constructor(
    public dialogRef: MatDialogRef<TicketStatusDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar,
    private translateService: TranslateService) {
    this.uploadInput = new EventEmitter<UploadInput>(); // input events, we use this to emit data to ngx-uploader
  }

  onCancelClicked(): void {
    this.dialogRef.close();
  }

  onKeyPress() {
    this.emitDirty.emit();
  }


  receiveFilesChange() {
    this.hasLargeFile = this.fileUploadComponent.hasLargeFile;
  }

  isClosed() {
    return this.ticketStatus === 'RESOLVED';
  }

  onSetNewStatusClicked() {
    if (!this.noteForm.valid) {
      let text = '';
      this.translateService.get('status.change.add.note').subscribe((res: string) => {
        text = res;
      });

      this.snackBar.open(text, '', {duration: 5000});
      return;
    }
    if (this.selectedStatus) {

      this.fileUploadComponent.uploadFiles(this.ticketId)
        .subscribe(() => {
          const results = [];
          results['note'] = this.note.value;
          results['isClosed'] = this.isClosed();
          results['selectedStatus'] = this.selectedStatus;
          this.dialogRef.close(results);
        });
    }
  }

  ngOnInit(): void {
    this.availableStatuses = this.data['availableStatuses'];
    this.ticketStatus = this.data['ticketStatus'];
    this.ticketId = this.data['ticketId'];
  }
}
