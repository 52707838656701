import {Injectable} from '@angular/core';
import {Resolve} from '@angular/router';
import {ContactJson} from './api/auth-api.service';
import {publishReplay, refCount, tap} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CurrentContactService implements Resolve<any> {

  private contactPromise;
  private contact: ContactJson;
  public _contact$: Subject<ContactJson> = new Subject<ContactJson>();
  public contact$: Observable<ContactJson>;

  constructor(private http: HttpClient) {
    this.contact$ = this._contact$.pipe(
      publishReplay(1),
      refCount()
    );
    this.contact$.pipe(
      tap(x => {
        this.contact = x;
      })
    ).subscribe();
  }

  resolve(): Promise<any> {
    if (this.contactPromise) {
      return this.contactPromise;
    }
    return this.reload();
  }

  reload() {
    this.contactPromise = this.getCurrentContactApi()
      .pipe(
        tap(x => {
          this._contact$.next(x);
        })
      )
      .toPromise();
    return this.contactPromise;
  }

  clear() {
    this._contact$.next(null);
    this.contactPromise = null;
  }

  getContactMaybe(): ContactJson | null {
    return this.contact;
  }

  getContact(): ContactJson {
    if (!this.contact) {
      throw new Error('Null contact');
    }
    return this.contact;
  }

  private getCurrentContactApi(): Observable<ContactJson> {
    return this.http.get<ContactJson>('api/contact/current');
  }

  getProfilePicture(): Observable<string> {
    return this.http.get('/api/contact/' + this.getContact().contactId + '/profile-picture', {responseType: 'text'});
  }

  changeProfilePicture(formdata: FormData): Observable<any> {
    const headers = new HttpHeaders();
    headers.set('Content-Type', null);
    headers.set('Accept', 'multipart/form-data');
    return this.http.post(
      '/api/contact/' + this.getContact().contactId + '/change-profile-picture',
      formdata,
      {headers: headers}
    );
  }
}
