<div class="container">
  <h1 mat-dialog-title>{{ 'ziro.dashboard.delete.token.dialog.title' | translate}}</h1>
  <span class="dialog-content">{{ 'ziro.dashboard.delete.token.dialog.content' | translate}} </span>
  <div mat-dialog-actions>
    <div style="margin-left: auto; padding-top: 28px; padding-bottom: 28px">
      <button color="basic" mat-raised-button
              (click)="onCancelClicked()">{{ 'ziro.dashboard.delete.token.dialog.cancel' | translate}}</button>
      <button color="primary" mat-raised-button
              (click)="onConfirmClicked()">{{ 'ziro.dashboard.delete.token.dialog.confirm' | translate}}</button>
    </div>
  </div>
</div>
