<div class="user-management-container">
  <h1>{{ 'user.management.title' | translate}}</h1>
  <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
  <div *ngIf="!isLoading">

    <div class="data-row">
      <mat-card style="margin-right: 20px">
        <mat-card-header>
          <mat-card-title>{{ 'user.management.header.users' | translate }}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <h1 data-auto="active-user-count">{{currentNumberOfActiveUsers}} / {{maxNumberOfEnabledUsers}}</h1>
        </mat-card-content>
      </mat-card>

      <mat-card>
        <mat-card-header>
          <mat-card-title>{{ 'user.management.header.admins' | translate }}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <h1 data-auto="admin-count">{{currentNumberOfAdmins}} / {{maxNumberOfAdmins}}</h1>
        </mat-card-content>
      </mat-card>
    </div>

    <mat-table #userstable
               [dataSource]="dataSource"
               (matSortChange)="sortData($event)"
               matSort
               matSortDisableClear
               class="mat-elevation-z8 files-table">

      <ng-container matColumnDef="user">
        <mat-header-cell *matHeaderCellDef>
          <div class="filter-header">
            {{ 'user.management.table.header.user' | translate }}
            <mat-form-field>
              <label>
                <input matInput name="userFilter" [formControl]="userFilter">
              </label>
            </mat-form-field>
          </div>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" >
          <span data-auto="user-management-name">{{ row.firstName }} {{row.lastName }}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="email">
        <mat-header-cell *matHeaderCellDef>
          <div class="filter-header">
            {{ 'user.management.table.header.email' | translate }}
            <mat-form-field>
              <label>
                <input matInput name="emailFilter" [formControl]="emailFilter">
              </label>
            </mat-form-field>
          </div>
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <span data-auto="user-management-email">{{ row.email }}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="phone">
        <mat-header-cell *matHeaderCellDef>
          <div class="filter-header">
            {{ 'user.management.table.header.phone' | translate }}
            <mat-form-field>
              <label>
                <input matInput name="phoneFilter" [formControl]="phoneFilter">
              </label>
            </mat-form-field>
          </div>
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <span data-auto="user-management-phone">{{ row.phoneNumber }}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="role">
        <mat-header-cell *matHeaderCellDef>
          <div class="filter-header">
            <div class="role-header">
              <div>{{ 'user.management.table.header.role' | translate }}</div>
              <mat-icon class="role-icon" matListIcon (click)="openUserTypesHelpDialog()">help</mat-icon>
            </div>
            <mat-form-field>
              <label>
                <input matInput name="roleFilter" [formControl]="roleFilter">
              </label>
            </mat-form-field>
          </div>
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <span data-auto="user-management-role">{{ getRoleI18n(row.userType) | translate }}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef>
          <div class="filter-header">
            {{ 'user.management.table.header.status' | translate }}
            <mat-form-field>
              <label>
                <input matInput name="statusFilter" [formControl]="statusFilter">
              </label>
            </mat-form-field>
          </div>
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <span data-auto="user-management-status">{{ getStatus(row.isPortalEnabled) }}</span>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row class="row-cursor" *matRowDef="let row; columns: displayedColumns;"
               (click)="onEditUserClicked(row)"></mat-row>
    </mat-table>

    <div class="speed-dial-container">
      <div class="add-user-button">
        <button mat-fab
                color="accent"
                type="submit"
                tabindex="3"
                (click)="onAddNewUserClicked()"
                data-auto="add-user-fab">
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
