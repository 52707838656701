import {Component, OnInit} from '@angular/core';
import {AnalyticsService} from '../analytics.service';
import {LanguageService} from '../language.service';
import {environment} from '../../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import * as moment from 'moment';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {filter, map, mergeMap} from 'rxjs/operators';
import {SuffixTitleService} from '../suffix-title.service';

declare var window: any;

@Component({
  selector: 'app-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.scss']
})
export class RootComponent implements OnInit {

  constructor(
    private analyticsService: AnalyticsService,
    private languageService: LanguageService,
    private translate: TranslateService,
    private router: Router,
    private titleService: SuffixTitleService,
    private activatedRoute: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {

    doDisableAnimations();
    this.createCookieConsentPopup();
    moment.locale(this.languageService.getCurrent());
    this.setTitle();
  }

  private setTitle() {
    // https://toddmotto.com/dynamic-page-titles-angular-2-router-events
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data)
    ).subscribe((data) => {
      if (data) {
        const titleKey = data['titleKey'];
        if (titleKey) {
          this.titleService.setTitle(this.translate.instant(titleKey));
          return;
        }
      }
      this.titleService.setTitle(this.translate.instant('general.customer-portal'));
    });
  }

  private createCookieConsentPopup() {
    // Cookieconsent by default adds itself to the body, which then causes it to be in front of other elements
    // event when it has been dismissed.
    // Here we're giving it a container to live in, which we'll discard as soon as it's no longer needed.
    // That way we don't have this interacting strangely with anything else once it's served its purpose.
    let container = document.createElement('div');
    document.body.insertBefore(container, document.body.firstChild);

    // if the container is null, it means that the user has already dismissed the popup, no need to do anything more.
    if (container == null) {
      return;
    }

    function onDismiss() {
      if (container) {
        container.remove();
        container = null;
      }
    }

    window.cookieconsent.initialise(
      {
        'container': container,
        // this is called when consent is provided (ie the button is clicked)
        'onStatusChange': function (newStatus) {
          if (newStatus === 'dismiss') {
            onDismiss();
          }
        },
        // this is called when consent was already provided (ie the button was clicked at some point in the past)
        'onInitialise': function (status) {
          if (status === 'dismiss') {
            onDismiss();
          }
        },
        'palette': {
          'popup': {
            'background': '#000000',
            'text': '#ffffff'
          },
          'button': {
            'background': '#2DCCD3',
            'text': '#ffffff'
          }
        },
        'position': 'top',
        'content': {
          'message': this.translate.instant('cookieconsent.message'),
          'dismiss': this.translate.instant('cookieconsent.dismiss'),
          'link': this.translate.instant('cookieconsent.link'),
          'href': 'https://goziro.com/about-ziro/privacy-policy/'
        }
      }
    );
  }
}


function doDisableAnimations() {
  if (environment['disableAnimations']) {
    if (!document.getElementById('disable-animations-style')) {
      const styleEl = document.createElement('style');
      styleEl.id = 'disable-animations-style';
      styleEl.textContent =
        ` * {
  animation-name: none !important;
  transition: none !important;
}`;
      document.head.appendChild(styleEl);
    }
  }
}
