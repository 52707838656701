import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProjectApiService {

  constructor(private http: HttpClient) {
  }

  listProjectSummaries(): Observable<ProjectSummary[]> {
    return this.http.get<ProjectSummary[]>('/api/project');
  }

  getProject(projectId: string): Observable<ProjectSummary> {
    return this.http.get<ProjectSummary>('/api/project/' + encodeURIComponent(projectId));
  }

  getNotes(projectId: string): Observable<ProjectNote[]> {
    return this.http.get<ProjectNote[]>('/api/project/' + encodeURIComponent(projectId) + '/notes');
  }

  getAttachments(projectId: string): Observable<ProjectAttachment[]> {
    return this.http.get<ProjectAttachment[]>('/api/project/' + encodeURIComponent(projectId) + '/attachments');
  }

  downloadAttachment(projectId: string, documentId: string): Observable<Blob> {
    return this.http.get(
      '/api/project/' + encodeURIComponent(projectId) + '/attachments/' + encodeURIComponent(documentId) + '/download',
      {responseType: 'blob'}
    );
  }

  getIssues(projectId: string): Observable<ProjectIssue[]> {
    return this.http.get<ProjectIssue[]>('/api/project/' + encodeURIComponent(projectId) + '/issues');
  }
}

export class ProjectSummary {
  id: number;
  projectName: string;
  managerFirstName: string;
  managerLastName: string;
  status: string;
  type: string;
  actualStartDate: string;
  actualEndDate: string;
  description: string;
  projectHealth: string;
  completion: number;
}

export class ProjectNote {
  note: string;
  lastUpdate: string;
  authorFirstName: string;
  authorLastName: string;
}

export class ProjectAttachment {
  id: number;
  title: string;
  fileName: string;
  publicFlag: number;
}

export class ProjectIssue {
  id: number;
  issueOwner: string;
  issueStatus: string;
  summary: string;
  closedFlag: boolean;
  dateEntered: string;
  impactSummary: string;
  actionSteps: string;
  initialDescription: string;
  urgency: string;
}
