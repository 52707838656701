import {Component, OnInit, ViewChild} from '@angular/core';
import {map, startWith, switchMap} from 'rxjs/operators';
import {from, Observable} from 'rxjs';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {TicketApiService} from '../api/ticket-api.service';
import {AnalyticsService, EventCategory} from '../analytics.service';
import {FileUploadComponent} from '../file-upload/file-upload.component';
import {FormCanDeactivate} from '../form-can-deactivate/form-can-deactivate';
import {RouteService} from '../route.service';
import {Router} from '@angular/router';
import {AuthApiService, ContactJson} from '../api/auth-api.service';
import {UserManagementApiService, UserRoles} from '../api/user-management-api.service';
import {CurrentContactService} from '../current-contact.service';

@Component({
  selector: 'app-create-ticket',
  templateUrl: './create-ticket.component.html',
  styleUrls: ['./create-ticket.component.scss']
})
export class CreateTicketComponent extends FormCanDeactivate implements OnInit {

  isLoading: boolean;
  isSaving: boolean;
  hasLargeFile: boolean;

  currentContact: ContactJson;
  currentContacts: ContactJson[];
  filteredPortalContacts: Observable<ContactJson[]>;
  selectedContact: ContactJson;

  title = new FormControl('', [Validators.required, Validators.maxLength(100)]);
  description = new FormControl('', [Validators.required]);
  ticketForm = new FormGroup({
    title: this.title,
    description: this.description
  });
  onBehalf = new FormControl();

  upload = new FormControl('');
  uploadForm = new FormGroup({
    upload: this.upload,
  });


  @ViewChild(FileUploadComponent, {static: false}) fileUploadComponent: FileUploadComponent;

  constructor(
    private ticketApiService: TicketApiService,
    private analyticsService: AnalyticsService,
    private routeService: RouteService,
    private router: Router,
    private authApiService: AuthApiService,
    private userManagementApiService: UserManagementApiService,
    private currentContactService: CurrentContactService
  ) {
    super();
    this.currentContactService.contact$.subscribe(value => this.currentContact = value);
  }

  ngOnInit() {
    this.isLoading = true;
    if (this.canCreateTicketForOthers()) {
      this.userManagementApiService
        .getUsersForCompany(this.currentContact.companyId)
        .subscribe(users => {
          this.currentContacts = users;
          this.currentContacts.sort(function (a, b) {
            if (b.lastName && a.lastName) {
              return a.lastName.localeCompare(b.lastName);
            } else {
              return -1;
            }
          });
          this.filteredPortalContacts = this.onBehalf.valueChanges
            .pipe(
              startWith(''),
              map(value => typeof value === 'string' ? value : value.name),
              map(name => name ? this._filter(name) : this.currentContacts.slice())
            );
          this.isLoading = false;
          this.onBehalf.setValue(users.filter(user => user.contactId === this.currentContact.contactId)[0]);
        });
    } else {
      this.selectedContact = new ContactJson();
      this.selectedContact.contactId = this.currentContact.contactId;
      this.isLoading = false;
    }
  }

  private createTicket(title, description) {
    this.isSaving = true;
    this.ticketForm.disable();

    if (!this.selectedContact) {
      this.selectedContact = new ContactJson();
      this.selectedContact.contactId = this.currentContact.contactId;
      this.analyticsService.trackEvent('Create Ticket On Behalf', EventCategory.Ticket);
    }
    if (this.onBehalf.value) {
      this.selectedContact.contactId = this.onBehalf.value.contactId;
    }

    this.ticketApiService.createTicket(title, description, Number(this.selectedContact.contactId))
      .pipe(
        switchMap(ticketId => {
          return this.fileUploadComponent.uploadFiles(ticketId)
            .pipe(map(() => ticketId));
        }),
        switchMap((ticketId: number) => {
          this.analyticsService.trackEvent('Create Ticket', EventCategory.Ticket, ticketId.toString());
          this.ticketForm.markAsPristine();
          return from(this.router.navigate(this.routeService.ticket(ticketId)));
        })
      ).subscribe();
  }

  onNewTicketSave() {
    if (!this.ticketForm.valid) {
      return;
    }

    this.createTicket(
      this.title.value,
      this.description.value,
    );
  }

  receiveFilesChange() {
    this.hasLargeFile = this.fileUploadComponent.hasLargeFile;
  }

  get forms(): FormGroup[] {
    const groups: FormGroup[] = [];
    groups.push(this.ticketForm);
    groups.push(this.uploadForm);
    return groups;
  }

  canCreateTicketForOthers(): boolean {
    return this.currentContact.userType === UserRoles.roles.MANAGER.value ||
      this.currentContact.userType === UserRoles.roles.ADMIN.value;
  }

  displayFn(user?: ContactJson): string | undefined {
    return user ? user.firstName + ' ' + user.lastName : undefined;
  }

  private _filter(name: string): ContactJson[] {
    const filterValue = name.toLowerCase();

    return this.currentContacts.filter(user => (user.firstName + ' ' + user.lastName).toLowerCase().includes(filterValue));
  }
}
