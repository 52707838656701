import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {AuthApiService} from '../api/auth-api.service';
import {LanguageService} from '../language.service';
import {switchMap} from 'rxjs/operators';
import {from} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {RouteService} from '../route.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  username = new FormControl('', [Validators.required]);
  resetPassForm = new FormGroup({
      username: this.username
    }
  );
  isInProgress = false;

  constructor(
    private router: Router,
    private authApiService: AuthApiService,
    private snackBar: MatSnackBar,
    private languageService: LanguageService,
    private translateService: TranslateService,
    private routeService: RouteService,
  ) {
  }

  ngOnInit() {
  }

  onSendClicked() {
    if (!this.resetPassForm.valid) {
      return;
    }

    this.resetPassForm.disable();
    this.isInProgress = true;

    this.authApiService.sendResetPassword(this.username.value, this.languageService.getCurrent())
      .pipe(
        switchMap(() => {
          this.resetPassForm.enable();
          this.isInProgress = false;
          let text = '';
          this.translateService.get('reset.password.snackbar.instruction').subscribe((res: string) => {
            text = res;
          });
          this.snackBar.open(
            text + this.username.value,
            '',
            {duration: 5000}
          );

          return from(this.router.navigate(this.routeService.signIn()));
        })
      ).subscribe();
  }

  onLanguageClicked() {
    this.languageService.changeLanguage();
  }
}
