import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ZiroDashboardApiService} from '../../api/ziro-dashboard-api.service';

@Component({
  selector: 'app-delete-token-dialog',
  templateUrl: './delete-token-dialog.component.html',
  styleUrls: ['./delete-token-dialog.component.scss']
})
export class DeleteTokenDialogComponent implements OnInit {

  constructor(
    private ziroDashboardApiService: ZiroDashboardApiService,
    private dialogRef: MatDialogRef<DeleteTokenDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  ngOnInit() {
  }

  onCancelClicked(): void {
    this.dialogRef.close();
  }

  onConfirmClicked(): void {
    this.ziroDashboardApiService.deleteConfigurationApiToken(this.data.configId).subscribe(
      () => this.dialogRef.close()
    );
  }
}
