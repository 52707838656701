<div class="container">
  <h1 mat-dialog-title>{{ 'create.ticket.status.dialog.title' | translate}}</h1>

  <div mat-dialog-content>
    <p *ngIf="!availableStatuses || availableStatuses.length == 0">
      {{ 'create.ticket.status.dialog.contact.us' | translate}}
      <app-contact-us></app-contact-us>
    </p>
  </div>
  <div *ngIf="availableStatuses && availableStatuses.length > 0">
    <mat-form-field>
      <mat-select [(ngModel)]="selectedStatus"
                  placeholder="{{ 'create.ticket.status.dialog.select.status' | translate}}">
        <mat-option *ngFor="let status of availableStatuses" [value]="status">
          {{status | ticketStatus }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div mat-dialog-content *ngIf="!isClosed()">
    <form [formGroup]="noteForm" (keypress)="onKeyPress()">

      <div class="add-note-form">
        <mat-form-field>
        <textarea matInput
                  placeholder="{{ 'create.ticket.note.dialog.note' | translate}}"
                  formControlName="note"
                  rows="5"
                  tabindex="2"
                  data-auto="change-status-text">
        </textarea>
          <mat-error *ngIf="note.dirty && note.errors && note.errors['required']">
            Please provide a note
          </mat-error>
        </mat-form-field>

      </div>
    </form>

    <app-file-upload (filesChangedEvent)="receiveFilesChange()"></app-file-upload>
  </div>
  <div mat-dialog-actions *ngIf="availableStatuses && availableStatuses.length > 0">
    <div style="margin-left: auto;">
      <button mat-raised-button
              color="primary"
              (click)="onSetNewStatusClicked()"
              [disabled]="!selectedStatus || hasLargeFile || !noteForm.valid"
              data-auto="change-status-submit">
        <span>{{ 'create.ticket.status.dialog.change.status.button' | translate}}</span>
      </button>
      <button mat-button (click)="onCancelClicked()">{{ 'general.cancel' | translate}}</button>
    </div>
  </div>
</div>
