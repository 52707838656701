import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ErrorReportApiService {

  constructor(
    private http: HttpClient
  ) {
  }

  reportError(error: any): Observable<any> {
    // noinspection JSIgnoredPromiseFromCall
    return this.http.post(
      '/api/client-error',
      {
        'message': error.message,
        'stack': error.stack,
      },
      {observe: 'response'}
    );
  }

}
