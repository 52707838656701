import {Component, OnInit} from '@angular/core';
import {MatDialogRef, MatTableDataSource} from '@angular/material';
import {Company, CompanyApiService} from '../../api/company-api.service';
import {AuthApiService} from '../../api/auth-api.service';
import {Router} from '@angular/router';
import {RouteService} from '../../route.service';

@Component({
  selector: 'app-dialog-companies',
  templateUrl: './dialog-companies.component.html',
  styleUrls: ['./dialog-companies.component.scss'],
})

export class DialogCompaniesComponent implements OnInit {
  currentStep: string;
  dataSource = new MatTableDataSource();
  selectedCompany: Company;
  selectedUserType: string;
  isLoading: boolean;

  constructor(
    private companyApiService: CompanyApiService,
    private dialogRef: MatDialogRef<DialogCompaniesComponent>,
    private authApi: AuthApiService,
    private router: Router,
    private routeService: RouteService
  ) {
    this.currentStep = 'companyList';
    this.isLoading = true;
  }

  ngOnInit() {
    this.companyApiService.getAllCompanies()
      .subscribe(results => {
        this.dataSource.data = results;
        this.isLoading = false;
      });
  }

  confirm() {
    this.authApi.impersonate(this.selectedCompany.id, this.selectedUserType).subscribe(() => {
      this.router.navigate(this.routeService.dashboard()).then(() => window.location.reload());
    });
  }
}
