import {Component, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ReadOnlyTicket, TicketApiService, TicketAttachment, TicketNote, UiEscalationStatus} from '../api/ticket-api.service';
import {saveAs} from 'file-saver';
import {Location, LocationStrategy} from '@angular/common';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {MatTabGroup} from '@angular/material/tabs';
import {TicketAddNoteDialogComponent} from './ticket-add-note-dialog.component';
import {EscalationDialogComponent} from './escalation/escalation-dialog.component';
import {distinctUntilChanged, filter, map, share, switchMap, takeUntil, tap} from 'rxjs/operators';
import {combineLatest, forkJoin, interval, merge, Subject, zip} from 'rxjs';
import {AnalyticsService, EventCategory} from '../analytics.service';
import {RouteService} from '../route.service';
import {SuffixTitleService} from '../suffix-title.service';
import {RouterPathParams} from '../route/router-paths';
import {TicketStatusDialogComponent} from './ticket-status-dialog.component';
import {CurrentContactService} from '../current-contact.service';
import {FormGroup} from '@angular/forms';
import {FormCanDeactivate} from '../form-can-deactivate/form-can-deactivate';

class NotePanel {
  isOpened: boolean;
  note: TicketNote;
}

// defines the url parameter name of a tab as well as its index in the tab group
const TABS = ['description', 'notes', 'attachments'];

@Component({
  selector: 'app-ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.scss']
})
export class TicketComponent extends FormCanDeactivate implements OnInit, OnDestroy {

  public ticketId: number;
  public isLoading: boolean;
  public ticketTitle: string;
  public ticketStatus: string;
  public ticketPriority: string;
  public ticketCreationDate: string;
  public ticketAssignedTo: string;
  public ticketLastUpdated: string;
  public initialDescription: TicketNote;
  public notePanels: NotePanel[];
  public attachments: TicketAttachment[];

  private escalationStatus: UiEscalationStatus;
  private ticketAvailableStatuses: string[];
  private isEscalationStatusPolling = false;

  private escalationPoolingTrigger$ = new Subject<void>();
  private ngUnsubscribe = new Subject<void>();
  private ticketReloadTrigger$ = new Subject<string>();
  private tabGroup$: Subject<MatTabGroup> = new Subject<MatTabGroup>();
  private ticketContact: string;

  dialogForm = new FormGroup({});

  @ViewChild(MatTabGroup, {static: true}) private set tabGroup(tabGroup: MatTabGroup) {
    if (tabGroup) {
      this.tabGroup$.next(tabGroup);
    }
  }

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private ticketApiService: TicketApiService,
    private dialog: MatDialog,
    private analyticsService: AnalyticsService,
    private location: Location,
    private locationStrategy: LocationStrategy,
    private routeService: RouteService,
    private titleService: SuffixTitleService,
    private zone: NgZone,
    private currentContactService: CurrentContactService
  ) {
    super();
  }

  ngOnInit() {
    this.ticketSetup();
    this.tabSetup();
  }

  ngOnDestroy(): void {
    // this pattern is suggested by https://stackoverflow.com/a/41177163/501930
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  get forms(): FormGroup[] {
    const groups: FormGroup[] = [];
    groups.push(this.dialogForm);
    return groups;
  }

  private tabSetup() {
    const tabParam$ = this.activatedRoute.paramMap.pipe(
      map(paramMap => paramMap.get('tab')),
      filter(param => !!param)
    );

    combineLatest([tabParam$, this.tabGroup$])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(([tabParam, tabGroup]) => {
        function getTabIndex() {
          for (let i = 0; i < TABS.length; i++) {
            if (TABS[i] === tabParam) {
              return i;
            }
          }
          throw new Error('Unhandled: ' + tabParam);
        }

        tabGroup.selectedIndex = getTabIndex();
      });
  }

  private ticketSetup() {

    this.zone.runOutsideAngular(() => {
      merge(interval(10000), this.escalationPoolingTrigger$)
        .pipe(
          filter(() => this.isEscalationStatusPolling),
          switchMap(() => this.zone.run(() => this.ticketApiService.getEscalatedStatus(this.ticketId))),
          takeUntil(this.ngUnsubscribe)
        )
        .subscribe(value => this.zone.run(() => this.onEscalationStatusReceived(value)));
    });

    let lastTicketId = null;

    const ticketIdLoader$ = // this observable emits a ticketId every time we need to load a ticket
      this.ticketReloadTrigger$.pipe(
        map(() => lastTicketId),
        tap(() => this.isLoading = true),
        share(),
        takeUntil(this.ngUnsubscribe)
      );

    const ticket$ = ticketIdLoader$.pipe(switchMap(ticketId => this.ticketApiService.getTicket(ticketId)));
    const notes$ = ticketIdLoader$.pipe(switchMap(ticketId => this.ticketApiService.getTicketNotes(ticketId)));
    const attachments$ = ticketIdLoader$.pipe(switchMap(ticketId => this.ticketApiService.getTicketAttachments(ticketId)));
    const escalationStatus$ = ticketIdLoader$.pipe(switchMap(ticketId => this.ticketApiService.getEscalatedStatus(ticketId)));

    zip(ticketIdLoader$, ticket$, notes$, attachments$, escalationStatus$)
      .subscribe(([ticketId, ticket, notes, attachments, escalationStatusValue]) => {
        this.isLoading = false;
        console.log('loaded ticket', ticketId, ticket, notes, attachments, escalationStatusValue);
        this.onTicketLoaded(ticketId, ticket, notes, attachments);
        this.onEscalationStatusReceived(escalationStatusValue);
      });

    this.activatedRoute.paramMap.pipe(
      map(paramMap => paramMap.get(RouterPathParams.ticketId)),
      filter(ticketIdParam => !!ticketIdParam),
      distinctUntilChanged(),
      takeUntil(this.ngUnsubscribe)
    ).subscribe(ticketId => {
      lastTicketId = ticketId;
      this.ticketReloadTrigger$.next();
    });
  }

  onTabIndexChanged(tabIndex: number) {
    // noinspection JSIgnoredPromiseFromCall
    this.router.navigate(this.routeService.ticket(this.ticketId, TABS[tabIndex]));
  }

  private onEscalationStatusReceived(escalationStatusValue: string) {
    this.escalationStatus = UiEscalationStatus[escalationStatusValue];
    this.isEscalationStatusPolling = this.escalationStatus !== UiEscalationStatus.NONE;
  }

  private onTicketLoaded(ticketId: number, ticket: ReadOnlyTicket, notes: TicketNote[], attachments: TicketAttachment[]) {
    this.ticketId = ticketId;
    this.attachments = attachments;
    this.titleService.setTitle(this.ticketId + ' - ' + ticket.title);
    this.ticketTitle = ticket.title;
    this.ticketStatus = ticket.status;
    this.ticketPriority = ticket.priority;
    this.ticketCreationDate = ticket.created;

    if (this.ticketStatus === 'RESOLVED') {
      this.ticketAvailableStatuses = [];
    } else if (this.ticketStatus === 'PENDING') {
      this.ticketAvailableStatuses = ['RESOLVED'];
    } else {
      this.ticketAvailableStatuses = ['RESOLVED', 'PENDING'];
    }
    this.ticketAssignedTo = ticket.assignedTo;
    this.ticketContact = ticket.contact;
    this.ticketLastUpdated = ticket.updated;

    /**
     There is no way to determine the initial description from a Note's own attributes. We define initial description
     as the first note of a ticket. When we add a note to a closed ticket, it can be possible to have two notes which
     share the exact same date, because seconds are the most precise we can get. If that is the case, we will choose
     the note with the lowest ID to be the initial description. The reason why we can't just use IDs to begin with
     is because Notes come from two sets of objects having their own sets of IDs (ServiceNote and TimeEntry).
     The order of the notes must reflect this reality.
     */
    function sortNotes() {
      const numberOfNotes = notes.length;
      notes.sort((a, b) => b.date.localeCompare(a.date));
      if (numberOfNotes >= 2 && notes[numberOfNotes - 1].date === notes[numberOfNotes - 2].date) {
        if (notes[numberOfNotes - 1].id > notes[numberOfNotes - 2].id) {
          const temp = notes[numberOfNotes - 2];
          notes[numberOfNotes - 2] = notes[numberOfNotes - 1];
          notes[numberOfNotes - 1] = temp;
        }
      }
    }

    sortNotes();

    this.notePanels = [];
    notes.forEach(value => {
      const np = new NotePanel();
      np.isOpened = false;
      np.note = value;
      this.notePanels.push(np);
    });
    this.initialDescription = notes.length > 0 ? notes[notes.length - 1] : null;
  }

  private createTicketFromClosedTicket(title, description, note) {
    this.ticketApiService.createTicket(title, description, this.currentContactService.getContact().contactId)
      .pipe(
        switchMap(ticketId => this.ticketApiService.addTicketNote(ticketId, note).pipe(map(() => ticketId)))
      )
      .subscribe(ticketId => {
        this.analyticsService
          .trackEvent('Create Ticket From Closed Ticket', EventCategory.Ticket, ticketId.toString());
        return this.router.navigate(this.routeService.ticket(ticketId));
      });
  }

  onAttachmentClick(attachment: TicketAttachment) {
    this.ticketApiService.downloadAttachment(this.ticketId, attachment.id)
      .subscribe(file => {
        saveAs(file, attachment.fileName);
        this.analyticsService.trackEvent('Download Attachments', EventCategory.Ticket, this.ticketId.toString());
      });
  }

  onChangeStatusClicked() {
    const dialogConfig: MatDialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      availableStatuses: this.ticketAvailableStatuses,
      ticketId: this.ticketId,
      ticketStatus: this.ticketStatus
    };

    if (this.ticketAvailableStatuses.length > 0) {
      dialogConfig.panelClass = 'panel-two-thirds';
    }

    let newStatus;

    const dialogRef = this.dialog.open(TicketStatusDialogComponent, dialogConfig);

    dialogRef.componentInstance.emitDirty.subscribe(() => {
      this.dialogForm.markAsDirty();
    });

    dialogRef.afterClosed()
      .pipe(
        tap(() => {
          this.dialogForm.markAsPristine();
        }),
        filter(value => value),
        switchMap(value => {
            newStatus = value['selectedStatus'];
            this.isLoading = true;
            return forkJoin([
                this.ticketApiService.addTicketNote(this.ticketId, value['note']),
                this.ticketApiService.setTicketStatus(this.ticketId, value['selectedStatus'])
              ]
            );
          }
        )
      )
      .subscribe(() => {
        this.dialogForm.markAsPristine();
        this.analyticsService.trackEvent(
          'Set Status to ' + newStatus,
          EventCategory.Ticket,
          'ticket:' + this.ticketId + ' status:' + newStatus
        );
        this.ticketReloadTrigger$.next();
      });
  }

  onAddNoteClicked() {
    this.analyticsService.trackEvent('Add Note Clicked', EventCategory.Ticket, this.ticketId.toString());
    const dialogRef = this.dialog.open(TicketAddNoteDialogComponent, {
      // don't allow escape or backdrop click to close the dialog - this is to avoid losing a half written note.
      disableClose: true,
      panelClass: 'panel-two-thirds',
      data: {
        ticketId: this.ticketId,
        ticketStatus: this.ticketStatus
      }
    });
    dialogRef.componentInstance.emitDirty.subscribe(() => {
      this.dialogForm.markAsDirty();
    });
    dialogRef.afterClosed()
      .subscribe(results => {
          this.dialogForm.markAsPristine();
          if (results) {
            this.isLoading = true;
            if (results['isClosed'] === false) {
              this.ticketApiService.addTicketNote(this.ticketId, results['note'])
                .subscribe(() => {
                  this.analyticsService.trackEvent('Add Note', EventCategory.Ticket, this.ticketId.toString());
                  this.ticketReloadTrigger$.next();
                });
            } else {
              this.analyticsService
                .trackEvent('Add Note To Closed Ticket', EventCategory.Ticket, this.ticketId.toString());
              this.createTicketFromClosedTicket(
                this.ticketTitle,
                'Created from Closed Ticket #' + this.ticketId + '\n\n' + this.initialDescription.text,
                results['note']
              );
            }
          }
        }
      );
  }

  onEscalateClicked() {
    this.analyticsService.trackEvent('Escalate Clicked', EventCategory.Ticket, this.ticketId.toString());
    const dialogRef = this.dialog.open(EscalationDialogComponent, {
      disableClose: true,
      panelClass: 'panel-one-third',
      data: {
        ticketId: this.ticketId,
        ticketStatus: this.ticketStatus
      }
    });
    dialogRef.afterClosed()
      .subscribe(results => {
        if (results['isEscalated'] === true) {
          this.analyticsService.trackEvent('Escalation Started', EventCategory.Ticket, this.ticketId.toString());
          this.isEscalationStatusPolling = true;
          this.escalationPoolingTrigger$.next();
        }
      });
  }

  isEscalated() {
    return this.escalationStatus !== UiEscalationStatus.NONE;
  }

  isEscalationInProgress() {
    return this.escalationStatus === UiEscalationStatus.IN_PROGRESS;
  }

  isEscalationFinished() {
    return this.escalationStatus === UiEscalationStatus.FINISHED;
  }

  canEscalate() {
    return this.currentContactService.getContact().roles.includes('ESCALATE_TICKET');
  }
}
