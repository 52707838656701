import {Component, EventEmitter, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {UserManagementApiService, UserRoles} from '../../api/user-management-api.service';
import {LanguageService} from '../../language.service';
import {AnalyticsService, EventCategory} from '../../analytics.service';
import {CurrentContactService} from '../../current-contact.service';
import {TranslateService} from '@ngx-translate/core';
import {ContactJson} from '../../api/auth-api.service';

@Component({
  selector: 'app-user-dialog',
  templateUrl: './user-dialog.component.html',
  styleUrls: ['./user-dialog.component.scss']
})
export class UserDialogComponent implements OnInit {
  isSaving = false;
  isAddNewUser: boolean;

  emitDirty = new EventEmitter();

  firstName = new FormControl('', [Validators.required]);
  lastName = new FormControl('', [Validators.required]);
  email = new FormControl('', [Validators.required, Validators.email]);
  phoneNumber = new FormControl('');
  role = new FormControl('', [Validators.required]);
  status = new FormControl('');
  companyId = new FormControl('');

  user: ContactJson;
  allActiveEmails: string[];
  currentContactCompanyId: string;
  canCreateAdminUsers: boolean;
  isExceedingMaxNumberOfActiveUsers: boolean;

  userRoles = [UserRoles.roles.ADMIN, UserRoles.roles.EXECUTIVE, UserRoles.roles.MANAGER, UserRoles.roles.USER];

  userForm = new FormGroup({
    firstName: this.firstName,
    lastName: this.lastName,
    email: this.email,
    phoneNumber: this.phoneNumber,
    role: this.role,
    status: this.status,
    companyId: this.companyId
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<UserDialogComponent>,
    public userManagementApiService: UserManagementApiService,
    private languageService: LanguageService,
    private analyticsService: AnalyticsService,
    private currentContactService: CurrentContactService,
    private translate: TranslateService
  ) {
    this.user = data['user'];
    this.allActiveEmails = data['allActiveEmails'];
    this.currentContactCompanyId = data['companyId'];
    this.canCreateAdminUsers = data['canCreateAdminUsers'];
    this.isExceedingMaxNumberOfActiveUsers = data['isExceedingMaxNumberOfActiveUsers'];

    if (this.user) {
      this.isAddNewUser = false;
      this.firstName.setValue(this.user.firstName);
      this.lastName.setValue(this.user.lastName);
      this.email.setValue(this.user.email);
      this.phoneNumber.setValue(this.user.phoneNumber);
      this.role.setValue(this.user.userType);
      this.status.setValue(this.user.isPortalEnabled);
      this.companyId.setValue(this.user.companyId);
    } else {
      this.isAddNewUser = true;
    }
  }

  ngOnInit() {
    this.disableActivationCheckbox();
  }

  onKeyPress() {
    this.emitDirty.emit();
  }

  onSaveClicked() {
    if (!this.userForm.valid) {
      return;
    }
    this.isSaving = true;
    const user = this.createUserFromForm();

    if (this.user) {
      user.contactId = this.user.contactId;
      this.userManagementApiService.updateUser(user)
        .subscribe((response) => {
          this.handleSaveResponse(response, user, 'Updated User');
        });
    } else {
      this.userManagementApiService.createUser(user)
        .subscribe((response) => {
          this.handleSaveResponse(response, user, 'Created User');
        });
    }
  }

  private handleSaveResponse(response, user, successAnalyticsMessage) {
    if (response.error) {
      if (response.error.errorType === 'EmailAlreadyExistsException') {
        this.analyticsService.trackEvent('Email Already Exist', EventCategory.User_Management, user.email);
        this.email.setErrors({notUnique: true});
      } else if (response.error.errorType === 'MaxAdminsExceededException') {
        this.userForm.setErrors({tooManyAdmins: true});
      }
    } else {
      this.analyticsService.trackEvent(successAnalyticsMessage, EventCategory.User_Management, user.email);
      this.onDialogClose(true);
    }
    this.isSaving = false;
  }

  allowSettingActivationStatus(): boolean {
    if (this.isExceedingMaxNumberOfActiveUsers) {
      return this.user && this.user.isPortalEnabled;
    }
    return true;
  }

  disableActivationCheckbox() {
    if (!this.allowSettingActivationStatus()) {
      this.status.disable();
    }
  }

  onDialogClose(refreshList: boolean): void {
    this.dialogRef.close(refreshList);
  }

  createUserFromForm(): ContactJson {
    const user = new ContactJson();
    user.isPortalEnabled = this.user ? this.user.isPortalEnabled : true;
    user.userType = this.role.value;
    user.firstName = this.firstName.value;
    user.lastName = this.lastName.value;
    user.email = this.email.value;
    user.phoneNumber = this.phoneNumber.value;
    user.contactId = this.user ? this.user.contactId : null;
    user.language = this.languageService.getCurrent();
    user.isPortalEnabled = this.status.value;
    user.companyId = this.companyId.value;

    return user;
  }

  isCurrentContact() {
    if (!this.isAddNewUser) {
      return this.currentContactService.getContact().email.toLowerCase() === this.user.email.toLowerCase();
    }
    return false;
  }

  emailAlreadyExists(email: string) {
    const lowerCaseEmail = email.toLowerCase();
    if (this.email.dirty && this.allActiveEmails.includes(lowerCaseEmail)) {
      if (this.user) {
        if (this.user.email.toLowerCase() !== lowerCaseEmail) {
          this.email.setErrors({notUnique: true});
        }
      }
      this.email.setErrors({notUnique: true});
    }
  }
}
