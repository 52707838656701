<div *ngIf="isDataLoaded | async">
  <!--ACTIVE TICKETS-->
  <div class="container">
    <div class="active-tickets-number">
      <mat-card>
        <mat-card-header class="ticket-number-header">
          <mat-card-title>{{ "dashboard.card.title.active.ticket.count" | translate }}</mat-card-title>
        </mat-card-header>
        <mat-card-content class="ticket-number-content">
          <h1>{{ dashboardData.activeTicketCount }}</h1>
        </mat-card-content>
      </mat-card>
    </div>

    <!--Opened Tickets Last 30 Days-->
    <div class="opened-tickets-number">
      <mat-card>
        <mat-card-header class="ticket-number-header">
          <mat-card-title>{{ "dashboard.card.title.opened.last.30.days" | translate }}</mat-card-title>
        </mat-card-header>
        <mat-card-content class="ticket-number-content">
          <h1>{{ dashboardData.openedLast30Days }}</h1>
        </mat-card-content>
      </mat-card>
    </div>

    <!--Closed Tickets Last 30 Days-->
    <div class="closed-tickets-number">
      <mat-card>
        <mat-card-header class="ticket-number-header">
          <mat-card-title>{{ "dashboard.card.title.closed.last.30.days" | translate }}</mat-card-title>
        </mat-card-header>
        <mat-card-content class="ticket-number-content">
          <h1>{{ dashboardData.closedLast30Days  }}</h1>
        </mat-card-content>
      </mat-card>
    </div>

    <!--Opened Tickets By Priority Last 30 Days-->
    <div class="priority-pie-chart">
      <mat-card>
        <mat-card-header>
          <mat-card-title>{{ "dashboard.card.title.tickets.opened.by.priority.last.30.days" | translate }}</mat-card-title>
        </mat-card-header>
        <mat-card-content class="pie-chart-content">
          <canvas baseChart
                  [data]="openedByPriorityData"
                  [labels]="openedByPriorityLabels"
                  [chartType]="openedByPriorityType"
                  [options]="openedByPriorityOptions"
                  [colors]="priorityPieColors">
          </canvas>
        </mat-card-content>
      </mat-card>
    </div>

    <!--Opened Tickets By Status Last 30 Days-->
    <div class="status-pie-chart">
      <mat-card>
        <mat-card-header>
          <mat-card-title>{{  "dashboard.card.title.tickets.opened.by.status.last.30.days" | translate }}</mat-card-title>
        </mat-card-header>
        <mat-card-content class="pie-chart-content">
          <canvas baseChart
                  [data]="openedByStatusData"
                  [labels]="openedByStatusLabels"
                  [chartType]="openedByStatusType"
                  [options]="openedByPriorityOptions"
                  [colors]="statusPieColors">
          </canvas>
        </mat-card-content>
      </mat-card>
    </div>

    <!--Aging-->
    <div class="aging-bar-chart">
      <mat-card>
        <mat-card-header>
          <mat-card-title>{{ "dashboard.card.title.active.tickets.age.in.days" | translate }}</mat-card-title>
        </mat-card-header>
        <mat-card-content class="bar-chart-content">
          <canvas baseChart
                  [datasets]="ticketAgingData"
                  [labels]="ticketAgingLabels"
                  [chartType]="ticketAgingType"
                  [colors]="activeTicketsAgeColors"
                  [options]="ticketAgingOptions"
                  [legend]=false>
          </canvas>
        </mat-card-content>
      </mat-card>
    </div>

    <!--Historical-->
    <div class="historical-bar-chart">
      <mat-card>
        <mat-card-header>
          <mat-card-title>{{ "dashboard.card.title.historical.opened.closed.tickets" | translate }}</mat-card-title>
        </mat-card-header>
        <mat-card-content class="bar-chart-content">
          <canvas baseChart
                  [datasets]="historicalTicketsData"
                  [labels]="historicalTicketsLabels"
                  [chartType]="historicalTicketsType"
                  [options]="historicalTicketsOptions"
                  [colors]="'colors'"
                  [legend]=true>
          </canvas>
        </mat-card-content>
      </mat-card>
    </div>

    <!--RECENT TICKETS-->
    <div class="recent-tickets-table">
      <mat-card>
        <mat-card-header>
          <mat-card-title>{{ "dashboard.card.title.recent.tickets" | translate }}</mat-card-title>
        </mat-card-header>
        <mat-card-content class="recent-ticket-mat-card">
          <app-recent-tickets></app-recent-tickets>
          <div class="link-to-search">
            <a [routerLink]="routeService.ticketSearch()" data-auto="search-tickets-button">
              {{ "dashboard.card.go.to.search" | translate}}
            </a>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>





