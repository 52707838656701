<div class="container">
  <h1 mat-dialog-title>{{ 'escalate.title' | translate}}</h1>

  <div *ngIf="!isEscalatedDesired">
    <div class="escalate-prompt">
      {{ 'escalate.prompt.text' | translate }}
    </div>
    <div mat-dialog-actions>
      <div style="margin-left: auto;">
        <button color="warn" mat-raised-button
                data-auto="escalate-ticket-button"
                (click)="onEscalationDesiredClicked()">{{ 'escalate.button' | translate}}</button>
        <button color="primary" mat-raised-button (click)="onDialogClose(false)">{{ 'general.cancel' | translate}}</button>
      </div>
    </div>
  </div>

  <div *ngIf="isEscalatedDesired">
    <div class="escalate-prompt">
      <p>{{ 'escalate.contact.method.prompt' | translate}}</p>
      <p>{{ 'escalate.contact.value.prompt' | translate}}</p>
    </div>

    <div class="contact-method-container">
      <mat-radio-group class="contact-method-radio-group escalate-radio-group" [(ngModel)]="selectedMethod">
        <mat-radio-button class="contact-method-radio-1" [value]="contactMethods[0]" checked="true">
          {{ contactMethods[0].radio | translate }}
        </mat-radio-button>
        <mat-radio-button class="contact-method-radio-2" [value]="contactMethods[1]">
          {{ contactMethods[1].radio | translate }}
        </mat-radio-button>
      </mat-radio-group>
      <div class="contact-method-field-group">
        <mat-form-field class="contact-method-field-1">
          <input matInput [formControl]=phoneFormControl ngDefaultControl data-auto="escalation-phone-input">
          <mat-placeholder>{{ phonePlaceholder }}</mat-placeholder>
          <mat-error *ngIf="phoneFormControl.hasError('pattern') &&
          selectedMethod.radio === 'escalate.phone.radio'">
            {{ 'escalate.e164.error' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="contact-method-field-2">
          <input matInput [formControl]="emailFormControl" ngDefaultControl data-auto="escalation-email-input">
          <mat-placeholder>{{ emailPlaceholder }}</mat-placeholder>
          <mat-error *ngIf="emailFormControl.hasError('email') &&
          selectedMethod.radio === 'escalate.email.radio'">
            {{ 'escalate.email.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>


    <div mat-dialog-actions>
      <div style="margin-left: auto;">
        <button color="warn" mat-raised-button
                [disabled]="isButtonDisabled()"
                data-auto="escalation-submit-button"
                (click)="onEscalationConfirmedClicked()">{{ 'escalate.button' | translate}}</button>
        <button color="primary" mat-raised-button
                (click)="onDialogClose(false)">{{ 'general.cancel' | translate}}</button>
      </div>
    </div>
  </div>
</div>



