import { Pipe, PipeTransform } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
  name: 'ticketPriority'
})
export class TicketPriorityPipe implements PipeTransform {

  constructor(
    private translate: TranslateService,
  ) {
  }

  transform(value: any, args?: any): any {
    return this.translate.instant('ticket.priority.' + value);
  }

}
