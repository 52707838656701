import {Component, OnInit} from '@angular/core';
import {CompanyApiService} from '../api/company-api.service';
import {AnalyticsService, EventCategory} from '../analytics.service';

@Component({
  selector: 'app-configure-portal',
  templateUrl: './configure-portal.component.html',
  styleUrls: ['./configure-portal.component.scss']
})
export class ConfigurePortalComponent implements OnInit {

  isWaitingForConfirm = false;
  isLoading: boolean;
  hasLogo: boolean;
  currentCompanyLogo: string;
  previousCompanyLogo: string;
  selectedCompanyLogoFile: File;

  constructor(
    private companyApiService: CompanyApiService,
    private analytics: AnalyticsService
  ) {
  }

  ngOnInit() {
    this.isLoading = true;
    this.companyApiService.getCompanyLogo()
      .subscribe((value => {
        this.hasLogo = value !== null;
        if (this.hasLogo) {
          this.currentCompanyLogo = 'data:image/png;base64,' + value.body;
        }
        this.isLoading = false;
      }));

    this.companyApiService.companyLogo$.subscribe(value => this.currentCompanyLogo = 'data:image/png;base64,' + value.body);
  }

  selectLogo($event: Event) {
    if ((<HTMLInputElement>$event.target).files.length === 1) {
      this.selectedCompanyLogoFile = (<HTMLInputElement>$event.target).files[0];

      if (this.selectedCompanyLogoFile.type.startsWith('image/')) {
        this.isWaitingForConfirm = true;

        const reader = new FileReader();
        reader.onload = this.handleReaderLoaded.bind(this);
        reader.readAsBinaryString(this.selectedCompanyLogoFile);
        this.hasLogo = true;
      }
    }
  }

  private handleReaderLoaded(readerEvt) {
    const binaryString = readerEvt.target.result;
    this.previousCompanyLogo = this.currentCompanyLogo;
    this.currentCompanyLogo = 'data:image/png;base64,' + btoa(binaryString);
  }

  onCancelLogoClicked() {
    this.currentCompanyLogo = this.previousCompanyLogo;
    this.previousCompanyLogo = null;
    this.isWaitingForConfirm = false;
  }


  onConfirmLogoClicked() {
    const formData = new FormData();
    formData.append('logo', this.selectedCompanyLogoFile, this.selectedCompanyLogoFile.name);
    this.analytics.trackEvent('Change Logo', EventCategory.Company);
    this.companyApiService.uploadCompanyLogo(formData);
    this.isWaitingForConfirm = false;
  }
}
