<div>
  <button mat-raised-button class="filters-button" color="primary" (click)="toggleFilters()">
    {{ 'issues.filters' | translate }}
  </button>
</div>
<div class="issue-filters" *ngIf="showFilters">

  <div class="filter-buttons">
    <button mat-button class="filters-button" color="primary" (click)="setCheckboxes(true)">
      {{ 'issues.filters.select.all.button' | translate }}
    </button>
    <button mat-button class="filters-button" color="primary" (click)="setCheckboxes(false)">
      {{ 'issues.filters.clear.all.button' | translate }}
    </button>
  </div>

  <!--  Status -->
  <div class="filter-row">
    <div class="filter-label">{{ 'project.issues.issue.status.header' | translate }}</div>
    <div class="filter-checkbox-options">
      <mat-checkbox [(ngModel)]="statusFilters['new']"
                    (change)="applyFilter()">{{ 'project.issue.status.new' | translate}}</mat-checkbox>
    </div>
    <div class="filter-checkbox-options">
      <mat-checkbox [(ngModel)]="statusFilters['inProgress']"
                    (change)="applyFilter()">{{ 'project.issue.status.in.progress' | translate}}</mat-checkbox>
    </div>
    <div class="filter-checkbox-options">
      <mat-checkbox [(ngModel)]="statusFilters['pending']"
                    (change)="applyFilter()">{{ 'project.issue.status.pending' | translate}}</mat-checkbox>
    </div>
    <div class="filter-checkbox-options">
      <mat-checkbox [(ngModel)]="statusFilters['blocked']"
                    (change)="applyFilter()">{{ 'project.issue.status.blocked' | translate}}</mat-checkbox>
    </div>
    <div class="filter-checkbox-options">
      <mat-checkbox [(ngModel)]="statusFilters['resolved']"
                    (change)="applyFilter()">{{ 'project.issue.status.resolved' | translate}}</mat-checkbox>
    </div>
  </div>
  <mat-divider></mat-divider>
  <!--  Priority -->
  <div class="filter-row">
    <div class="filter-label">{{ 'project.issues.priority.header' | translate }}</div>
    <div class="filter-checkbox-options">
      <mat-checkbox [(ngModel)]="priorityFilters['critical']"
                    (change)="applyFilter()">{{ 'project.issue.priority.critical' | translate}}</mat-checkbox>
    </div>
    <div class="filter-checkbox-options">
      <mat-checkbox [(ngModel)]="priorityFilters['high']"
                    (change)="applyFilter()">{{ 'project.issue.priority.high' | translate}}</mat-checkbox>
    </div>
    <div class="filter-checkbox-options">
      <mat-checkbox [(ngModel)]="priorityFilters['medium']"
                    (change)="applyFilter()">{{ 'project.issue.priority.medium' | translate}}</mat-checkbox>
    </div>
    <div class="filter-checkbox-options">
      <mat-checkbox [(ngModel)]="priorityFilters['low']"
                    (change)="applyFilter()">{{ 'project.issue.priority.low' | translate}}</mat-checkbox>
    </div>
    <div class="filter-checkbox-options">
      <mat-checkbox [(ngModel)]="priorityFilters['escalated']"
                    (change)="applyFilter()">{{ 'project.issue.priority.escalated' | translate}}</mat-checkbox>
    </div>
  </div>
</div>
<div>
  <table
    mat-table
    [dataSource]="dataSource"
    class="issues-container">
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>№</th>
      <td mat-cell *matCellDef="let row">
        <span class="issue-table-text">{{ row.id }}</span>
      </td>
    </ng-container>
    <ng-container matColumnDef="issueStatus">
      <th mat-header-cell *matHeaderCellDef>{{ 'project.issues.issue.status.header' | translate }}
      </th>
      <td mat-cell *matCellDef="let row">
        <span class="issue-table-text">{{ getStatusTranslation(row) | translate }}</span>
      </td>
    </ng-container>
    <ng-container matColumnDef="priority">
      <th mat-header-cell *matHeaderCellDef>{{ 'project.issues.priority.header' | translate }}</th>
      <td mat-cell *matCellDef="let row">
        <span class="issue-table-text">{{ getPriorityTranslation(row) | translate }}</span>
      </td>
    </ng-container>
    <ng-container matColumnDef="issueOwner">
      <th mat-header-cell *matHeaderCellDef>{{ 'project.issues.issue.owner.header' | translate }}</th>
      <td mat-cell *matCellDef="let row">
        <span class="issue-table-text">{{ row.issueOwner }}</span>
      </td>
    </ng-container>
    <ng-container matColumnDef="initialDescription">
      <th mat-header-cell *matHeaderCellDef>{{ 'project.issues.summary.header' | translate }}</th>
      <td mat-cell *matCellDef="let row">
        <div class="text-pad">
          <span class="issue-table-text">{{ row.initialDescription }}</span>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="dateEntered">
      <th mat-header-cell *matHeaderCellDef>{{ 'project.issues.start.date.header' | translate }}</th>
      <td mat-cell *matCellDef="let row">
        <span class="issue-table-text">{{ row.dateEntered | localizedDateShort }}</span>
      </td>
    </ng-container>
    <ng-container matColumnDef="impactSummary">
      <th mat-header-cell *matHeaderCellDef>{{ 'project.issues.impact.summary.header' | translate }}</th>
      <td mat-cell *matCellDef="let row">
        <div class="text-pad">
          <span class="issue-table-text">{{ row.impactSummary }}</span>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="actionSteps">
      <th mat-header-cell *matHeaderCellDef>{{ 'project.issues.action.steps.header' | translate }}</th>
      <td mat-cell *matCellDef="let row">
        <div class="text-pad">
          <span class="issue-table-text">{{ row.actionSteps }}</span>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="issuesDisplayedColumns;"></tr>
    <tr mat-row *matRowDef="let row; columns: issuesDisplayedColumns;" data-auto="project-issue-row"></tr>
  </table>
</div>
