<div class="container">
  <h1 mat-dialog-title>{{ 'layout.contact.us.button' | translate}}</h1>
  <div mat-dialog-content class="content" style="font-size: large;">
    <mat-icon class="first-icon">email</mat-icon>
    <span class="info">
    <a href="mailto:support@goziro.com">support@goziro.com</a>
  </span>
    <mat-icon class="third-icon">location_on</mat-icon>
    <div class="loc-text info">
    <span class="loc-text-name">
      <a href="https://goo.gl/maps/hew8QwqfuBL2">
      {{'contact.us.add1' | translate }}
      </a>
    </span>
      <span class="loc-text-address">{{'contact.us.add2' | translate }}</span>
      <span class="loc-text-city">{{'contact.us.add3' | translate }}</span>
      <span class="loc-text-country">{{'contact.us.add4' | translate }}</span>
    </div>
  </div>

  <div mat-dialog-actions>
    <div style="margin-left: auto">
      <button color="primary" mat-raised-button (click)="onCloseClicked()">{{ 'general.close' | translate}}</button>
    </div>
  </div>
</div>
